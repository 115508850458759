import { Icon } from "@iconify/react";
import React from "react";
import DeleteHook from "../../../Hooks/DeleteHook";
import { Link } from "react-router-dom";
import { useState } from "react";
import AdminUpdatePaymentModal from "../../../Shared/AdminUpdatePaymentModal";

const AdminAllAgentTable = ({ data, refetch, setRefetch }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <tr className="shadow">
        <td className="h-16 px-6 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {data?.userName}
        </td>

        <td className="h-16 px-6 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {data?.role}
        </td>
        <td className="h-16 px-6 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {data?.referralUserName}
        </td>
        <td className="h-16 px-6 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        ৳ {data?.mobileBalance.toFixed(3)}
        </td>
        <td className="h-16 px-6 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        € {data?.riccaricaBalance.toFixed(3)}
        </td>
        <td className="h-16 px-6 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        € {data?.cargoBalance.toFixed(3)}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {data?.status}
        </td>
        <td className="h-16 px-6 text-xs transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center cursor-pointer">
          <Link
            to={`/adminDashboard/updateAgent/${data?._id}`}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="basil:edit-outline" />
          </Link>
          {/* <button
            onClick={() => setIsOpen(true)}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="streamline:money-cash-bag-dollar-bag-payment-cash-money-finance" />
          </button> */}


          <Link
            to={`/adminDashboard/admin-bkash-report/${data?._id}`}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="mdi:report-line" />
          </Link>
          <Link
            to={`/adminDashboard/admin-riccarica-report/${data?._id}`}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="material-symbols:partner-reports" />
          </Link>
          <Link
            to={`/adminDashboard/admin-cargo-report/${data?._id}`}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="iconoir:reports" />
          </Link>
          <button
            aria-label="Open delete confirmation"
            onClick={() => {
              DeleteHook({
                refetch,
                setRefetch,
                url: `https://walletserver.genzamwallet.com/api/v1/user/${data?._id}`,
              });
            }}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="material-symbols:delete-outline" />
          </button>
        </td>
      </tr>
      <AdminUpdatePaymentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        user={data}
        setRefetch={setRefetch}
        refetch={refetch}
      />
    </>
  );
};

export default AdminAllAgentTable;
