
const MyPaymentPostHook = (data) => {
  fetch(`https://walletserver.genzamwallet.com/api/v1/myPayment/addMyPayment`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
    },
  })    
};

export default MyPaymentPostHook;
