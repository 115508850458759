import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// import logo from "../../Assets/logo.png";

import { Collapse, Dropdown, initTE } from "tw-elements";
import AuthUser from "../../Hooks/authUser";
function PublicWebNavbar() {
  const { userInfo } = AuthUser();

  useEffect(() => {
    initTE({ Collapse, Dropdown });
  }, []);
  return (
    <nav
      class="relative flex w-full flex-nowrap items-center justify-between  lg:text-black text-white text-[18px]  lg:flex-wrap lg:justify-start lg:shadow-md  bg-primary lg:py-0 py-4 "
      data-te-navbar-ref
    >
      <div class="flex w-full flex-wrap items-center justify-between">
        <div className="flex lg:hidden justify-between items-center w-full px-5 ">
          <Link to="/" className="w-20  ">
            {/* <img src={logo} alt="" /> */}
            <p>Wallet</p>
          </Link>
          {/* <!-- Hamburger button for mobile view --> */}
          <button
            class="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
            type="button"
            data-te-collapse-init
            data-te-target="#navbarSupportedContent8"
            aria-controls="navbarSupportedContent8"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <!-- Hamburger icon --> */}
            <span class="[&>svg]:w-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                class="h-7 w-7"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </button>
        </div>
        <div>
          <Link to="/" className="pl-5 hidden lg:block text-white font-bold  ">
            {/* <img src={logo} alt="" /> */}
            <p>Wallet</p>
          </Link>
        </div>
        {/* <!-- Collapsible navbar container --> */}
        <div
          class="!visible  hidden flex-grow basis-[100%] items-center justify-center lg:mt-0 lg:!flex lg:basis-auto "
          id="navbarSupportedContent8"
          data-te-collapse-item
        >
          {/* <!-- Left links --> */}
          <ul
            class="list-style-none flex text-left flex-col lg:gap-7 gap-5 pl-0  lg:flex-row  relative  w-full flex-nowrap items-center justify-between  py-2 text-white text-[16px]  lg:flex-wrap lg:justify-end lg:py-4  lg:pr-5"
            data-te-navbar-nav-ref
          >
            <li className="  border-b-2   hover:border-white transition-all duration-500 border-b-2   border-primary hover:border-white transition-all duration-500">
              <Link to="/login"> Home</Link>
            </li>

            <li
              class=" pl-2 lg:mb-0 lg:pl-0 lg:pr-1 "
              data-te-nav-item-ref
              data-te-dropdown-ref
            >
              {/* <!-- Dropdown --> */}
              <a
                class="flex items-center "
                href="/"
                type="button"
                id="dropdownMenuButton2"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                About Us
                <span class="ml-1 w-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="h-5 w-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </a>
              <ul
                class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg text-black font-medium [&[data-te-dropdown-show]]:block"
                aria-labelledby="dropdownMenuButton2"
                data-te-dropdown-menu-ref
              >
                <li>
                  <Link
                    class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal  hover:bg-primary hover:text-white"
                    to="about"
                    data-te-dropdown-item-ref
                  >
                    About us
                  </Link>
                </li>
                
              
               
              </ul>
            </li>
            <li>
              {localStorage.getItem("walletAccess") ? (
                <Link
                  to={`/${userInfo?.role}Dashboard`}
                  className="border-b-2 border-primary hover:border-white transition-all duration-500"
                >
                  Dashboard
                </Link>
              ) : (
                <Link to={"/login"}>
                  <button className="border-b-2 border-primary hover:border-white transition-all duration-500">
                    Login
                  </button>
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default PublicWebNavbar;
