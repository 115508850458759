import React from "react";

const About = () => {
  return (
    <div className=" min-h-screen bg-blue-200 ">
      <section className="py-10">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-4xl font-bold text-gray-800 mb-4">About Us</h2>
            <p className="text-lg text-gray-600 mb-8">
              Welcome to our money transfer service from Italy to Bangladesh. We
              are dedicated to providing safe, efficient, and affordable
              international money transfer solutions to our customers.
            </p>
            <p className="text-lg text-gray-600 mb-8">
              Our mission is to make the process of sending money overseas as
              seamless as possible, ensuring that your funds reach their
              destination quickly and securely.
            </p>
            <p className="text-lg text-gray-600">
              We take pride in our commitment to customer satisfaction and the
              trust that our users place in us. Your financial needs are
              important to us, and we're here to assist you every step of the
              way.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
          <div className="max-w-3xl">
            <h2 className="text-3xl font-bold sm:text-4xl">
              Founder, Director, CEO at Genzam
            </h2>
          </div>

          <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
            <div className="relative h-64 overflow-hidden sm:h-80 lg:h-full">
              <img
                alt="Party"
                src="https://scontent.fjsr3-1.fna.fbcdn.net/v/t39.30808-6/380416652_1055785885788513_4961949248250007653_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=5614bc&_nc_eui2=AeF0KbgkuyQl1XWqPY1gSceCC2rmiCncOMQLauaIKdw4xAJdLP2zBsBE3UaJ8ea0X-kt2139p55NEW3g_iGXUk1S&_nc_ohc=L4LNIqTZ8_MAX9Zg2GE&_nc_ht=scontent.fjsr3-1.fna&oh=00_AfD-EU3RZyRSmbcgeiHJ3jPixwC2jhRZ4Lha_nP9vlllTQ&oe=652B9164"
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>

            <div className="lg:py-16">
              <article className="space-y-4 text-gray-600">
                <p>
                  Ahmed Medule is the driving force behind Genzam-IT, leading
                  the company with visionary leadership and a passion for
                  innovation. With a commitment to excellence, Ahmed Medule has
                  propelled Genzam-IT to the forefront of Online innovation,
                  where the company continues to redefine standards and shape
                  the future.
                </p>
                <br />
                <p>
                  Meet the visionary leader behind Genzam, Ahmed Medul.
                  With a relentless drive for innovation and a commitment to
                  excellence, Ahmed Medul has steered Genzam to remarkable
                  success in the Online innovation, setting new standards and
                  achieving remarkable milestones.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
