import React, { useEffect, useState } from "react";
import Bkash from "../../../Assents/mobile banking -recharge/mobile banking.png";
import Nagad from "../../../Assents/mobile banking -recharge/recharge.png";
import cargo from "../../../Assents/mobile banking -recharge/cargo.png";

import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import AuthUser from "../../../Hooks/authUser";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AgentDashBord = () => {
  const { userInfo, logout } = AuthUser();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [dashboardDetails, setDashboardDetails] = useState({});

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setUser(data?.data));
  }, [userInfo?._id]);

  useEffect(() => {
    if (userInfo?.role === "agent" || userInfo?.role === "user") {
    } else {
      logout();
      navigate("/login");
    }
  }, [logout, navigate, userInfo?.role]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://walletserver.genzamwallet.com/api/v1/dashboard/getDashboardDetails"
        );
        const data = await response.json();

        if (data?.data.length) {
          setDashboardDetails(data?.data[0]);
        }
      } catch (error) {
        console.error("Error fetching dashboard details:", error);
        // Handle error as needed (e.g., show an error message)
      }
    };

    fetchData();
  }, []);

  return (
    <div className="min-h-screen m-5">
      <div>
        <h1 className="text-center font-bold text-2xl">
          Welcome to Genzam-IT Dashboard
        </h1>
        <p className="text-center">
          Welcome to Genzam-IT Dashboard, designed to empower you with tools to
          manage your banking and cargo operations efficiently.
        </p>
      </div>

      <div className="mt-5">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
        <div className="rounded-lg shadow-md bg-white overflow-hidden">
      <div className="p-4">
        <h2 className="text-xl font-semibold mb-2">User Details</h2>

        <div className="flex items-center mb-2">
          <div className="bg-gray-200 rounded-full p-1">
            <Icon icon="fa6-solid:user-tie" className="text-gray-600 text-xl" />
          </div>
          <div className="ml-2">
            <h3 className="text-md font-semibold">
              {user ? user.name : <Skeleton width={80} />}
            </h3>
            <p className="text-xs text-gray-600">
              {user ? user.role : <Skeleton width={80} />}
            </p>
          </div>
        </div>

        <div className="flex items-center mb-2">
          <div className="bg-gray-200 rounded-full p-1">
            <Icon icon="eos-icons:role-binding-outlined" className="text-gray-600 text-xl" />
          </div>
          <div className="ml-2">
            <h3 className="text-md font-semibold uppercase">
              {user ? user.role : <Skeleton width={80} />}
            </h3>
            <p className="text-xs text-gray-600">
              {user ? user.userName : <Skeleton width={80} />}
            </p>
          </div>
        </div>

        <div className="flex items-center mb-2">
          <div className="bg-gray-200 rounded-full p-1">
            <Icon icon="mdi:id-card-outline" className="text-gray-600 text-xl" />
          </div>
          <div className="ml-2">
            <h3 className="text-md font-semibold">
              {user ? user.userName : <Skeleton width={80} />}
            </h3>
          </div>
        </div>

        <hr className="my-2" />

        <h2 className="text-lg font-semibold mb-2">Your Current Balance</h2>

        <div className="flex flex-col space-y-1">
          <div className="flex items-center">
            <div className="bg-blue-500 rounded-full p-1">
              <Icon icon="flat-color-icons:currency-rupee" className="text-white text-lg" />
            </div>
            <div className="ml-2">
              <p className="text-md font-semibold">Mobile Banking</p>
              <p className="text-xs text-gray-600">
                {user ? `৳ ${(user.mobileBalance || 0).toFixed(2)}` : <Skeleton width={80} />}
              </p>
            </div>
          </div>

          <div className="flex items-center">
            <div className="bg-green-500 rounded-full p-1">
              <Icon icon="emojione-monotone:euro-symbol" className="text-white text-lg" />
            </div>
            <div className="ml-2">
              <p className="text-md font-semibold">Ricarica</p>
              <p className="text-xs text-gray-600">
                {user ? `€ ${(user.riccaricaBalance || 0).toFixed(2)}` : <Skeleton width={80} />}
              </p>
            </div>
          </div>

          <div className="flex items-center">
            <div className="bg-yellow-500 rounded-full p-1">
              <Icon icon="carbon:shipping-cargo" className="text-white text-lg" />
            </div>
            <div className="ml-2">
              <p className="text-md font-semibold">Cargo</p>
              <p className="text-xs text-gray-600">
                {user ? `€ ${(user.cargoBalance || 0).toFixed(2)}` : <Skeleton width={80} />}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

          <div className="rounded-lg shadow-md lg:col-span-2 p-5">
            <div className="lg:flex justify-center items-center gap-10">
              <div className="text-center shadow-2xl rounded-md bg-[#E2136E] p-5 mb-2">
                <img className="lg:w-[120px]" src={Bkash} alt="Bkash" />
                <div>
                  <Link
                    to="/mobileBanking"
                    className="bg-text hover:bg-gray-700 text-white font-bold py-2 px-7 shadow-2xl rounded-md inline-block"
                  >
                    Banking
                  </Link>
                </div>
              </div>
              <div className="text-center bg-[#EA1C25] p-5 shadow-2xl rounded-md mb-2">
                <img className="lg:w-[120px]" src={Nagad} alt="Nagad" />
                <div>
                  <Link
                    to="/riccarica"
                    className="bg-text hover:bg-gray-700 text-white font-bold py-2 px-7 shadow-2xl rounded-md inline-block"
                  >
                    Riccarica
                  </Link>
                </div>
              </div>

              <div className="text-center bg-[#34495E] p-5 shadow-2xl rounded-md mb-2">
                <img className="lg:w-[120px]" src={cargo} alt="Cargo" />
                <div>
                  <Link
                    to="/cargo"
                    className="bg-text hover:bg-gray-700 text-white font-bold py-2 px-7 shadow-2xl rounded-md inline-block"
                  >
                    Cargo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rounded-lg shadow-lg lg:col-span-2 mt-5 p-6">
        <h2 className="text-xl font-semibold mb-4 text-gray-800">
          Instructions
        </h2>

        <div className="space-y-4 text-sm text-gray-700">
          <div>
            <p className="mb-2">
              <span className="font-semibold">Navigation:</span> Use the sidebar
              menu or navigation links to access different sections of the
              dashboard.
            </p>
          </div>

          <div>
            <p className="mb-2">
              <span className="font-semibold">User Details:</span> Your personal
              details including name, role, and username are displayed for
              reference.
            </p>
          </div>

          <div>
            <p className="mb-2">
              <span className="font-semibold">Current Balances:</span>
            </p>
            <ul className="list-disc ml-6">
              <li>
                Mobile Banking: View your balance in Bangladeshi Taka (৳).
              </li>
              <li>Ricarica: Check your balance in Euros (€).</li>
              <li>
                Cargo: Track your balance for cargo services in Euros (€).
              </li>
            </ul>
          </div>

          <div>
            <p className="mb-2">
              <span className="font-semibold">Functionalities Overview:</span>
            </p>
            <ul className="list-disc ml-6">
              <li>
                Mobile Banking: Manage your financial transactions and accounts.
              </li>
              <li>
                Ricarica: Recharge your accounts for seamless transactions.
              </li>
              <li>Cargo: Track and manage your cargo shipments.</li>
            </ul>
          </div>

          <div>
            <p className="mb-2">
              <span className="font-semibold">Additional Information:</span> For
              emergency situations or further assistance, refer to the emergency
              contact details provided below.
            </p>
          </div>
        </div>
      </div>

      <div className="rounded-lg shadow-md bg-white p-6">
        <h2 className="text-2xl font-semibold mb-4">Emergency Contact</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="flex items-center p-4 bg-blue-500 rounded-lg">
            <div className="rounded-full bg-white p-3">
              <Icon
                icon="logos:whatsapp-icon"
                className="text-blue-500 text-xl"
              />
            </div>
            <div className="ml-4">
              <p className="text-lg font-semibold text-white">WhatsApp</p>
              <p className="text-sm text-gray-300">+39 351 178 9888</p>
            </div>
          </div>

          <div className="flex items-center p-4 bg-green-500 rounded-lg">
            <div className="rounded-full bg-white p-3">
              <Icon
                icon="material-symbols:call"
                className="text-green-500 text-xl"
              />
            </div>
            <div className="ml-4">
              <p className="text-lg font-semibold text-white">Phone</p>
              <p className="text-sm text-gray-300">+39 351 178 9888</p>
            </div>
          </div>

          <div className="flex items-center p-4 bg-yellow-500 rounded-lg col-span-2 md:col-auto">
            <div className="rounded-full bg-white p-3">
              <Icon
                icon="ic:outline-email"
                className="text-yellow-500 text-xl"
              />
            </div>
            <div className="ml-4">
              <p className="text-lg font-semibold text-white">Email</p>
              <p className="text-sm text-gray-300">genzamofficial@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentDashBord;
