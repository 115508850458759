import { Icon } from "@iconify/react";
import React from "react";

const AgentRechargeMypaymentTable = () => {
  return (
    <div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="text-left bg-[#008C44] text-white">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Order #
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Date</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Payment For
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Type</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Received
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">User</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                A. Balance
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Payment Type
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Amount (EUR)
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Note</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Status
              </th>

              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Action
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            <tr className="odd:bg-gray-50">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                1
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                AY1695579724
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                24/09/2023
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                International
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                Allocated
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                26.09.2023
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                osthirstore1250
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                301.3900
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                DUE BALANCED{" "}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                301.3900
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                26.09.2023 --- 50 euro paid{" "}
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                <p className=" bg-green-600 rounded-md px-4 text-center text-white py-1">
                  {" "}
                  Completed
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AgentRechargeMypaymentTable;
