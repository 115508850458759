import React from "react";
import MobileBankingHero from "./MobileBankingHero/MobileBankingHero";
import TodayTransactions from "./Transactions/TodayTransactions";

const MobileBakingIndex = () => {
  return (
    <div>
      <MobileBankingHero></MobileBankingHero>
      {/* <TodayTransactions></TodayTransactions> */}
    </div>
  );
};

export default MobileBakingIndex;
