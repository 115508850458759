import React, { useEffect, useState } from "react";
import gp from "../../../Assents/bdsim company/gp.png";
import bl from "../../../Assents/bdsim company/bl.png";
import robi from "../../../Assents/bdsim company/robi.png";
import airtel from "../../../Assents/bdsim company/airtel.png";
import teletalk from "../../../Assents/bdsim company/teletalk.png";
import BangladeshSimCompany from "./BangladeshSimCompany";
import PackageCard from "./PackageCard";
import AgentRiccaricaModal from "../../../Shared/AgentRiccaricaModal";
import AuthUser from "../../../Hooks/authUser";
import { server_url } from "../../../Config/API";
import { toast } from "react-toastify";
import moment from "moment";
import AgentBdRechargeModal from "../../../Shared/AgentBdRechargeModal";

const AgentBbRecharge = () => {
  let date = moment().format("YYYY-MM-D");
  const [packages, setPackages] = useState([]);
  const [data, setData] = useState([]);
  const { userInfo } = AuthUser();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [exchangeRate, setExchangeRate] = useState([]);
  const [formData, setFormData] = useState({
    receiverMobileNumber: "",
    packageType: "Recharge",
    amountBdt: "",
    serviceCharge: "",
    note: "",
  });

  const firstThree = (number) => {
    const nString = number.toString();
    const firstThreeWords = nString.slice(0, 3);
    let operator;
    if (firstThreeWords === "017" || firstThreeWords === "013") {
      operator = "GrameenPhone (GP)";
    } else if (firstThreeWords === "018") {
      operator = "Robi Axiate Limited (Robi)";
    } else if (firstThreeWords === "016") {
      operator = "Airtel Bangladesh (Airtel)";
    } else if (firstThreeWords === "019" || firstThreeWords === "014") {
      operator = "Banglalink (BL)";
    } else if (firstThreeWords === "015") {
      operator = "Teletalk Bangladesh Limited (Teletalk)";
    }
    return operator;
  };
  const operator = firstThree(formData?.receiverMobileNumber);

  const [myData, setMyData] = useState([]);
  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setMyData(data?.data));
  }, [userInfo?._id]);

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/exchangeRate/getExchangeRate`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data) {
          setExchangeRate(data?.data[0]);
        }
      });
  }, []);

  useEffect(() => {
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/packages/filtered-packages?operator=${operator}&packageType=${formData?.packageType}`
    ).then((res) =>
      res.json().then((data) => {
        setPackages(data?.data);
      })
    );
  }, [formData?.packageType, operator]);
  const total =
    Number(formData?.serviceCharge) +
    Number(formData?.amountBdt * exchangeRate?.euroExchangeRate);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formData.receiverMobileNumber.length !== 11) {
      toast.error("Mobile number must be 11 digits long");
      return;
    }
    setOpen(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleResetForm = () => {
    setFormData({
      receiverMobileNumber: "",
      packageType: "Recharge",
      amountBdt: "",
      serviceCharge: "",
      note: "",
    });
  };

  const packageData = { ...formData };
  return (
    <div>
      <nav aria-label="Breadcrumb" className="flex mb-3 ">
        <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
          <li className="flex items-center">
            <a
              href="#"
              className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>

              <span className="ms-1.5 text-xs font-medium"> Home </span>
            </a>
          </li>

          <li className="relative flex items-center">
            <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>

            <a
              href="#"
              className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900"
            >
              BD Recharge
            </a>
          </li>
        </ol>
      </nav>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
        <div className="h-auto p-5 rounded-lg text-white   bg-ricaricabg lg:col-span-2">
          <form onSubmit={handleFormSubmit}>
            <div className="  mt-1 rounded">
              <div className="md:flex gap-10 items-center justify-center p-2">
                <div className="flex gap-1 items-center">
                  <img className="w-16 -z-100" src={gp} alt="" />
                  <img className="w-16 -z-100" src={bl} alt="" />
                  <img className="w-16 -z-100" src={robi} alt="" />
                  <img className="w-16 -z-100" src={airtel} alt="" />
                  <img className="w-16 -z-100" src={teletalk} alt="" />
                </div>
              </div>
            </div>

            <div className="flex gap-2 items-center ">
              <label htmlFor="receiverMobileNumber" className="w-full">
                <p className="text-sm font-medium">
                  Mobile Number <span className="">*</span>
                </p>
                <input
                  className="w-full text-black py-[5px] border-2 mt-1 p-2 rounded outline-none"
                  required
                  type="tel"
                  name="receiverMobileNumber"
                  onChange={(e) => {
                    if (e.target.value.length <= 11) {
                      handleInputChange(e);
                    }
                  }}
                  value={formData.receiverMobileNumber}
                  placeholder="Enter Your Receiver Mobile Number"
                  pattern="\d{11}"
                  title="Mobile number must be exactly 11 digits"
                  maxLength="11"
                />
              </label>

              <label className="w-full " htmlFor="">
                <p className=" text-sm font-medium">
                  Package Type<span className="r">*</span>
                </p>
                <select
                  className="w-full text-black py-[5px] mt-1 border-2  bg-white p-2 rounded"
                  required
                  id="packageType"
                  name="packageType"
                  onChange={handleInputChange}
                  value={formData.packageType}
                >
                  <option disabled className="w-full" value="">
                    Select
                  </option>

                  <option className="w-full" value="Recharge">
                    Recharge
                  </option>

                  <option className="w-full" value="Combo">
                    Combo
                  </option>
                  <option className="w-full" value="Talk-Time">
                    Talk Time
                  </option>
                  <option className="w-full" value="Internet">
                    Internet
                  </option>
                </select>
              </label>
            </div>
            <div className="flex gap-2 items-center mt-3">
              {formData?.packageType === "Recharge" && (
                <label className="w-full">
                  <div className="flex flex-wrap justify-between">
                    <p className="font-medium text-sm">
                      Amount (BDT) <span className="">*</span>
                    </p>
                    <p className="flex gap-1 text-sm font-medium">
                      <span className="hidden md:block"></span>{" "}
                      <span id="euroSpan">
                        {(
                          formData?.amountBdt * exchangeRate?.euroExchangeRate
                        ).toFixed(2)}
                      </span>
                      EURO
                    </p>
                  </div>
                  <input
                    className="w-full text-black py-[5px] mt-1 border-2  p-2 rounded outline-none"
                    type="number"
                    name="amountBdt"
                    onChange={handleInputChange}
                    value={formData.amountBdt}
                    placeholder="Enter bdt Amount"
                    min="1"
                  />
                </label>
              )}
            </div>
            <label className="w-full mt-2 block">
              <div className="flex flex-wrap justify-between">
                <p className="font-medium text-sm">Service Charge (Euro)</p>
                <p className="flex gap-1 font-medium text-sm">
                  <span className="hidden md:block"></span>{" "}
                  <span id="euroSpan">
                    {(
                      formData?.serviceCharge * exchangeRate?.bdtExchangeRate
                    ).toFixed(2)}
                  </span>
                  BDT
                </p>
              </div>
              <input
                className="w-full text-black py-[5px] mt-1 border-2   p-2 rounded outline-none"
                type="number"
                onChange={handleInputChange}
                value={formData.serviceCharge}
                id="serviceCharge"
                name="serviceCharge"
                placeholder="Service Charge in EUR (e.g., 0.50)"
                step="0.01"
                min="0"
              />
            </label>

            <div className="">
              <label className=" text-sm font-medium " htmlFor="note">
                Your Note:
              </label>
              <textarea
                className="w-full text-black py-[5px] mt-1 p-2 border-2  rounded outline-none"
                name="note"
                onChange={handleInputChange}
                value={formData.note}
                cols="20"
                rows="3"
                placeholder="Please write your note !!"
              ></textarea>
            </div>
            {formData?.packageType === "Recharge" && (
              <input
                className=" px-7 bg-white text-[#008C44] font-bold rounded  max-w-sm  py-2 cursor-pointer mt-2 mb-2"
                type="submit"
                value="Continue Recharge"
              />
            )}
          </form>
        </div>
        <div>
          <BangladeshSimCompany></BangladeshSimCompany>
        </div>
      </div>
      <div className=" mt-5  p-5 ">
        <h2 className="mb-5 text-xl text-center font-semibold">
         {operator} {formData?.packageType} Package
        </h2>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-5 ">
          {[...packages]?.reverse()?.map((p) => (
            <PackageCard
              key={p?._id}
              packageItem={p}
              setData={setData}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
          ))}
        </div>
      </div>
      <AgentRiccaricaModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        data={data}
        formData={packageData}
        onSuccess={handleResetForm}
      />
      <AgentBdRechargeModal
        setOpen={setOpen}
        open={open}
        formData={formData}
        total={total}
        onSuccess={handleResetForm}
      />
    </div>
  );
};

export default AgentBbRecharge;
