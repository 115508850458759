import React from 'react';
import AdminAllCargoTable from './AdminAllCargoTable';
import { useState } from 'react';
import { useEffect } from 'react';
import { server_url } from '../../../Config/API';
import Loading from '../../../Shared/Loading';

const AdminAllCargoDetails = () => {
    const [data, setData] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        fetch(`${server_url}/cargoDetails/getCargoDetails`).then(res => res.json().then(data => {
            setData(data?.data)
            setLoading(false)
        }))
    }, [refetch])

    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <div className="mt-3  text-2xl font-bold">
                All Cargo Details
            </div>

            <div className="w-full overflow-x-auto mt-10">
                <table
                    className="w-full text-left rounded w-overflow-x-auto "
                    cellspacing="0"
                >
                    <tbody>
                        <tr>
                            <th
                                scope="col"
                                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                                Name
                            </th>

                            <th
                                scope="col"
                                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                                Charge
                            </th>
                            <th
                                scope="col"
                                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                                Agent Commission
                            </th>
                            <th
                                scope="col"
                                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                                Delivery Charge
                            </th>


                            <th
                                scope="col"
                                className="h-16 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-100"
                            >
                                Action
                            </th>
                        </tr>
                        {[...data]?.reverse()?.map((d) => (
                            <AdminAllCargoTable
                                data={d}
                                key={d?._id}
                                refetch={refetch}
                                setRefetch={setRefetch}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminAllCargoDetails;