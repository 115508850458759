import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import { Icons } from "react-toastify";
import AgentRechargeMypaymentTable from "./AgentRechargeMypaymentTable";

const AgentRechargeMypayment = () => {
  return (
    <div>
      <nav aria-label="Breadcrumb" className="flex mb-5" >
        <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
          <li className="flex items-center">
            <a
              href="#"
              className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>

              <span className="ms-1.5 text-xs font-medium"> Home </span>
            </a>
          </li>

          <li className="relative flex items-center">
            <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>

            <a
              href="#"
              className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900"
            >
              My Payment
            </a>
          </li>
        </ol>
      </nav>
      <div className=" lg:flex  justify-center items-center border  p-5 rounded-md  gap-5">
        <div className=" lg:w-[500px] mt-2 mb-2">
          <input
            className="  w-full border p-2 rounded-md"
            placeholder="Payment Number , User Name ,Phone,Payment Type etc."
            type="search"
          ></input>
        </div>

        <div className=" lg:flex mt-2 justify-end w-full items-center gap-5">
          <div className=" w-full mb-2">
            <select className=" w-full p-2 bg-white border rounded-md">
              <option value="Completed"> Type</option>

              <option value="Allocated">Allocated</option>
              <option value="Return">Return</option>
            </select>
          </div>

          <div className=" w-full mb-2">
            <select className=" w-full p-2 bg-white border rounded-md">
              <option value="Completed"> Payment For</option>

              <option value="Completed">International</option>
            </select>
          </div>
          <div className=" w-full mb-2">
            <select className=" w-full p-2 bg-white border rounded-md">
              <option value="Completed"> Select Status</option>

              <option value="Completed">Completed</option>
              <option value="Pendding">Pendding</option>
              <option value="Cancled">Cancled</option>
            </select>
          </div>

          <div className=" mb-2 w-full">
            {" "}
            <button className=" bg-[#008C44] p-2 rounded-md px-7 text-white">
              Search
            </button>{" "}
          </div>
        </div>
      </div>
      <div className="mt-5 bg-white shadow-2xl p-5">
        <div className=" lg:flex justify-between  mb-3">
          <h1 className=" text-2xl"> Payment List (Total Results: 219)131</h1>
          <div className=" gap-2 flex">
            <Link
              className="group relative inline-flex items-center overflow-hidden rounded bg-[#008C44]  px-8 py-3 text-white focus:outline-none focus:ring active:bg-indigo-500"
              href="/download"
            >
              <span className="absolute -start-full transition-all group-hover:start-4">
                <svg
                  className="h-5 w-5 rtl:rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </span>

              <span className="text-sm font-medium transition-all group-hover:ms-4">
                New MRecharge
              </span>
            </Link>
          </div>
        </div>

        <AgentRechargeMypaymentTable></AgentRechargeMypaymentTable>
      </div>{" "}
    </div>
  );
};

export default AgentRechargeMypayment;
