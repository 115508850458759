import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RicaricaUsersalesReport = () => {
  const { id } = useParams();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true); // Initially set loading to true
  const [filterOrders, setFilterOrders] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedMonthTotalAmount, setSelectedMonthTotalAmount] = useState(0);
  const [selectedMonthTotalOrders, setSelectedMonthTotalOrders] = useState(0);

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/riccaricaOrder/getRiccaricaOrder`).then(
      (res) =>
        res.json().then((data) => {
          const confirmOrders = data?.data.filter((order) => {
            return order.status === "confirmed" && order.ordererId === id;
          });

          setOrders(confirmOrders);
          setFilterOrders(confirmOrders);
          setLoading(false); // Set loading to false after data is fetched
        })
    );
  }, [id]);

  const filterOrdersByDate = () => {
    if (selectedMonth) {
      const results = orders?.filter((data) => {
        const orderDate = new Date(data.date);
        return (
          orderDate.getMonth() === selectedMonth.getMonth() &&
          orderDate.getFullYear() === selectedMonth.getFullYear()
        );
      });
      setFilterOrders(results);
      const totalAmount = results.reduce(
        (acc, order) => acc + order.totalAmountEuro,
        0
      );
      const totalOrders = results.length;
      setSelectedMonthTotalAmount(totalAmount);
      setSelectedMonthTotalOrders(totalOrders);
    }
  };

  return (
    <div>
      <div className="h-auto p-3 rounded-lg bg-ricaricabg mb-5">
        <div className="max-w-md mx-auto lg:flex items-center justify-center border-2 gap-5 p-5 shadow-md">
          <div className="mx-auto text-center">
            <label htmlFor="month">
              <p className="text-white font-medium">
                Select Month <span>*</span>
              </p>
              <DatePicker
                selected={selectedMonth}
                onChange={(date) => setSelectedMonth(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                className="w-full px-10 py-2 mt-1 bg-white border-2 border-[#008C44] outline-none cursor-pointer p-2 rounded"
                id="month"
              />
            </label>
          </div>

          <div className="text-center">
            <p className="text-white font-medium">
              Filter Now <span>*</span>
            </p>
            <button
              className="bg-white px-10 w-full text-gray-700 text-xl py-2 rounded-md"
              onClick={filterOrdersByDate}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="text-center mb-5 lg:flex justify-center items-center gap-5">
        <p className="text-green-800 font-medium">
          Total Amount for Selected Month: €{" "}
          {selectedMonthTotalAmount.toFixed(2)}
        </p>
        <p className="text-green-800">||</p>
        <p className="text-green-800 font-medium">
          Total Orders for Selected Month: {selectedMonthTotalOrders}
        </p>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-xs">
          <thead className="text-left bg-[#008C44] text-white">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Entry By
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Date</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Receiver Number
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Package Type
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Amount
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Service Charge
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Commission
              </th>

              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Total Amount
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Status
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {loading
              ? Array.from({ length: 10 }).map((_, i) => (
                  <tr key={i} className="odd:bg-gray-50">
                    <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                      <Skeleton />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <Skeleton />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <Skeleton />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <Skeleton />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <Skeleton />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <Skeleton />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <Skeleton />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <Skeleton />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <Skeleton />
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <Skeleton />
                    </td>
                  </tr>
                ))
              : [...filterOrders]?.reverse()?.map((order, i) => (
                  <tr key={order?._id} order={order} className="odd:bg-gray-50">
                    <td
                      key={order?._id}
                      order={order}
                      className="whitespace-nowrap px-4 py-2 font-medium text-gray-900"
                    >
                      {i + 1}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {order?.ordererUserName}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {order?.date}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {order?.receiverMobileNumber}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {order?.packageType}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      € {order?.amountEuro.toFixed(2)}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      € {order?.serviceChargeEuro.toFixed(2)}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      € {order?.commissionEuro.toFixed(2)}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      € {order?.totalAmountEuro.toFixed(2)}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <button className="bg-[#008C44] px-3 py-1 text-white  rounded-md">
                        {order?.status}
                      </button>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RicaricaUsersalesReport;
