import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { server_url } from "../../../Config/API";
import UpdateHooks from "../../../Hooks/UpdateHooks";

const AdminEditPayment = () => {
  const {id}=useParams();
  const [data,setData]=useState([]);
  const [note,setNote]=useState("")
  const [status,setStatus]=useState("")
  const navigator = useNavigate()

  useEffect(() => {
    fetch(`${server_url}/myPayment/getMyPaymentById/${id}`).then(res => res.json().then(data => {
      setData(data?.data)
    }))
}, [id])

  const data2 = [ "complete", "pending", "return"];

  const handelUpdate = ()=>{
    UpdateHooks(
      `${server_url}/myPayment/updateMyPayment/${id}`,
      {note:note?note:data?.note,status:status?status:note?.status},
      "Report Updated !"
  );
  navigator(-1);
  }

  return (
    <div className=" md:w-1/2 mx-auto mt-10 mb-20">
      <div className="flex flex-col gap-3">
        <div className="flex">
          <label className=" py-2 pl-4 w-64 rounded bg-gray-200">
          Payment Date
          </label>
          <div
            className="border w-full px-3 outline-none py-2 rounde"
          >
            {data?.date}
          </div>
        </div>

        

        <div className="flex">
          <label className=" py-2 pl-4 w-64 rounded bg-gray-200">
         Recipient ID
          </label>
          <div
            className="border w-full px-3 outline-none py-2 rounde"
          >
            {data?.recipientId}
          </div>
        </div>

        <div className="flex">
          <label className=" py-2 pl-4 w-64 rounded bg-gray-200">
         Recipient Phone Number
          </label>
          <div
            className="border w-full px-3 outline-none py-2 rounde"
          >
            {data?.recipientNumber}
          </div>
        </div>
        
        
        <div className="flex">
          <label className=" py-2 pl-4 w-64 rounded bg-gray-200">
          Recipient User Name
          </label>
          <div
            className="border w-full px-3 outline-none py-2 rounde"
          >
            {data?.recipientUserName}
          </div>
        </div>

        <div className="flex">
          <label className=" py-2 pl-4 w-64 rounded bg-gray-200">
            Service Name
          </label>
          <div
            className="border w-full px-3 outline-none py-2 rounde"
          >
            {data?.serviceName}
          </div>
        </div>

        <div className="flex">
          <label className=" py-2 pl-4 w-64 rounded bg-gray-200">
          Status
          </label>

          <select onChange={(e)=>setStatus(e.target.value)} id="condition" className="border w-full px-3 outline-none py-2  ">
            <option value="" disabled selected>
              Select Any
            </option>

            {data2?.map((cat, index) => (
              <option selected={cat==data?.status} key={index} cat={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>


        <div className="flex">
          <label className=" py-2 pl-4 w-64 rounded bg-gray-200">
          Amount
          </label>
          <div
            className="border w-full px-3 outline-none py-2 rounde"
          >
            {data?.amount}
          </div>
        </div>


        <div className="flex">
          <label className=" py-2 pl-4 w-64 rounded bg-gray-200">
            Note
          </label>
          <textarea defaultValue={data?.note} onChange={(e)=>setNote(e.target.value)}
            rows="7" cols="50"
            type="type"
            className="border w-full px-3 outline-none py-2 rounde "
          ></textarea>
        </div>

        
      </div>

      
      <button onClick={handelUpdate} className='bg-green-600 mt-3 hover:bg-green-700 duration-300 px-10 py-1 text-center rounded text-white text-lg font-semibold'>Update</button>
    </div>
  );
};

export default AdminEditPayment;
