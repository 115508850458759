import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthUser from "../../../Hooks/authUser";
import { MdAccountBalance, MdPending } from "react-icons/md";
import { FaHistory } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AgentBkashSideBar = () => {
  const { userInfo, logout } = AuthUser();
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setUser(data?.data))
      .catch((error) => console.error("Error fetching user data:", error));
  }, [userInfo?._id]);

  return (
    <div>
      <div className="flex h-screen w-52 flex-col justify-between border-e bg-bkashprimary">
        <div>
          <div className="grid grid-cols-1 h-16 w-full items-center justify-center p-3 mb-3">
            <div>
              <h1 className="text-white">
                {user ? (
                  `${user?.userName?.slice(0, 15)}...`
                ) : (
                  <Skeleton width={80} />
                )}
              </h1>
            </div>
            <div className="flex items-center text-white">
              <MdAccountBalance />
              <Icon className="text-white" icon="mdi:currency-bdt" />
              <p className="text-white">
                {user ? (
                  user?.mobileBalance?.toFixed(2)
                ) : (
                  <Skeleton width={50} />
                )}
              </p>
            </div>
          </div>

          <div className="border-t border-gray-100">
            <div className="px-2">
              <div className="py-4">
                <Link
                  to="overview"
                  className="t group relative flex justify-start items-center gap-2 rounded bg-blue-50 px-2 py-1.5 text-blue-700"
                >
                  <Icon icon="ic:baseline-dashboard"></Icon>
                  <p>Dashboard</p>
                </Link>
              </div>

              <ul className="space-y-1 border-t border-gray-100 pt-4">
                <li>
                  <Link
                    to="newRequest"
                    className="group relative flex justify-start items-center gap-2 rounded px-2 py-1.5 text-white hover:bg-gray-50 hover:text-gray-700"
                  >
                    <Icon icon="ri:chat-new-fill"></Icon>
                    <p className="text-sm">New Request</p>
                  </Link>
                </li>

                <li>
                  <Link
                    to="peding-Orders"
                    className="group relative flex justify-start rounded px-2 py-1.5 text-white hover:bg-gray-50 hover:text-gray-700 items-center gap-1"
                  >
                    <MdPending />
                    <p className="text-sm">Pending Orders</p>
                  </Link>
                </li>

                <li>
                  <Link
                    to="order-history"
                    className="group relative flex justify-start rounded px-2 py-1.5 text-white hover:bg-gray-50 hover:text-gray-700 items-center gap-1"
                  >
                    <FaHistory />
                    <p className="text-sm">Order History</p>
                  </Link>
                </li>
                {userInfo?.role === "agent" && (
                  <li>
                    <Link
                      to="user-order-history"
                      className="group relative flex justify-start rounded px-2 py-1.5 text-white hover:bg-gray-50 hover:text-gray-700 items-center gap-1"
                    >
                      <FaHistory />
                      <p className="text-sm">User Order History</p>
                    </Link>
                  </li>
                )}

                <li>
                  <Link
                    to="sales-report"
                    className="group relative flex justify-start items-center gap-2 rounded px-2 py-1.5 text-white hover:bg-gray-50 hover:text-gray-700"
                  >
                    <Icon icon="icon-park-outline:sales-report"></Icon>
                    <p className="text-sm">Your Sales Report</p>
                  </Link>
                </li>

                {userInfo?.role === "agent" && (
                  <li>
                    <Link
                      to="user-sales-list"
                      className="group relative flex justify-start items-center gap-2 rounded px-2 py-1.5 text-white hover:bg-gray-50 hover:text-gray-700"
                    >
                      <Icon icon="icon-park-outline:sales-report"></Icon>
                      <p className="text-sm">User Sales Report</p>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="sticky inset-x-0 bottom-0 border-t border-gray-100 bg-bkashprimary p-2">
          <button
            onClick={logout}
            className="group relative flex w-full justify-start rounded-lg px-2 py-1.5 text-sm text-white hover:bg-gray-50 hover:text-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
            <p className="ml-2">Logout</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentBkashSideBar;
