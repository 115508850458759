import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';
import DeleteHook from '../../../Hooks/DeleteHook';

const AdminAllCargoTable = ({data,refetch,setRefetch}) => {
    return (
      
        <tr className="shadow">
          <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
            {data?.name}
          </td>
  
          <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
            {data?.charge}
          </td>
  
          <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
            {data?.agentCommission}
          </td>
          <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
            {data?.deliveryCharge}
          </td>
         
         
          <td className="h-16 px-6  transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center cursor-pointer">
            <Link
              to={`/adminDashboard/updateCargoDetails/${data?._id}`}
              // onClick={() => openModalResellerEditProfile(resellerData)}
              className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
            >
              <Icon icon="basil:edit-outline" />
            </Link>
        
            <button
              aria-label="Open delete confirmation"
              onClick={() => {
                DeleteHook({
                  refetch,
                  setRefetch,
                  url: `https://walletserver.genzamwallet.com/api/v1/cargoDetails/deleteCargoDetails/${data?._id}`,
                });
              }}
              className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
            >
              <Icon icon="material-symbols:delete-outline" />
            </button>
          </td>
        </tr>
      
    );
};

export default AdminAllCargoTable;