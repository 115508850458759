import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../../Shared/Loading";
import AdminAllUserTable from "./AdminAllUserTable";
import Pagination from "../../../Shared/Pagination";

const AdminAllUser = () => {
  const [loading, setLoading] = useState(false);
  const [filterUser, setFilterUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState([]);
  const [refetch, setRefetch] = useState(false);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(50);

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/user/specific?fieldName=${"role"}&&fieldValue=${"user"}&&size=${size}&&page=${page}`
    ).then((res) =>
      res.json().then((data) => {
        setFilterUser(data?.data);
        setUser(data?.data);
        setQuantity(data?.total);
        setLoading(false);
      })
    );
  }, [refetch, page, size]);

  const handleSearch = () => {
    const results = user?.filter((data) => {
      return (
        data.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.phone === searchTerm
      );
    });
    setFilterUser(results);
  };

  useEffect(() => {
    // Auto-filter when the search term changes
    handleSearch();
  }, [searchTerm]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <div className="flex justify-between gap-5 items-center mt-3">
        <Link
          className="py-3 px-5 bg-primary rounded-lg whitespace-nowrap font-semibold text-white"
          to="/adminDashboard/addUser"
        >
          Add User
        </Link>
        <div className="flex relative rounded-md w-full ">
          <input
            type="text"
            placeholder="Enter Reseller Name, Email, or Phone"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
          />
          <button
            onClick={handleSearch}
            className="inline-flex items-center gap-2 bg-secondary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
          >
            <span>search</span>
            <span className="hidden md:block">
              <Icon icon="material-symbols:search"></Icon>
            </span>
          </button>
        </div>
      </div>
      <div className="w-full overflow-x-auto mt-10">
        <table
          className="w-full text-left rounded w-overflow-x-auto "
          cellspacing="0"
        >
          <tbody>
            <tr>
              <th
                scope="col"
                className="h-16 px-6 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                userName
              </th>

              <th
                scope="col"
                className="h-16 px-6 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Role
              </th>

              <th
                scope="col"
                className="h-16 px-6 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                R. UserName
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                B. Balance
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                R. Balance
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                C. Balance
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Status
              </th>
              <th
                scope="col"
                className="h-16 px-6 text-xs font-medium stroke-slate-700 text-slate-700 bg-slate-100"
              >
                Action
              </th>
            </tr>
            {[...filterUser]?.reverse()?.map((data) => (
              <AdminAllUserTable
                data={data}
                key={data?._id}
                refetch={refetch}
                setRefetch={setRefetch}
              />
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

export default AdminAllUser;
