import Swal from "sweetalert2";

const DeleteHook = ({ refetch, setRefetch, url }) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      await fetch(`${url}`, {
        method: "DELETE",
      }).then((res) => {
        if (res.status === 200) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          setRefetch(!refetch);
          setInterval(setRefetch(!refetch), 1000);
        }
      });
    }
  });
};

export default DeleteHook;
