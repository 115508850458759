// export default MobileBanking;
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Icon } from "@iconify/react";
import AgentWebNavBar from "../Agent/AgentWebNavBar/AgentWebNavBar";
import AgentRiccaricaSideBar from "../Agent/AgentRiccarica/AgentRiccaricaSideBar";
import { IoMdArrowRoundBack } from "react-icons/io";

const Riccarica = () => {
  const windowWidth = useRef(window.innerWidth);
  const [isOpen, setIsOpen] = useState();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (windowWidth.current && windowWidth.current > 667) {
      setIsOpen(true);
    }
  }, [windowWidth]);
  const user_info = localStorage.getItem("user_info");
  const user = JSON.parse(user_info);
  return (
    <div className={`fixed inset-0 overflow-auto ${isOpen ? "" : ""}`}>
      <AgentWebNavBar></AgentWebNavBar>
      <div
        className={`${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transform   top-[58px] left-0  h-full  fixed transition ease-in-out duration-500  `}
      >
        <div className="">
          <AgentRiccaricaSideBar></AgentRiccaricaSideBar>
        </div>
      </div>

      {isOpen && (
        <div
          className="fixed transition-opacity"
          aria-hidden="true"
          onClick={toggleMenu}
        ></div>
      )}

      <section
        className={
          isOpen
            ? "md:ml-[220px] opacity-100 duration-500 ease-out"
            : "md:ml-[0px] opacity-100  duration-500 ease-out"
        }
      >
        <button
          className="z-50 px-5 py-1 mt-2 w-fit ml-auto block  mr-5 text-black  bg-gray-100 rounded-md"
          onClick={toggleMenu}
        >
          <IoMdArrowRoundBack />
        </button>
        <div className="  px-5 ">
          <Outlet></Outlet>
        </div>
      </section>
    </div>
  );
};

export default Riccarica;
