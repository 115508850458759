import React from 'react';
import CargoNavbar from './CargoNavbar';
import { Outlet } from 'react-router-dom';
import AgentWebNavBar from '../Agent/AgentWebNavBar/AgentWebNavBar';

const Cargoindex = () => {
    return (
        <div>
            <AgentWebNavBar></AgentWebNavBar>
            <CargoNavbar></CargoNavbar>
            <Outlet></Outlet>
        </div>
    );
};

export default Cargoindex;