import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const RicaricaUserSalesTable = ({
  u,
  index,
  setIsOpen,
  refetch,
  setRefetch,
  setData,
}) => {
  return (
    <tr className="bg-white">
      <td className="whitespace-nowrap px-4 py-2 text-gray-700">{index + 1}</td>
      <td
        className="whitespace-nowrap  text-start text-blue-500 hover:underline cursor-pointer"
        onClick={() => {
          navigator.clipboard.writeText(u?.userName);
          toast.success("Copied to clipboard");
        }}
      >
        {u?.userName}
      </td>
      <td
        className="whitespace-nowrap text-start  text-blue-500 hover:underline cursor-pointer"
        onClick={() => {
          navigator.clipboard.writeText(u?.email);
          toast.success("Copied to clipboard");
        }}
      >
        {u?.email}
      </td>
      <td className="whitespace-nowrap text-start  text-gray-700">৳ {u?.mobileBalance}</td>
      <td className="whitespace-nowrap text-start  text-gray-700">€ {u?.riccaricaBalance}</td>
      <td className="whitespace-nowrap text-start  text-gray-700">€ {u?.cargoBalance}</td>
      <td className="whitespace-nowrap text-start text-gray-700">{u?.status}</td>
      <td className="px-6 py-2 flex gap-2 items-center ">
        <Link
          to={`/riccarica/riccarica-user-sales-report/${u?._id}`}
          className="border border-[#008C44] py-2 px-3 text-center rounded-md hover:bg-secondary/10 duration-300"
        >
          <Icon icon="mdi:report-line" />
        </Link>
      </td>
    </tr>
  );
};

export default RicaricaUserSalesTable;
