import React, { useState, useEffect } from "react";
import AdminRiccaricaTable from "./AdminRiccaricaTable";
import { Icon } from "@iconify/react";
import { server_url } from "../../../Config/API";
import Pagination from "../../../Shared/Pagination";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BookmarkX } from "lucide-react";

const AdminRiccaricaOrders = () => {
  const [status, setStatus] = useState("pending");
  const [search, setSearch] = useState("");
  const [filterOrders, setFilterOrders] = useState([]);
  const [refresh, setRefresh] = useState(false);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, [page, size, refresh, status]);

  const fetchOrders = async () => {
    const res = await fetch(
      `${server_url}/riccaricaOrder/specific/?size=${size}&&page=${page}&&fieldName=status&&fieldValue=${status}`
    );
    const data = await res.json();
    setQuantity(data?.total);
    setOrders(data?.data);
    setFilterOrders(data?.data);
  };

  const handleSearch = () => {
    if (search === "") {
      setFilterOrders(orders);
      return;
    }

    const results = orders.filter((data) => {
      return (
        data._id.toLowerCase().includes(search.toLowerCase()) ||
        data.ordererUserName.toLowerCase().includes(search.toLowerCase()) ||
        data.receiverMobileNumber
          .toLowerCase()
          .includes(search.toLowerCase()) ||
          data.trackingId
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        data.totalAmount === search ||
        data.status.toLowerCase().includes(search.toLowerCase())
      );
    });
    setFilterOrders(results);
  };

  const clearSearch = () => {
    setSearch("");
    setFilterOrders(orders);
  };

  return (
    <div>
      <div className="flex border w-fit rounded-lg overflow-hidden">
        <button
          onClick={() => setStatus("pending")}
          className={`${
            status === "pending"
              ? "bg-primary text-white"
              : "bg-white text-black"
          } font-semibold py-2 px-4`}
        >
          Pending Orders
        </button>
        <button
          onClick={() => setStatus("confirmed")}
          className={`${
            status === "confirmed"
              ? "bg-primary text-white"
              : "bg-white text-black"
          } font-semibold py-2 px-4`}
        >
          Confirmed Orders
        </button>
        <button
          onClick={() => setStatus("rejected")}
          className={`${
            status === "rejected"
              ? "bg-primary text-white"
              : "bg-white text-black"
          } font-semibold py-2 px-4`}
        >
          Rejected Orders
        </button>
      </div>

      {/* for search */}
      <div className="flex justify-between gap-5 items-center mt-3">
        <div className="flex relative rounded-md w-full">
          <input
            type="text"
            placeholder="Search by Tracking-ID, User Name, or Mobile Number"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
          />
          <button
            onClick={handleSearch}
            className="inline-flex items-center gap-2 bg-primary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-primary-hover"
          >
            <span>Search</span>
            <span className="hidden md:block">
              <Icon  icon="material-symbols:search" />
            </span>
          </button>
          <button
            onClick={clearSearch}
            className="inline-flex items-center gap-2 bg-primary  text-white text-lg font-semibold py-3 px-6 rounded-md hover:bg-primary-hover ml-2"
          >
            <span className=" flex items-center gap-1">
              Clear <BookmarkX />
            </span>
            <span className="hidden md:block">
              <Icon icon="material-symbols:clear" />
            </span>
          </button>
        </div>
      </div>
      {/* for search */}

      <div className="mt-3">
        <p>Total Orders: {orders.length}</p>
      </div>

      <div className="w-full overflow-x-auto mt-10">
        <table
          className="w-full text-left rounded w-overflow-x-auto"
          cellSpacing="0"
        >
          <thead>
            <tr className=" text-white bg-primary">
              <th
                scope="col"
                className="h-12 px-4 text-sm font-medium  "
              >
                #
              </th>
              <th
                scope="col"
                className="h-12 px-4 text-sm font-medium"
              >
                Tracking-ID
              </th>
              <th
                scope="col"
                className="h-12 px-4 text-sm font-medium "
              >
                Date
              </th>
              <th
                scope="col"
                className="h-12 px-4 text-sm font-medium "
              >
                Entry By
              </th>

              <th
                scope="col"
                className="h-12 px-4 text-sm font-medium"
              >
                Receiver Number
              </th>
              <th
                scope="col"
                className="h-12 px-4 text-sm font-medium "
              >
                Package
              </th>
              <th
                scope="col"
                className="h-12 px-4 text-sm font-medium "
              >
                Amount
              </th>
              <th
                scope="col"
                className="h-12 px-4 text-sm font-medium "
              >
                Status
              </th>
              <th
                scope="col"
                className="h-12 px-4 text-sm font-medium "
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filterOrders.length === 0
              ? Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index}>
                    {Array.from({ length: 9 }).map((_, subIndex) => (
                      <td key={subIndex} className="px-4">
                        <Skeleton height={20} />
                      </td>
                    ))}
                  </tr>
                ))
              : filterOrders?.map((order, i) => (
                  <AdminRiccaricaTable
                    key={order?._id}
                    i={i}
                    order={order}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                ))}
          </tbody>
        </table>
      </div>
      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

export default AdminRiccaricaOrders;
