import React, { useEffect, useState } from "react";
import "flatpickr/dist/flatpickr.min.css";
import AgentRechargeReportTable from "./AgentRechargeReportTable";
import { server_url } from "../../../Config/API";
import AuthUser from "../../../Hooks/authUser";
import Pagination from "../../../Shared/Pagination";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AgentRechargeReports = () => {
  const { userInfo } = AuthUser();
  const [status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const form = moment(fromDate);
  const to = moment(toDate);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [orders, setOrders] = useState([]);
  const [filterOrders, setFilterOrders] = useState([]);

  useEffect(() => {
    fetch(
      `${server_url}/riccaricaOrder/getRiccaricaOrder/?size=${size}&&page=${page}&&fieldName=${"ordererId"}&&fieldValue=${
        userInfo?._id
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        setQuantity(data?.total);
        setOrders(data?.data);
        setFilterOrders(data?.data);
      });
  }, [page, size, userInfo?._id]);

  const handleSearch = () => {
    const results = orders?.filter((data) => {
      return (
        (to.isAfter(data?.date) && form.isBefore(data?.date)) ||
        (status && status === data?.status)
      );
    });
    setFilterOrders(results);
  };

  return (
    <div>
      <nav aria-label="Breadcrumb" className="flex mb-5">
        <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
          <li className="flex items-center">
            <button
              type="button"
              className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>

              <span className="ms-1.5 text-xs font-medium"> Home </span>
            </button>
          </li>

          <li className="relative flex items-center">
            <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>

            <button
              type="button"
              className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900"
            >
              Reports
            </button>
          </li>
        </ol>
      </nav>

      <div className="lg:flex justify-center bg-ricaricabg items-center gap-5 rounded-md border p-5">
        <div className="">
          <label className="text-lg text-white font-semibold">From Date</label>{" "}
          <br />
          <input
            onChange={(e) => setFromDate(e?.target?.value)}
            className="border-2 border-gray-300 rounded px-3 py-2 w-56"
            type="date"
            placeholder="Select a date"
          />
        </div>
        <div className="">
          <label className="text-lg font-semibold text-white">To Date</label>{" "}
          <br />
          <input
            type="date"
            onChange={(e) => setToDate(e?.target?.value)}
            className="border-2 border-gray-300 rounded px-3 py-2 w-56"
            placeholder="Select a date"
          />
        </div>

        <div className="">
          <label className="text-lg font-semibold text-white">Status</label>{" "}
          <br />
          <select
            value={status}
            onChange={(e) => setStatus(e?.target?.value)}
            className="px-12 py-2 bg-white text-black rounded-md border-2"
          >
            <option>Select Status</option>
            <option value="pending">Pending</option>
            <option value="confirmed">Confirm</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
        <div className="">
          <label className="text-lg font-semibold"></label> <br />
          <button
            onClick={handleSearch}
            className="bg-white text-gray-700 rounded px-3 py-2 w-44 hover:scale-105 duration-300"
          >
            Search History
          </button>
        </div>
      </div>

      <div className="mt-5">
        <div>
          <div className="flex justify-between mb-3">
            <h1 className="text-xl font-bold text-center text-white bg-gradient-to-r from-[#008C44] to-[#00B74A] p-2 rounded-md mb-4 lg:mb-0">
              Recharge Order List (Total Transaction: {quantity})
            </h1>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-xs">
              <thead className="text-left bg-[#008C44] text-white">
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium">
                    Tracking-ID
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium">
                    Date
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium">
                    Entry By
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium">
                    Receiver Number
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium">
                    Package Type
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium">
                    Amount
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium">
                    Service Charge
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium">
                    Commission
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium">
                    Total Amount
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium">
                    Status
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {orders.length === 0
                  ? Array.from({ length: 10 }).map((_, i) => (
                      <tr key={i}>
                        <td className="px-4 py-2">
                          <Skeleton width={30} />
                        </td>
                        <td className="px-4 py-2">
                          <Skeleton width={80} />
                        </td>
                        <td className="px-4 py-2">
                          <Skeleton width={80} />
                        </td>
                        <td className="px-4 py-2">
                          <Skeleton width={80} />
                        </td>
                        <td className="px-4 py-2">
                          <Skeleton width={100} />
                        </td>
                        <td className="px-4 py-2">
                          <Skeleton width={80} />
                        </td>
                        <td className="px-4 py-2">
                          <Skeleton width={80} />
                        </td>
                        <td className="px-4 py-2">
                          <Skeleton width={80} />
                        </td>
                        <td className="px-4 py-2">
                          <Skeleton width={80} />
                        </td>
                        <td className="px-4 py-2">
                          <Skeleton width={80} />
                        </td>
                        <td className="px-4 py-2">
                          <Skeleton width={80} />
                        </td>
                      </tr>
                    ))
                  : [...filterOrders]
                      ?.reverse()
                      ?.map((order, i) => (
                        <AgentRechargeReportTable
                          i={i}
                          key={order?._id}
                          order={order}
                        />
                      ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

export default AgentRechargeReports;
