import React, { useState, useEffect } from "react";
import bkash from "../../../Assents/bkash-nagad/bkash.png";
import nagad from "../../../Assents/bkash-nagad/nagad.png";
import AuthUser from "../../../Hooks/authUser";
import moment from "moment/moment";
import AgentBkashModal from "../../../Shared/AgentBkashModal";
import { Link } from "react-router-dom";

const MobileNewRequest = () => {
  const { userInfo } = AuthUser();
  const [amount, setAmount] = useState("");
  const [myData, setMyData] = useState([]);
  const [transitionRate, setTransitionRate] = useState([]);

  const [paymentMethod, setPaymentMethod] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [recipientNumber, setRecipientNumber] = useState("");
  const [message, setMessage] = useState("");

  let date = moment().format("YYYY-MM-D");

  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setMyData(data?.data));
  }, [userInfo?._id]);

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/commission/getCommission`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data) {
          setTransitionRate(data?.data[0]);
        }
      });
  }, []);

  const calculateCommission = (inputAmount) => {
    if (inputAmount) {
      const commission =
        (inputAmount / 1000) *
        (transitionRate?.bkashCommission - myData?.discount);
      return commission;
    } else {
      return 0;
    }
  };

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    setAmount(newAmount);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleServiceTypeChange = (e) => {
    setServiceType(e.target.value);
  };

  const handleCustomerNameChange = (e) => {
    setCustomerName(e.target.value);
  };

  const handleRecipientNumberChange = (e) => {
    setRecipientNumber(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const commissionResult = calculateCommission(amount);
  const agentCommission = (Number(amount) / 1000) * myData?.agentCommission;
  const commission = commissionResult + agentCommission;
  const total = Number(amount) + Number(commission);

  const handelSubmit = async (e) => {
    e.preventDefault();
    const paymentMethod = e?.target?.paymentMethod?.value;
    const amount = Number(e?.target?.amount?.value);
    const recipientNumber = e?.target?.recipientNumber?.value;
    const message = e?.target?.message?.value;
    const customerName = e?.target?.customerName?.value;
    const serviceType = e?.target?.serviceType?.value;
    const totalAmount = total;

    const data = {
      paymentMethod,
      amount,
      recipientNumber,
      message,
      customerName,
      serviceType,
      totalAmount,
      commission,
      date,
      ordererUserName: userInfo?.userName,
      ordererId: userInfo?._id,
      referralId: userInfo?.referralId,
      ordererPhone: userInfo?.phone,
      referralUserName: userInfo?.referralUserName,
    };
    setFormData(data);
    setIsOpen(true);
  };

  useEffect(() => {
    if (formSubmitted) {
      setPaymentMethod("");
      setServiceType("");
      setCustomerName("");
      setRecipientNumber("");
      setMessage("");
      setAmount("");
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  return (
    <div>
      <nav aria-label="Breadcrumb" className="flex mb-2">
        <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
          <li className="flex items-center">
            <Link
              to="/overview"
              className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
              <span className="ms-1.5 text-xs font-medium"> Home </span>
            </Link>
          </li>

          <li className="relative flex items-center">
            <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>
            <Link
              to="#"
              className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900"
            >
              New Request
            </Link>
          </li>
        </ol>
      </nav>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">
        <div className="h-auto rounded-lg bg-[#FFF0F6] lg:col-span-2">
          <section className="p-2 rounded">
            <div>
              <div className="flex items-center gap-1 py-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-bkashprimary"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M14 16h2v-2h2v-2h-2v-2h-2v2h-2v2h2v2ZM4 20q-.825 0-1.413-.588T2 18V6q0-.825.588-1.413T4 4h6l2 2h8q.825 0 1.413.588T22 8v10q0 .825-.588 1.413T20 20H4Z"
                  />
                </svg>
                <h1 className="font-medium">New Request</h1>
              </div>
            </div>
            <form onSubmit={handelSubmit}>
              <div className="border border-white/20 rounded">
                <div className="md:flex gap-10 items-center justify-center p-2">
                  <div className="flex gap-1 items-center">
                    <img className="w-16 -z-100" src={bkash} alt="" />
                    <img className="w-16 -z-100" src={nagad} alt="" />
                  </div>
                  <div className="md:w-11/12 mx-auto ">
                    <label htmlFor="">
                      <p className="text-bkashprimary font-medium">
                        Payment method <span className="">*</span>
                      </p>
                      <select
                        className="w-full py-2 mt-1 bg-white border-2 border-bkashprimary p-2 rounded"
                        required
                        name="paymentMethod"
                        id="payment"
                        onChange={handlePaymentMethodChange}
                        value={paymentMethod}
                      >
                        <option disabled className="w-full" value="">
                          Select
                        </option>
                        <option className="w-full" value="Bkash">
                          Bkash
                        </option>
                        <option className="w-full" value="Nagad">
                          Nagad
                        </option>
                      </select>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex gap-2 items-center mt-3">
                <label className="w-full ">
                  <p className="text-bkashprimary font-medium">
                    Service type <span className="r">*</span>
                  </p>
                  <select
                    className="w-full py-2 mt-1 border-2 border-bkashprimary bg-white p-2 rounded"
                    required
                    name="serviceType"
                    id="payment"
                    value={serviceType}
                    onChange={handleServiceTypeChange}
                  >
                    <option disabled className="w-full" value="">
                      Select
                    </option>
                    <option className="w-full" value="Personal">
                      Personal
                    </option>
                    <option className="w-full" value="Agent">
                      Agent
                    </option>
                  </select>
                </label>
                <label htmlFor="" className="w-full">
                  <p className="text-bkashprimary font-medium">Customer Name</p>
                  <input
                    className="w-full py-[5px] mt-1 border-2 border-bkashprimary p-2 rounded outline-none"
                    type="text"
                    name="customerName"
                    placeholder="Customer Name"
                    id="customer"
                    value={customerName}
                    onChange={handleCustomerNameChange}
                    title="Enter Your Customer Name"
                  />
                </label>
              </div>
              <div className="flex gap-2 items-center mt-3">
                <label htmlFor="" className="w-full">
                  <p className="text-bkashprimary font-medium">
                    Recipient Number <span className="">*</span>
                  </p>
                  <input
                    className="w-full py-[5px] border-2 border-bkashprimary mt-1 p-2 rounded outline-none"
                    required
                    value={recipientNumber}
                    onChange={handleRecipientNumberChange}
                    type="tel"
                    placeholder="Please enter an 11-digit phone number"
                    name="recipientNumber"
                    id="recipient"
                    pattern="\d{11}"
                    maxLength="11"
                    title="Please enter an 11-digit phone number"
                  />
                </label>
                <label htmlFor="amount" className="w-full">
                  <div className="flex flex-wrap justify-between">
                    <p className="text-bkashprimary font-medium">
                      Amount <span className="">*</span>
                    </p>
                    <p className="flex gap-1 font-medium">
                      <span className="hidden text-bkashprimary md:block">
                        Charge {commission?.toFixed(2)}
                      </span>
                    </p>
                  </div>
                  <input
                    className="w-full py-[5px] mt-1 border-2 border-bkashprimary p-2 rounded outline-none"
                    required
                    type="number"
                    name="amount"
                    value={amount}
                    onChange={handleAmountChange}
                    placeholder="Enter the amount"
                    title="Please enter the amount to be transferred"
                    id="amount"
                  />
                </label>
              </div>
              <div className="mt-3">
                <label className="text-bkashprimary" htmlFor="message">
                  Your Message:
                </label>
                <textarea
                  className="w-full py-[5px] mt-1 p-2 border-2 border-bkashprimary rounded outline-none"
                  name="message"
                  value={message}
                  onChange={handleMessageChange}
                  id="message"
                  cols="20"
                  rows="3"
                  placeholder="Please write your message!"
                ></textarea>
              </div>

              <input
                className="w-full bg-bkashprimary text-white rounded font-medium py-2 cursor-pointer mt-5 mb-2"
                type="submit"
                value="Submit"
              />
            </form>
          </section>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-center items-center text-bkashprimary bg-[#FFF0F6] p-1 rounded-md">
            <Link to="/mobileBanking/order-history">
              <button className="inline-block rounded-md px-4 py-2 text-sm hover:text-bkashprimary focus:relative">
                Order History
              </button>
            </Link>
            <Link to="/mobileBanking/sales-report">
              <button className="inline-block rounded-md px-4 py-2 text-sm hover:text-bkashprimary focus:relative">
                Sales Reports
              </button>
            </Link>
          </div>
          <div className="p-5 text-bkashprimary bg-[#FFF0F6] text-center rounded-md">
            <h1>সব আর্থিক সল্যুশন এক প্ল্যাটফর্মে</h1>
            <p className="text-xs text-justify">
              ওয়ালেট-এর আর্থিক সল্যুশনের মাধ্যমে আপনার ব্যবসায়িক লেনদেন করতে
              পারবেন আরো দ্রুত, সহজে ও নিরাপদে। তাই আরো দক্ষভাবে ব্যবসা পরিচালনা
              করুন ওয়ালেট এর মাধ্যমে।
            </p>
          </div>
        </div>
      </div>
      {isOpen && (
        <AgentBkashModal
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          formData={formData}
          setFormSubmitted={setFormSubmitted}
        />
      )}
    </div>
  );
};

export default MobileNewRequest;
