import AdminAddAgent from "../Component/AdminDashboard/AdminAddAgent/AdminAddAgent";
import AdminAddBalance from "../Component/AdminDashboard/AdminAddBalance/AdminAddBalance";
import AdminEditPayment from "../Component/AdminDashboard/AdminAddBalance/AdminEditPayment";
import AdminPaymentReport from "../Component/AdminDashboard/AdminAddBalance/AdminPaymentReport";
import AdminAllAgent from "../Component/AdminDashboard/AdminAllAgent/AdminAllAgent";
import AdminAddUser from "../Component/AdminDashboard/AdminAllUser/AdminAddUser";
import AdminAllUser from "../Component/AdminDashboard/AdminAllUser/AdminAllUser";
import AdminAllCargoDetails from "../Component/AdminDashboard/AdminCargoDetails/AdminAllCargoDetails";
import AdminCargoDetails from "../Component/AdminDashboard/AdminCargoDetails/AdminCargoDetails";
import AdminUpdateCargoDetails from "../Component/AdminDashboard/AdminCargoDetails/AdminUpdateCargoDetails";
import AdminAddPackage from "../Component/AdminDashboard/AdminDashboardAll/AdminAddPackage";
import AdminUpdatePackage from "../Component/AdminDashboard/AdminDashboardAll/AdminUpdatePackage";
import adminAllPackage from "../Component/AdminDashboard/AdminDashboardAll/adminAllPackage";
import AdminDashboardIndex from "../Component/AdminDashboard/AdminDashboardIndex";
import AdminDashboardUpdate from "../Component/AdminDashboard/AdminDashboardUpdate/AdminDashboardUpdate";
import AdminBkashOrders from "../Component/AdminDashboard/AdminOrders/AdminBkashOrders";
import AdminBkashViewOrder from "../Component/AdminDashboard/AdminOrders/AdminBkashViewOrder";
import AdminRiccaricaConfirmOrders from "../Component/AdminDashboard/AdminOrders/AdminRiccaricaConfirmOrders";
import AdminRiccaricaOrderView from "../Component/AdminDashboard/AdminOrders/AdminRiccaricaOrderView";
import AdminRiccaricaOrders from "../Component/AdminDashboard/AdminOrders/AdminRiccaricaOrders";
import AdminRiccaricaRejectOrders from "../Component/AdminDashboard/AdminOrders/AdminRiccaricaRejectOrders";
import AdminCargoUpdateStatus from "../Component/AdminDashboard/AdminOrders/Cargo/AdminCargoUpdateStatus";
import CargoOrder from "../Component/AdminDashboard/AdminOrders/Cargo/CargoOrder";
import AdminBkashReports from "../Component/AdminDashboard/AdminReports/AdminBkashReports";
import AdminCargoReports from "../Component/AdminDashboard/AdminReports/AdminCargoReports";
import AdminRiccaricaReports from "../Component/AdminDashboard/AdminReports/AdminRiccaricaReports";
import AdminUpdateCommission from "../Component/AdminDashboard/AdminUpdateCommission/AdminUpdateCommission";
import AdminUpdateExchangeRate from "../Component/AdminDashboard/AdminUpdateExchangeRate/AdminUpdateExchangeRate";
import ChangePassword from "../Shared/ChangePassword";
import CommonUpdateUser from "../Shared/CommonUpdateUser";

const AdminRoute = [
  { path: "overview", Component: AdminDashboardIndex },
  { path: "changePassword", Component: ChangePassword },
  {
    path: "admin-add-package",
    Component: AdminAddPackage,
  },

  {
    path: "admin-all-package",
    Component: adminAllPackage,
  },

  {
    path: "admin-all-package/admin-update-package/:id",
    Component: AdminUpdatePackage,
  },
  { path: "allAgent", Component: AdminAllAgent },
  { path: "addAgent", Component: AdminAddAgent },
  { path: "updateAgent/:id", Component: CommonUpdateUser },

  { path: "allUser", Component: AdminAllUser },
  { path: "addUser", Component: AdminAddUser },
  { path: "updateUser/:id", Component: CommonUpdateUser },
  { path: "admin-riccarica-Orders", Component: AdminRiccaricaOrders },
  {
    path: "admin-riccarica-Orders/admin-riccarica-confirm-orders",
    Component: AdminRiccaricaConfirmOrders,
  },

  {
    path: "admin-riccarica-Orders/admin-riccarica-reject-orders",
    Component: AdminRiccaricaRejectOrders,
  },

  {
    path: "admin-riccarica-Orders/:id",
    Component: AdminRiccaricaOrderView,
  },

  {
    path: "admin-bkash-orders",
    Component: AdminBkashOrders,
  },


  {
    path: "admin-bkash-orders/:id",
    Component: AdminBkashViewOrder,
  },

 
  {
    path: "updateExchangeRate", Component: AdminUpdateExchangeRate,
  },

  {path:"admin-bkash-report/:id",Component: AdminBkashReports},
  {path:"admin-riccarica-report/:id",Component: AdminRiccaricaReports},
  {path:"admin-cargo-report/:id",Component: AdminCargoReports},

  {path:"updateCargoDetails",Component: AdminCargoDetails},
  {path:"allCargoDetails",Component: AdminAllCargoDetails},
  {path:"updateCargoDetails/:id",Component: AdminUpdateCargoDetails},
  {path:"cargo-order",Component: CargoOrder},
  {path:"cargo-order-update/:id",Component: AdminCargoUpdateStatus},
  {path:"updateCommission",Component: AdminUpdateCommission},
  {path:"updateDashboard",Component: AdminDashboardUpdate},
  {path:"addBalance",Component: AdminAddBalance},
  {path:"admin-payment-report",Component: AdminPaymentReport},
  {path:"admin-edit-payment/:id",Component: AdminEditPayment}






];

export default AdminRoute;
