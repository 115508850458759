import moment from 'moment';
import React, { useEffect, useState } from 'react';
import UpdateHooks from '../../../Hooks/UpdateHooks';
import PostHooks from '../../../Hooks/PostHooks';
import { server_url } from '../../../Config/API';
import { toast } from 'react-toastify';
import Loading from '../../../Shared/Loading';

const AdminUpdateExchangeRate = () => {
    let date = moment().format("YYYY-MM-D");
    const [loading,setLoading]=useState(false)
    const [formData,setFormData]=useState({
        bdtExchangeRate:"",
        euroExchangeRate:"",
        date:date,
    })

    useEffect(()=>{
        setLoading(true)
        fetch(`https://walletserver.genzamwallet.com/api/v1/exchangeRate/getExchangeRate`).then(res=>res.json()).then(data=>{
            if(data?.data){
                setFormData(data?.data[0]) 
            }
            setLoading(false)
        })
    },[])

    const handelUpdate =async (e)=>{
        e.preventDefault()
        if(formData?._id){
            await UpdateHooks(
                `${server_url}/exchangeRate/updateExchangeRate/${formData?._id}`,
                formData
              );
              toast.success("Exchange Rate Updated")
        }else{
            PostHooks(
                `${server_url}/exchangeRate/addExchangeRate`,
                formData,
                "ExchangeRate Updated !"
              );
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };


 if(loading){
return <Loading/>
 }
    return (
        <div>
            <div className=" rounded-lg">
                <div className="mt-3  text-2xl font-bold">
                    Update ExchangeRate
                </div>
                <p className='mt-1'> <span className='font-semibold '>Date:</span> {date}</p>
                <form onSubmit={handelUpdate}>
                    <div className="mt-5 shadow-md rounded-lg px-3 py-5">
                        <div className="lg:flex w-full mb-5 gap-4">
                            <div className="w-full">
                                <label>BDT ExchangeRate (<span className='text-xs'>1 EURO to BDT</span>)</label>
                                <input
                                    type="text"
                                    name="bdtExchangeRate"
                                    onChange={handleChange}
                                    defaultValue={formData?.bdtExchangeRate}
                                    className="mt-1 w-full block rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                                    placeholder="Enter bdt ExchangeRate"
                                    
                                />
                            </div>
                            <div className="w-full">
                                <label>Euro ExchangeRate (<span className='text-xs'>1 BDT to EURO</span>)</label>
                                <input
                                    type="text"
                                    name="euroExchangeRate"
                                    defaultValue={formData?.euroExchangeRate}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                                    placeholder="Enter Euro ExchangeRate"
                                />
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="cursor-pointer rounded-lg bg-primary px-4 py-3 font-semibold text-white hover:scale-105 duration-300"
                        >
                            Update ExchangeRate
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AdminUpdateExchangeRate;