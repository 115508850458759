import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import MyPaymentPostHook from "../../../Hooks/MyPaymentPostHook";
import { server_url } from "../../../Config/API";
import AuthUser from "../../../Hooks/authUser";


const AdminAddBalance = () => {
  let date = moment().format("YYYY-MM-D");
  const [user, setUser] = useState([]);
  const [refresh, setRefresh] = useState(false);
//   const [myData, setMyData] = useState([]);
  const [formData, setFormData] = useState({
    userName: "",
    serviceName: "",
    status: "",
    amount: "",
  });
  const data = ["Bkash", "Riccarica", "Cargo"];
  const data2 = [ "complete", "pending", "return"];
  const {userInfo}=AuthUser();

  // load data by user name
  useEffect(() => {
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/user/specific?fieldName=${"userName"}&&fieldValue=${
        formData?.userName
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length) {
          setUser(data?.data[0]);
        }else{
            setUser([])
        }
      });
  }, [formData?.userName, refresh]);


  const handelUpdate = async (e) => {
    e.preventDefault();
    if (user?.userName) {
      if (formData?.serviceName == "Cargo") {
      
          await fetch(`${server_url}/user/${user?._id}`, {
            method: "PATCH",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              cargoBalance:
                Number(user?.cargoBalance) + Number(formData?.amount),
            }),
          })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              const data = {
                agentId: userInfo?._id,
                agentUserName: userInfo?.userName,
                recipientNumber: user?.phone,
                recipientId: user?._id,
                recipientUserName: user?.userName,
                amount: formData?.amount, serviceName: formData?.serviceName,
                date: date,
                status: formData?.status,
              };
              MyPaymentPostHook(data);
              toast?.success("User Balance Updated !");
            }
          });
        
      } else if (formData?.serviceName == "Bkash") {
      
          await fetch(`${server_url}/user/${user?._id}`, {
            method: "PATCH",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              mobileBalance:
                Number(user?.mobileBalance) + Number(formData?.amount),
            }),
          })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              const data = {
                agentId: userInfo?._id,
                agentUserName: userInfo?.userName,
                recipientNumber: user?.phone,
                recipientId: user?._id,
                recipientUserName: user?.userName,
                amount: formData?.amount, serviceName: formData?.serviceName,
                date: date,
                status: formData?.status,
              };
              MyPaymentPostHook(data);
              toast?.success("User Balance Updated !");
            }
          });
       
      } else if (formData?.serviceName == "Riccarica") {
       
          await fetch(`${server_url}/user/${user?._id}`, {
            method: "PATCH",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              riccaricaBalance:
                Number(user?.riccaricaBalance) + Number(formData?.amount),
            }),
          })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              const data = {
                agentId: userInfo?._id,
                agentUserName: userInfo?.userName,
                recipientNumber: user?.phone,
                recipientId: user?._id,
                recipientUserName: user?.userName,
                amount: formData?.amount, serviceName: formData?.serviceName,
                date: date,
                status: formData?.status,
              };
              MyPaymentPostHook(data);
              toast?.success("User Balance Updated !");
            }
          });
        
      }
    } else {
      toast?.error("Don't have any user");
    }

    e.target.reset();

    setTimeout(() => {
      setRefresh(!refresh);
    }, 1000);
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <form onSubmit={handelUpdate} className="my-10">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="flex">
          <label className=" py-2 pl-4 w-56 rounded bg-gray-200">
            UserName:
          </label>
          <input
            name="userName"
            onChange={handleChange}
            required
            type="text"
            className="border w-full px-3 outline-none rounded "
          ></input>
        </div>

        <div className="flex">
          <label className=" py-2 pl-4 w-56 rounded bg-gray-200">
            Service Name:
          </label>

          <select
            name="serviceName"
            onChange={handleChange}
            required
            className="border border-stone-400 w-full px-3 outline-none  "
          >
            <option value="" disabled selected>
              Select
            </option>

            {data?.map((cat, index) => (
              <option key={index} cat={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 my-10 gap-5">
        <div className="flex">
          <label className=" py-2 pl-4 w-56 rounded bg-gray-200">
            Payment Status:
          </label>

          <select
            name="status"
            required
            onChange={handleChange}
            className="border border-stone-400 w-full rounded px-3 outline-none  "
          >
            <option value="" disabled selected>
              Select
            </option>

            {data2?.map((cat, index) => (
              <option key={index} cat={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="flex">
          <label className=" py-2 border mr-[1.5px] pl-4 w-56 rounded bg-gray-200">
            Current Balance
          </label>
          <div className="bg-gray-200 rounded-md px-3 py-2 w-full">
            {formData?.serviceName == "Cargo" && <p>{user?.cargoBalance?.toFixed(2)}</p>}
            {formData?.serviceName == "Bkash" && <p>{user?.mobileBalance?.toFixed(2)}</p>}
            {formData?.serviceName == "Riccarica" && (
              <p>{user?.riccaricaBalance?.toFixed(2)}</p>
            )}
          </div>
        </div>

        <div className="flex">
          <label className=" py-2 pl-4 w-56 rounded bg-gray-200">Amount</label>

          <input
            name="amount"
            onChange={handleChange}
            type="number"
            required
            className="border w-full px-3 outline-none rounded "
          ></input>
        </div>
      </div>

      <button type="submit" className="bg-green-600 mt-10 mb-20 hover:bg-green-700 duration-300 px-20 py-1 text-center rounded-md text-white text-lg font-semibold">
        Add
      </button>
      
    </form>
  );
};

export default AdminAddBalance;