import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const ReceiverInfoForm = ({ receiverInfo, setReceiverInfo }) => {
  const [country, setCountry] = useState([])
  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all?fields=name")?.then(res => res.json().then(data => setCountry(data)))
  }, [])

  const handleChange = (e) => {
    setReceiverInfo({
      ...receiverInfo,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className=" mt-5">
      <div>
        <h1 className=" font-bold text-xl">Receiver Information</h1>
      </div>
      <div className=" lg:grid grid-cols-2 gap-5    font-thin">
        <div className=" ">
          <div>
            <label >First Name:</label>
            <br />
            <input
              type="text"
              name="firstName"
              onChange={handleChange}
              className=" p-1 outline-none border-gray border w-full"
              placeholder="Enter Sender First Name"
            />
          </div>

          <div>
            <label >Document No.</label>
            <br />
            <input
              type="text"
              name="documentNo"
              onChange={handleChange}
              className=" p-1 outline-none border-gray border w-full"
              placeholder="Enter Document  No"
            />
          </div>

          <div>
            <label >Phone:</label>
            <br />
            <input
              type="text"
              name="phone"
              onChange={handleChange}
              className=" p-1 outline-none border-gray border w-full"
              placeholder="Enter Phone  No"
            />
          </div>

          <div>
            <label >City:</label>
            <br />
            <input
              type="text"
              name="city"
              onChange={handleChange}
              className=" p-1 outline-noneborder-gray border w-full"
              placeholder="Enter City "
            />
          </div>

          <div>
            <label >Post Code:</label>
            <br />
            <input
              type="number"
              name="postCode"
              onChange={handleChange}
              className=" p-1 outline-none border-gray border w-full"
              placeholder="Enter post code "
            />
          </div>

          <div>
            <label >Address:</label>
            <br />
            <textarea
              type="text"
              name="address"
              onChange={handleChange}
              className=" p-1 outline-none border-gray border w-full"
              placeholder="Enter address  "
            />
          </div>
        </div>

        <div>
          <div>
            <label >last Name:</label>
            <br />
            <input
              type="text"
              name="lastName"
              onChange={handleChange}
              className=" p-1 outline-none border-gray border w-full"
              placeholder="Enter Sender Last Name"
            />
          </div>
          <div>
            <label >Email:</label>
            <br />
            <input
              type="email"
              name="email"
              onChange={handleChange}
              className=" p-1 outline-none border-gray border w-full"
              placeholder="Enter Sender Last Name"
            />
          </div>


          <div>
            <label >Country:</label>
            <br />
            <select name="country" onChange={handleChange} className=" p-2 w-80 border bg-white">

              {
                country?.map(c => <option value={c?.name?.common}>{c?.name?.common}</option>)
              }
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiverInfoForm;