import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect } from "react";
import { server_url } from "../Config/API";
import AuthUser from "../Hooks/authUser";
import { toast } from "react-toastify";
import moment from "moment";

const AgentRiccaricaModal = ({ isOpen, setIsOpen, data, formData,onSuccess }) => {
  const { userInfo } = AuthUser();
  let date = moment().format("YYYY-MM-D");
  const [pin, setPin] = useState("");
  const [myData, setMyData] = useState([]);

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setMyData(data?.data));
  }, [userInfo?._id]);

  const calculateCommission = (inputAmount) => {
    if (inputAmount) {
      const commission = (inputAmount / 100) * myData?.agentRiccaricaCommission;
      return commission;
    } else {
      return 0;
    }
  };

  const amountEuro = Number(data?.priceEuro);
  const serviceChargeEuro = Number(formData?.serviceCharge);
  const commissionEuro = Number(data?.commissionEuro);

  const agentCommission = calculateCommission(commissionEuro);
  const userCommission = Number(commissionEuro) - Number(agentCommission);
  const total = serviceChargeEuro + amountEuro - userCommission;

  const handelUpdate = async () => {
    if (myData?.pin === pin) {
      if (total <= myData?.riccaricaBalance) {
        await fetch(`${server_url}/riccaricaOrder/addRiccaricaOrder`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ordererUserName: userInfo?.userName,
            ordererId: userInfo?._id,
            referralId: userInfo?.referralId,
            ordererPhone: userInfo?.phone,
            receiverMobileNumber: formData?.receiverMobileNumber,
            packageType: data?.packageType,
            note: formData?.note,
            serviceChargeEuro: serviceChargeEuro,
            commissionEuro: commissionEuro,
            amountEuro: amountEuro,
            totalAmountEuro: total,
            date,
            package: data,
            referralUserName: userInfo?.referralUserName,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "success") {
              fetch(`${server_url}/user/${myData?._id}`, {
                method: "PATCH",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  riccaricaBalance: Number(myData?.riccaricaBalance) - total,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data?.status === "success") {
                    if (myData?.agentRiccaricaCommission) {
                      fetch(`${server_url}/user/${myData?.referralId}`, {
                        method: "PATCH",
                        headers: {
                          "content-type": "application/json",
                        },
                        body: JSON.stringify({
                          riccaricaBalance:
                            myData?.riccaricaBalance + agentCommission,
                        }),
                      });
                    }

                    toast?.success("User Balance Updated !");
                    onSuccess()
                    setIsOpen(false);
                  }
                });
            }
          });
      } else {
        toast?.error("You don't have sufficient amount");
      }
    } else {
      toast?.error("Pin doesn't match!");
    }
  };

  console.log(formData, "This is formdata rica");

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm " />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-5/12 md:8/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all">
                <div className="bg-primary/10 py-2 flex justify-between items-center px-5">
                  <h2 className="text-lg font-semibold">Riccarica</h2>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>

                <div className="border m-5 p-2 rounded-lg grid grid-cols-2 gap-2">
                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Mobile Number</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      {formData?.receiverMobileNumber}
                    </p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Amount</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">€ {amountEuro}</p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Package Type</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">{data?.packageType}</p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Service Charge</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      € {serviceChargeEuro.toFixed(2)}
                    </p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Commission</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">€ {commissionEuro}</p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Total</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">€ {total.toFixed(2)}</p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Your Note</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">{formData?.note}</p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm mt-1">Pin</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <input
                      name="mobileBalance"
                      type="password"
                      onChange={(e) => setPin(e.target?.value)}
                      required
                      placeholder="Enter Your Pin"
                      className="w-full rounded-md  border border-gray-300 px-2 py-1  focus:outline-none"
                    />
                  </div>

                  <button
                    onClick={handelUpdate}
                    className="bg-primary w-fit my-2 py-2 px-4 rounded-md text-white  font-semibold hover:scale-105 duration-300"
                  >
                    Update !
                  </button>
                </div>
                <div className="border-t h-6 bg-primary/10"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AgentRiccaricaModal;
