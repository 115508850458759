import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../Shared/Loading";
import ReactToPrint from "react-to-print";

const RiccaricaOrderInvoice = React.forwardRef((props, ref) => {
  const [orderData, setOrderData] = useState(null);
  const { id } = useParams();
  const componentRef = useRef();

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/riccaricaOrder/getRiccaricaOrderById/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setOrderData(data); 
      })
      .catch((error) => {
        console.error("Error fetching order data:", error);
      });
  }, [id]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="bg-gray-100 p-8" ref={ref}>
      <div className="bg-white p-8 rounded-lg shadow-lg" ref={componentRef}>
        {orderData ? (
          <>
            <div className="flex justify-between">
              <div className="flex">
                <div className="ml-4">
                  <h1 className="text-2xl font-bold">Genzam-IT</h1>
                  <p>Rome, Italy</p>
                  <p>Email: genzamofficial@gmail.com</p>
                  <p>Phone: +39 351178 9888</p>
                </div>
              </div>
              <div>
                <h2 className="text-3xl font-bold">Invoice</h2>
                <p># {orderData?.data?._id}</p>
                <p>Invoice Date: {orderData?.data?.date}</p>
              </div>
            </div>

            <div className="mt-8">
              <h3 className="text-xl font-semibold">Bill To:</h3>
              <p>User Name: {orderData?.data?.ordererUserName}</p>
              <p>Phone: {orderData?.data?.ordererPhone}</p>
            </div>

            <table className="w-full mt-8">
              <thead>
                <tr>
                  <th className="text-left">Description</th>
                  <th className="text-left">Receiver Number</th>
                  <th className="text-left">Amount (BDT)</th>
                  <th className="text-left">Amount (Euro)</th>
                  <th className="text-left">Total</th>
                  <th className="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{orderData?.data?.packageType}</td>
                  <td>{orderData?.data?.receiverMobileNumber}</td>
                  <td>{orderData?.data?.amountBDT} ৳</td>
                  <td>{orderData?.data?.amountEuro} €</td>
                  <td>{orderData?.data?.totalAmount} €</td>
                  <td>{orderData?.data?.status}</td>
                </tr>
              </tbody>
            </table>

            <div className="mt-8">
              <p className="text-xl font-semibold">
                Total Amount: {orderData?.data?.totalAmount} €
              </p>
            </div>

            <div className=" text-right text-xs">
              <p>© 2023-2024 Genzam-IT. All Rights Reserved.</p>
            </div>

           
          </>
        ) : (
          <Loading />
        )}
      </div>

      <div className="mt-4 text-center">
            <ReactToPrint
              trigger={() => (
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handlePrint}
                >
                  Download Invoice
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
    </div>
  );
});

export default RiccaricaOrderInvoice;
