import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

const UserMobileUserSalesTable = ({
  u,
  setIsOpen,
  refetch,
  setRefetch,
  setData,
}) => {
  return (
    <>
      <tr className="odd:bg-gray-50">
        <td className="whitespace-nowrap px-4 border py-2 font-medium text-gray-900">
          {u?.userName}
        </td>

        <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
          {u?.email}
        </td>

        <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
          ৳ {u?.mobileBalance}
        </td>
        <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
          € {u?.riccaricaBalance}
        </td>
        <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
          € {u?.cargoBalance}
        </td>
        <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
          {u?.status}
        </td>

        <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
          <Link
            to={`/mobileBanking/user-sales-report/${u?._id}`}
            className="flex justify-center text-bkashprimary"
          >
            <Icon icon="mdi:report-line" />
          </Link>
        </td>
        {/* <td className="h-16 px-6  transition duration-300 border-slate-200  text-secondary text-lg flex gap-2 items-center cursor-pointer">
          <Link
            to={`/agentDashboard/updateUser/${u?._id}`}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="basil:edit-outline" />
          </Link>
          <button
            onClick={() => {
              setData(u);
              setIsOpen(true);
            }}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="streamline:money-cash-bag-dollar-bag-payment-cash-money-finance" />
          </button>

          <button
            aria-label="Open delete confirmation"
            onClick={() => {
              DeleteHook({
                refetch,
                setRefetch,
                url: `https://walletserver.genzamwallet.com/api/v1/user/${u?._id}`,
              });
            }}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="material-symbols:delete-outline" />
          </button>
        </td> */}
      </tr>
    </>
  );
};

export default UserMobileUserSalesTable;
