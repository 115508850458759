import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AdminUpdatePackage = () => {
  // Initialize packageData state with default values
  const navigate = useNavigate();

  const [packageData, setPackageData] = useState({
    operator: "",
    packageType: "",
    priceBDT: "",
    priceEuro: "",
    packageDetails: "",
    commissionBdt:"",
    commissionEuro:""

  });

  // Get the package ID from the URL using useParams
  const { id } = useParams();

  // Fetch package details based on packageId when the component mounts
  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/packages/packages/${id}`)
      .then((response) => response.json())
      .then((data) => {
        // Update the state with the fetched package data
        setPackageData(data);
      })
      .catch((error) => {
        console.error("Error fetching package details:", error);
      });
  }, [id]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update the state with the new form data
    setPackageData({
      ...packageData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Send a PUT request to update the package data
    fetch(`https://walletserver.genzamwallet.com/api/v1/packages/packages/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(packageData),
    })
      .then((response) => response.json())
      .then((data) => {
        navigate(-1);

        toast.success("Package updated successfully");
      })
      .catch((error) => {
        toast.error("Error updating package");
        console.error("Error updating package:", error);
        // Handle error, e.g., display an error message to the user
      });
  };

  console.log("all packages",packageData)
  return (
    <div>
      <div className="max-w-3xl mx-auto text-center ">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          Update Packages
        </h1>
        <p className="text-lg text-gray-800 mb-8">
          You can update your package.
        </p>
      </div>
      <form className=" border rounded-md" onSubmit={handleSubmit}>
        <div className="p-8">
          <div className="lg:flex gap-4">
            <div className="w-full">
              <label htmlFor="operator">Select Operator</label>
              <select
                name="operator"
                id="operator"
                className="block w-full mt-1 rounded-md border border-slate-300 bg-white px-3 py-4 font-semibold text-gray-500 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                value={packageData.operator}
                onChange={handleChange}
              >
                <option className="font-semibold text-slate-300">
                  Please Select Operator
                </option>
                <option
                  value="GrameenPhone (GP)"
                  className="font-semibold text-slate-300"
                >
                  GrameenPhone (GP)
                </option>
                <option
                  value="Banglalink (BL)"
                  class="font-semibold text-slate-300"
                >
                  Banglalink (BL)
                </option>{" "}
                <option
                  value="Robi Axiate Limited (Robi)"
                  class="font-semibold text-slate-300"
                >
                  Robi Axiate Limited (Robi)
                </option>{" "}
                <option
                  value="Airtel Bangladesh (Airtel)"
                  class="font-semibold text-slate-300"
                >
                  Airtel Bangladesh (Airtel)
                </option>{" "}
                <option
                  value="Teletalk Bangladesh Limited (Teletalk)"
                  class="font-semibold text-slate-300"
                >
                  Teletalk Bangladesh Limited (Teletalk)
                </option>{" "}
              </select>
            </div>
            <div className="w-full">
              <label htmlFor="packageType">Select Package type</label>
              <select
                name="packageType"
                id="packageType"
                className="block w-full mt-1 rounded-md border border-slate-300 bg-white px-3 py-4 font-semibold text-gray-500 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                value={packageData.packageType}
                onChange={handleChange}
              >
                <option className="font-semibold text-slate-300">
                  Please Select Package Type
                </option>
                <option value="Combo" className="font-semibold text-slate-300">
                  Combo
                </option>
                <option
                  value="talk Time"
                  className="font-semibold text-slate-300"
                >
                  Talk Time
                </option>
                <option
                  value="Internet"
                  className="font-semibold text-slate-300"
                >
                  Internet
                </option>
              </select>
            </div>
          </div>

          <div className="lg:flex w-full mb-5 gap-4">
            <div className="w-full">
              <label htmlFor="priceBDT">Package Price (BDT)</label>
              <input
                type="number"
                name="priceBDT"
                id="priceBDT"
                className="mt-1 w-full block rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                placeholder="Enter a price BDT *"
                min="0"
                value={packageData.priceBDT}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label htmlFor="priceEuro">Package Price (Euro)</label>
              <input
                type="number"
                name="priceEuro"
                id="priceEuro"
                className="mt-1 block w-full rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                placeholder="Enter a Price Euro *"
                value={packageData.priceEuro}
                onChange={handleChange}
              />
            </div>

            
          </div>
          <div className="lg:flex w-full mb-5 gap-4">
            <div className="w-full">
              <label htmlFor="priceBDT">Package Price Commission (BDT)</label>
              <input
                type="number"
                name="commissionBdt"
                id="commissionBdt"
                className="mt-1 w-full block rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                placeholder="Enter a Commission price BDT *"
                min="0"
                value={packageData.commissionBdt}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label htmlFor="priceEuro">Package Price Commission (Euro)</label>
              <input
                type="number"
                name="commissionEuro"
                id="commissionEuro"
                className="mt-1 block w-full rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                placeholder="Enter a Commission Price Euro *"
                value={packageData.commissionEuro}
                onChange={handleChange}
              />
            </div>

            
          </div>
          
          <div className="">
            <label htmlFor="packageDetails">Package Details</label>
            <textarea
              name="packageDetails"
              id="packageDetails"
              cols="30"
              rows="10"
              className="mb-10 mt-1 h-40 w-full resize-none rounded-md border border-slate-300 p-5 font-semibold text-black "
              placeholder="Enter package Details"
              value={packageData.packageDetails}
              onChange={handleChange}
            ></textarea>
          </div>


          
          <div className="text-center">
            <button
              type="submit"
              className="cursor-pointer rounded-lg bg-primary px-8 py-5 text-sm font-semibold text-white"
            >
              Update Package!
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminUpdatePackage;
