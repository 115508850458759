import moment from "moment";
import React, { useState, useEffect } from "react";
import AuthUser from "../../Hooks/authUser";
import { server_url } from "../../Config/API";
import { toast } from "react-toastify";
import MyPaymentPostHook from "../../Hooks/MyPaymentPostHook";
import { Banknote, Captions, Plus, UsersRound } from "lucide-react";

const AddBalance = () => {
  let date = moment().format("YYYY-MM-D");
  const [user, setUser] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { userInfo } = AuthUser();
  const [myData, setMyData] = useState([]);
  const [myUser, setMyUser] = useState([]);
  const [formData, setFormData] = useState({
    userName: "",
    serviceName: "",
    status: "",
    amount: "",
  });
  const data = ["Bkash", "Riccarica", "Cargo"];
  const data2 = ["Complete", "Pending", "Return"];

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setMyData(data?.data));
  }, [userInfo?._id]);

  // load my user
  useEffect(() => {
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/user/specific?fieldName=${"referralId"}&&fieldValue=${
        userInfo?._id
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        setMyUser(data?.data);
      });
  }, [userInfo?._id]);

  // load data by user name
  useEffect(() => {
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/user/specific?fieldName=${"userName"}&&fieldValue=${
        formData?.userName
      }&&fieldName2=${"referralId"}&&fieldValue2=${userInfo?._id}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length) {
          setUser(data?.data[0]);
        } else {
          setUser([]);
        }
      });
  }, [formData?.userName, refresh, userInfo?._id]);

  const handelUpdate = async (e) => {
    e.preventDefault();
    if (user?.userName) {
      if (formData?.serviceName === "Cargo") {
        if (formData?.amount <= myData?.cargoBalance) {
          await fetch(`${server_url}/user/${user?._id}`, {
            method: "PATCH",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              cargoBalance:
                Number(user?.cargoBalance) + Number(formData?.amount),
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.status === "success") {
                fetch(`${server_url}/user/${myData?._id}`, {
                  method: "PATCH",
                  headers: {
                    "content-type": "application/json",
                  },
                  body: JSON.stringify({
                    cargoBalance:
                      Number(myData?.cargoBalance) - Number(formData?.amount),
                  }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    if (data?.status === "success") {
                      const paymentData = {
                        agentId: userInfo?._id,
                        agentUserName: userInfo?.userName,
                        recipientNumber: user?.phone,
                        recipientId: user?._id,
                        recipientUserName: user?.userName,
                        amount: formData?.amount,
                        serviceName: formData?.serviceName,
                        date: date,
                        status: formData?.status,
                      };
                      MyPaymentPostHook(paymentData);
                      toast?.success("User Balance Updated!");
                    }
                  });
              }
            });
        } else {
          toast.error("You Don't have sufficient balance!");
        }
      } else if (formData?.serviceName === "Bkash") {
        if (formData?.amount <= myData?.mobileBalance) {
          await fetch(`${server_url}/user/${user?._id}`, {
            method: "PATCH",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              mobileBalance:
                Number(user?.mobileBalance) + Number(formData?.amount),
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.status === "success") {
                fetch(`${server_url}/user/${myData?._id}`, {
                  method: "PATCH",
                  headers: {
                    "content-type": "application/json",
                  },
                  body: JSON.stringify({
                    mobileBalance:
                      Number(myData?.mobileBalance) - Number(formData?.amount),
                  }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    if (data?.status === "success") {
                      const paymentData = {
                        agentId: userInfo?._id,
                        agentUserName: userInfo?.userName,
                        recipientNumber: user?.phone,
                        recipientId: user?._id,
                        recipientUserName: user?.userName,
                        amount: formData?.amount,
                        serviceName: formData?.serviceName,
                        date: date,
                        status: formData?.status,
                      };
                      MyPaymentPostHook(paymentData);
                      toast?.success("User Balance Updated!");
                    }
                  });
              }
            });
        } else {
          toast.error("You Don't have sufficient balance!");
        }
      } else if (formData?.serviceName === "Riccarica") {
        if (formData?.amount <= myData?.riccaricaBalance) {
          await fetch(`${server_url}/user/${user?._id}`, {
            method: "PATCH",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              riccaricaBalance:
                Number(user?.riccaricaBalance) + Number(formData?.amount),
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.status === "success") {
                fetch(`${server_url}/user/${myData?._id}`, {
                  method: "PATCH",
                  headers: {
                    "content-type": "application/json",
                  },
                  body: JSON.stringify({
                    riccaricaBalance:
                      Number(myData?.riccaricaBalance) -
                      Number(formData?.amount),
                  }),
                })
                  .then((res) => res.json())
                  .then((data) => {
                    if (data?.status === "success") {
                      const paymentData = {
                        agentId: userInfo?._id,
                        agentUserName: userInfo?.userName,
                        recipientNumber: user?.phone,
                        recipientId: user?._id,
                        recipientUserName: user?.userName,
                        amount: formData?.amount,
                        serviceName: formData?.serviceName,
                        date: date,
                        status: formData?.status,
                      };
                      MyPaymentPostHook(paymentData);
                      toast?.success("User Balance Updated!");
                    }
                  });
              }
            });
        } else {
          toast.error("You Don't have sufficient balance!");
        }
      }
    } else {
      toast?.error("Don't have any user");
    }
    setTimeout(() => {
      setRefresh(!refresh);
    }, 1000);
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form onSubmit={handelUpdate} className="my-10 p-5 border">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="flex gap-1">
          <label className="py-2 pl-4 w-56 rounded  bg-primary text-white flex gap-1 items-center">
            <UsersRound />
            User Name
          </label>

          <select
            name="userName"
            onChange={handleChange}
            required
            className="border border-primary w-full px-3 outline-none rounded-md "
          >
            <option value=" " disabled selected>
              Select
            </option>

            {myUser?.map((cat) => (
              <option className="  " value={cat?.userName}>
                {cat?.userName}
              </option>
            ))}
          </select>
        </div>

        <div className="flex gap-1">
          <label className="py-2 pl-4 w-56 rounded bg-primary">
            <div className=" flex  items-center gap-1 text-white ">
              <Captions />
              Service Name
            </div>
          </label>

          <select
            name="serviceName"
            onChange={handleChange}
            required
            className="border  border-primary w-full px-3 outline-none rounded-md"
          >
            <option value="" disabled selected>
              Select
            </option>

            {data?.map((cat, index) => (
              <option key={index} cat={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 my-10 gap-5">
        <div className="flex gap-1">
          <label className="py-2 pl-4 w-56 rounded  bg-primary text-white">
            <div className=" flex gap-1 items-center">
              <Banknote />
              Payment Status
            </div>
          </label>

          <select
            name="status"
            required
            onChange={handleChange}
            className="border border-primary w-full rounded px-3 outline-none"
          >
            <option value="" disabled selected>
              Select
            </option>

            {data2?.map((cat, index) => (
              <option key={index} cat={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="flex">
          <label className="py-2 border mr-[1.5px] pl-4 w-56 rounded bg-primary text-white">
            Current Balance
          </label>
          <div className="bg-primary text-white rounded-md px-3 py-2 w-full">
            {formData?.serviceName === "Cargo" && (
              <p>{user?.cargoBalance?.toFixed(2)}</p>
            )}
            {formData?.serviceName === "Bkash" && (
              <p>{user?.mobileBalance?.toFixed(2)}</p>
            )}
            {formData?.serviceName === "Riccarica" && (
              <p>{user?.riccaricaBalance?.toFixed(2)}</p>
            )}
          </div>
        </div>

        <div className="flex gap-1">
          <label className="py-2 pl-4 w-56 rounded  bg-primary text-white">
            <div className=" flex gap-1 items-center">
              <Banknote />
              Amount
            </div>
          </label>

          <input
            name="amount"
            onChange={handleChange}
            type="number"
            required
            placeholder=" Enter Your Amount"
            className="border w-full px-3 outline-none rounded border-primary"
          ></input>
        </div>
      </div>

      <button
        type="submit"
        className="bg-primary mt-10 mb-20 flex gap-1 items-center  hover:bg-primary-hover orgae duration-300 px-20 py-1 text-center rounded-md text-white text-lg font-semibold"
      >
        Add
        <Plus />
      </button>
    </form>
  );
};

export default AddBalance;
