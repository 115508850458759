import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthUser from "../../../Hooks/authUser";
import Pagination from "../../../Shared/Pagination";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MobileBankingOrderHistory = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { userInfo } = AuthUser();
  const [orders, setOrders] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [filterOrders, setFilterOrders] = useState([]);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);

  useEffect(() => {
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/mobileBankingOrder/specific?size=${size}&&page=${page}&&fieldName=ordererId&&fieldValue=${userInfo?._id}&&sortBy=date&&order=desc`
    ).then((res) =>
      res.json().then((data) => {
        setQuantity(data?.total);
        setOrders(data?.data);
        setFilterOrders(data?.data);
      })
    );
  }, [size, page, refetch, userInfo?._id]);
  

  const handleSearch = () => {
    const results = orders.filter((data) => {
      const customerName = data.customerName
        ? data.customerName.toLowerCase()
        : "";
      const ordererUserName = data.ordererUserName
        ? data.ordererUserName.toLowerCase()
        : "";
      const status = data.status ? data.status.toLowerCase() : "";
      const trackingId = data.trackingId ? data.trackingId.toLowerCase() : "";
      const paymentMethod = data.paymentMethod
        ? data.paymentMethod.toLowerCase()
        : "";
      const recipientNumber = data.recipientNumber || "";

      return (
        customerName.includes(searchTerm.toLowerCase()) ||
        ordererUserName.includes(searchTerm.toLowerCase()) ||
        status.includes(searchTerm.toLowerCase()) ||
        trackingId.includes(searchTerm.toLowerCase()) ||
        paymentMethod.includes(searchTerm.toLowerCase()) ||
        recipientNumber.includes(searchTerm)
      );
    });
    setFilterOrders(results);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  return (
    <div>
      <nav aria-label="Breadcrumb" className="flex">
        <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
          <li className="flex items-center">
            <a
              href="overview"
              className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>

              <span className="ms-1.5 text-xs font-medium"> Home </span>
            </a>
          </li>

          <li className="relative flex items-center">
            <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>

            <a
              href="#"
              className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900"
            >
              Order History
            </a>
          </li>
        </ol>
      </nav>

      <div className="mt-5 bg-[#FFF0F6] rounded-md p-5 ">
        <div className="flex justify-between mb-3">
          <h1 className="text-2xl">Orders History</h1>
          <Link
            to="/mobileBanking/newRequest"
            className="bg-bkashprimary p-2 text-xs text-white rounded hover:bg-bkashprimary/90"
          >
            New Request
          </Link>
        </div>
        <div className="flex relative rounded-md w-full my-5">
          <input
            type="text"
            placeholder="Tracking-Id, status, username, phone, order-name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-3 py-2 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
          />

          <button
            onClick={handleSearch}
            className="inline-flex items-center gap-2 bg-bkashprimary text-white text-lg font-semibold py-2 px-6 rounded-r-md hover:bg-bkashprimary/90"
          >
            <span>Search</span>
            <span className="hidden md:block">
              <Icon icon="material-symbols:search"></Icon>
            </span>
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-xs">
            <thead className="text-left bg-bkashprimary text-white">
              <tr>
                <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Tracking-ID
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Date
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">Entry By</th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">Trx</th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Payment Method
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Service Type
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Customer
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Recipient
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">Amount</th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Commission
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">Total</th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">Status</th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {orders.length === 0
                ? Array.from({ length: size }).map((_, i) => (
                    <tr key={i} className="odd:bg-gray-50">
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                    </tr>
                  ))
                : filterOrders.map((order, i) => (
                    <tr key={order._id} className="odd:bg-gray-50">
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        {i + 1}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {order.trackingId}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {order.date}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {order.ordererUserName.slice(0, 15)}...
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {order.trx}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {order.paymentMethod}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {order.serviceType}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {order.customerName}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {order.recipientNumber}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        ৳ {order.amount}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        ৳ {order.commission.toFixed(2)}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        ৳ {order.totalAmount.toFixed(2)}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {order.status === "confirmed" && (
                          <button className="bg-green-500 text-white px-2 py-1 text-xs rounded">
                            {order.status}
                          </button>
                        )}
                        {order.status === "pending" && (
                          <button className="bg-yellow-500 text-white px-2 py-1 text-xs rounded">
                            {order.status}
                          </button>
                        )}
                        {order.status === "rejected" && (
                          <button className="bg-red-500 text-white px-2 py-1 text-xs rounded">
                            {order.status}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>

        <Pagination
          quantity={quantity}
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
        />
      </div>
    </div>
  );
};

export default MobileBankingOrderHistory;
