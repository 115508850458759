import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";

const AdminRiccaricaTable = ({ order, i, refresh, setRefresh }) => {
  return (
    <tr className="shadow cursor-pointer">
      <td className="h-12 px-4 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {i + 1}
      </td>
      <td
        className="h-12 px-4 text-sm transition duration-300  border-slate-200 stroke-slate-500 text-blue-500 hover:underline cursor-pointer"
        onClick={() => {
          navigator.clipboard
            .writeText(order?.trackingId)
            .then(() => toast.success("Copied to clipboard"));
        }}
      >
        {order?.trackingId}
      </td>
      <td className="h-12 px-4 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {order?.date}
      </td>
      <td className="h-12 px-4 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {order?.ordererUserName}
      </td>

      <td
        className="h-12 px-4 text-sm transition duration-300  border-slate-200 stroke-slate-500 text-blue-500 hover:underline cursor-pointer"
        onClick={() => {
          navigator.clipboard
            .writeText(order?.receiverMobileNumber)
            .then(() => toast.success("Copied to clipboard"));
        }}
      >
        {order?.receiverMobileNumber}
      </td>
      <td className="h-12 px-4 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {order?.packageType}
      </td>
      <td className="h-12 px-4 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        € {order?.amountEuro?.toFixed(2)}
      </td>
      <td className="h-12 px-4 text-sm transition duration-300 border-slate-200  text-black">
        {order?.status === "pending" ? (
          <span className="bg-yellow-200 px-2 py-1 ">
            {order?.status}
          </span>
        ) : (
          order?.status
        )}
      </td>
      <td className="h-12 px-4 transition duration-300 border-slate-200 text-secondary text-lg flex gap-2 items-center cursor-pointer">
        <Link
          to={`${order?._id}`}
          className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
        >
          <Icon icon="carbon:view" />
        </Link>
      </td>
    </tr>
  );
};

export default AdminRiccaricaTable;
