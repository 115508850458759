import React, { useEffect, useState } from "react";
import AuthUser from "../../../Hooks/authUser";
import Loading from "../../../Shared/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MobileBankingUserSalesReports = () => {
  const { userInfo } = AuthUser();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterOrders, setFilterOrders] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedMonthTotalAmount, setSelectedMonthTotalAmount] = useState(0);
  const [selectedMonthTotalOrders, setSelectedMonthTotalOrders] = useState(0);
  const [selectedMonthTotalTransectionFee, setselectedMonthTotalTransectionFee] = useState(0);

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/mobileBankingOrder/getMobileBankingOrder`
    ).then((res) =>
      res.json().then((data) => {
        const confirmOrders = data?.data.filter((order) => {
          return (
            order.status === "confirmed" &&  order?.referralId === userInfo?.referralId
          );
        });

        setOrders(confirmOrders);
        setFilterOrders(confirmOrders);
        setLoading(false);
      })
    );
  }, [userInfo?.referralId]);

  const filterOrdersByDate = () => {
    if (selectedMonth) {
      const results = orders?.filter((data) => {
        const orderDate = new Date(data.date);
        return (
          orderDate.getMonth() === selectedMonth.getMonth() &&
          orderDate.getFullYear() === selectedMonth.getFullYear()
        );
      });
      // Calculate total amount and total orders for the selected month
      const totalAmount = results.reduce((acc, order) => acc + order.amount, 0);
      const totalOrders = results.length;
      const totaltransectionFee = results.reduce((acc, order) => acc + order.commission, 0);

      // Update state with the calculated values
      setSelectedMonthTotalAmount(totalAmount);
      setSelectedMonthTotalOrders(totalOrders);
      setselectedMonthTotalTransectionFee(totaltransectionFee);

      setFilterOrders(results);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <nav aria-label="Breadcrumb" className="flex mb-5">
        <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
          <li className="flex items-center">
            <a
              href="overview"
              className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>

              <span className="ms-1.5 text-xs font-medium"> Home </span>
            </a>
          </li>

          <li className="relative flex items-center">
            <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>

            <a
              href="sales-report"
              className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900"
            >
             User Sales Reports{" "}
            </a>
          </li>
        </ol>
      </nav>
      <div className=" mb-5 text-bkashprimary" ><h1 className=" text-center text-xl">User Sales Reports</h1></div>

      <div class="h-auto p-3 rounded-lg bg-gray-200 mb-5">
        <div className="max-w-md mx-auto lg:flex items-center justify-center border-2  gap-5 border-bkashprimary p-5 shadow-md">
          <div className=" mx-auto  text-center">
            <label htmlFor="month">
              <p className="text-bkashprimary font-medium">
                Select Month <span className="text-red-500">*</span>
              </p>
              <DatePicker
                selected={selectedMonth}
                onChange={(date) => setSelectedMonth(date)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                className="w-full  px-10 py-2 mt-1 bg-white border-2 border-bkashprimary outline-none cursor-pointer p-2 rounded"
                id="month"
              />
            </label>
          </div>

          <div className="  text-center">
            <p className="text-bkashprimary font-medium">
              Filter Now <span className="text-red-500">*</span>
            </p>
            <button
              className="bg-bkashprimary   px-10  w-full text-white  text-xl py-2 rounded-md"
              onClick={filterOrdersByDate}
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div className="text-center mb-5 lg:flex gap-5 justify-center items-center">
        <p className="text-bkashprimary font-medium">
          Total Amount for Selected Month: ৳ {selectedMonthTotalAmount}  
        </p>
        <p className=" " >||</p>
     
        <p className="text-bkashprimary font-medium">
          Total Transaction Fee for Selected Month: ৳ {selectedMonthTotalTransectionFee}
        </p>
        <p className=" ">||</p>
        <p className="text-bkashprimary font-medium">
          Total Orders for Selected Month: {selectedMonthTotalOrders}  
        </p>
      </div>


      <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-xs">
          <thead className="text-left bg-bkashprimary text-white">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Code</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Date</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Entry By
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Payment Method
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Service Type
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Customer
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Recipient
              </th>

              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Amount
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Commission
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Total</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Trx</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Status
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {[...filterOrders]?.reverse()?.map((order, i) => (
              <tr key={order?._id} order={order} className="odd:bg-gray-50">
                <td
                  key={order?._id}
                  order={order}
                  className="whitespace-nowrap px-4 py-2 font-medium text-gray-900"
                >
                  {i + 1}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order?._id}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order?.date}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order?.ordererUserName}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order?.paymentMethod}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order?.serviceType}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order?.customerName}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order?.recipientNumber}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order?.amount}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order?.commission}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order?.totalAmount}
                </td>

                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {order?.trx}
                </td>
                <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  <button className=" bg-green-500 px-4 py-1 text-white pb-1 rounded">
                    {" "}
                    {order?.status}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* ... Your table and pagination components ... */}
    </div>
  );
};

export default MobileBankingUserSalesReports;
