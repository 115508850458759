import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { server_url } from "../../../Config/API";
import { Icon } from "@iconify/react";

const AdminBkashViewOrder = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [orderer, setOrderer] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [trxNumber, setTrxNumber] = useState("");
  const [exchangeRate, setExchangeRate] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `https://walletserver.genzamwallet.com/api/v1/mobileBankingOrder/getMobileBankingOrderById/${id}`
      )
      .then((response) => {
        setOrder(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching order details:", error);
      });
  }, [id]);

  useEffect(() => {
    if (order?.ordererId) {
      fetch(`${server_url}/user/${order.ordererId}`)
        .then((res) => res.json())
        .then((data) => setOrderer(data?.data));
    }
  }, [order]);

  useEffect(() => {
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/exchangeRate/getExchangeRate`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.data) {
          setExchangeRate(data?.data[0]);
        }
      });
  }, []);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleUpdateStatus = async () => {
    if (selectedStatus && trxNumber) {
      if (selectedStatus === "rejected") {
        fetch(
          `https://walletserver.genzamwallet.com/api/v1/mobileBankingOrder/updateMobileBankingOrder/${id}`,
          {
            method: "PATCH",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ status: selectedStatus, trx: trxNumber }),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              fetch(
                `https://walletserver.genzamwallet.com/api/v1/user/${order?.ordererId}`,
                {
                  method: "PATCH",
                  headers: {
                    "content-type": "application/json",
                  },
                  body: JSON.stringify({
                    mobileBalance: orderer?.mobileBalance + order?.totalAmount,
                  }),
                }
              ).then((res) => res.json());
            }
          });

        setSelectedStatus("");
        setTrxNumber("");
        toast.success("Order Status Updated");
        navigate(-1);
      } else {
        await UpdateHooks(
          `${server_url}/mobileBankingOrder/updateMobileBankingOrder/${order?._id}`,
          { status: selectedStatus, trx: trxNumber }
        );
        setSelectedStatus("");
        setTrxNumber("");
        navigate(-1);
        toast.success("Order Status Updated");
      }
    } else {
      toast.error("Please select a status and enter a transaction number");
    }
  };

  if (!order) {
    return <Skeleton height={200} />;
  }

  return (
    <div className="p-4">
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-2xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          View Bkash Orders (tracking-Id{" "}
          <span
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() => {
              navigator.clipboard
                .writeText(order?.trackingId)
                .then(() => toast.success("Copied to clipboard"));
            }}
          >
            {order?.trackingId}
          </span>
          )
        </h1>
      </div>

      <div className="space-y-1">
        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Orderer UserName
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.ordererUserName || <Skeleton />}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Referral UserName
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.referralUserName ? order?.referralUserName : "N/A"}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Recipient Phone Number
          </label>
          <h1
            className="border text-blue-500 hover:underline cursor-pointer font-bold p-2 rounded"
            onClick={() => {
              navigator.clipboard
                .writeText(order?.recipientNumber)
                .then(() => toast.success("Copied to clipboard"));
            }}
          >
            {order?.recipientNumber || <Skeleton />}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Payment Method
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.paymentMethod || <Skeleton />}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Service Type
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.serviceType || <Skeleton />}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Customer Name
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.customerName || <Skeleton />}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Amount BDT
          </label>
          <h1 className="border text-blue-500 font-bold p-2 rounded">
            {order?.amount || <Skeleton />} BDT
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Transaction Charge BDT
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.commission || <Skeleton />} BDT
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Total Amount BDT
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.totalAmount || <Skeleton />} BDT
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Note
          </label>
          <p className="border text-black font-bold p-2 rounded">
            {order?.message ? order?.message : "N/A"}
          </p>
        </div>
        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Status
          </label>
          <p
            className={`border ${
              order?.status === "pending"
                ? "text-yellow-500"
                : order?.status === "confirmed"
                ? "text-green-500"
                : "text-red-500"
            } font-bold p-2 rounded`}
          >
            {order?.status || <Skeleton />}
          </p>
        </div>
      </div>
      <div className="w-full mt-4">
        <label className="text-sm text-gray-600">Enter Your TRX Number</label>
        <br />
        <input
          className="w-full border p-2"
          type="number"
          name="trx"
          placeholder="Enter Your Trx number"
          value={trxNumber}
          onChange={(e) => setTrxNumber(e.target.value)}
          required
        />
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center mb-5 p-5 mt-6 bg-white shadow rounded-md">
        <div className="flex flex-col md:flex-row items-center mb-4 md:mb-0">
          <label
            htmlFor="status"
            className="text-sm flex items-center gap-1 px-6 py-3 bg-[#0A1849] text-white font-medium mr-3 mb-2 md:mb-0"
          >
            <Icon icon="bi:clipboard-check" />
            Status
          </label>
          <select
            id="status"
            onChange={handleStatusChange}
            className="cursor-pointer px-6 py-3 border border-gray-300 text-sm text-gray-700 focus:ring-primary focus:border-primary"
            value={selectedStatus}
          >
            <option disabled value="">
              Select Status
            </option>
            <option value="pending">Pending</option>
            <option value="confirmed">Confirmed</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
        <button
          onClick={handleUpdateStatus}
          className="flex items-center justify-center px-6 py-2 bg-[#0A1849] text-white text-sm font-medium rounded-md hover:bg-primary-dark transition duration-300 ease-in-out"
        >
          <Icon icon="mdi:update" /> Update!
        </button>
      </div>
    </div>
  );
};

export default AdminBkashViewOrder;
