import React, { useState, useEffect } from "react";
import { singleImageUpload } from "../Hooks/ImageUpload";
import saveAs from "file-saver";
import { useParams } from "react-router-dom";
import bcrypt from "bcryptjs";
import UpdateHooks from "../Hooks/UpdateHooks";
import { toast } from "react-toastify";
import Loading from "./Loading";
import { Icon } from "@iconify/react";
import AuthUser from "../Hooks/authUser";

const CommonUpdateUser = () => {
  const { userInfo } = AuthUser();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [contactPaper, setContactPaper] = useState("");
  const [documentFile, setDocumentFile] = useState("");
  const [visuraFiles, setVisuraFiles] = useState("");
  const [formData, setFormData] = useState({
    additionalInformation: "",
    password: "",
    userName: "",
    pceId: "",
    companyName: "",
    name: "",
    phone: "",
    email: "",
    codiceFiscale: "",
    pivaNumber: "",
    codiceUnivoco: "",
    address: "",
    city: "",
    country: "",
    agentCommission: 0,
    agentRiccaricaCommission: 0,
    agentCargoCommission: 0,
    discount: 0,
    status: "active",
  });

  useEffect(() => {
    setLoading(true);
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setFormData(data?.data);
        setLoading(false);
      });
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const pass = event.target.password.value;
    const hashedPassword = pass ? bcrypt.hashSync(pass) : formData?.password;

    const data = {
      ...formData,
      pin: pin || null,
      contactPaper: contactPaper || formData?.contactPaper,
      documentFile: documentFile || formData?.documentFile,
      visuraFiles: visuraFiles || formData?.visuraFiles,
      password: hashedPassword,
    };

    await UpdateHooks(`https://walletserver.genzamwallet.com/api/v1/user/${id}`, data);
    toast.success("Data updated!");
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    const { name } = event.target;

    if (name === "contactPaper") singleImageUpload(formData, setContactPaper);
    if (name === "visuraFiles") singleImageUpload(formData, setVisuraFiles);
    if (name === "documentFile") singleImageUpload(formData, setDocumentFile);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen text-black p-5">
      <section className="dark:bg-gray-800 dark:text-gray-50">
        <form
          onSubmit={handleSubmit}
          className="container flex flex-col mx-auto space-y-12 rounded-md"
        >
          <fieldset className="grid grid-cols-4 gap-6 rounded-md dark:bg-gray-900">
            <div className="space-y-2 col-span-full lg:col-span-1">
              <p className="font-medium">Personal Information</p>
              <p className="text-xs">
                Please provide the user's personal information for updating their profile.
              </p>
            </div>
            <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="companyName" className="text-sm">
                  Company/Shop Name
                </label>
                <input
                  name="companyName"
                  type="text"
                  defaultValue={formData?.companyName}
                  onChange={handleChange}
                  required
                  placeholder="Enter Company or Shop Name"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="name" className="text-sm">
                  Customer Name
                </label>
                <input
                  type="text"
                  required
                  name="name"
                  onChange={handleChange}
                  defaultValue={formData?.name}
                  placeholder="Enter User Full Name"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="phone" className="text-sm">
                  Phone Number
                </label>
                <input
                  type="number"
                  required
                  name="phone"
                  onChange={handleChange}
                  defaultValue={formData?.phone}
                  placeholder="Enter User Phone Number"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="email" className="text-sm">
                  E-mail
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  defaultValue={formData?.email}
                  required
                  placeholder="Enter User E-mail"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="codiceFiscale" className="text-sm">
                  Codice Fiscale
                </label>
                <input
                  type="text"
                  name="codiceFiscale"
                  onChange={handleChange}
                  defaultValue={formData?.codiceFiscale}
                  placeholder="Enter Codice Fiscale"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="pivaNumber" className="text-sm">
                  P.IVA Number
                </label>
                <input
                  type="number"
                  name="pivaNumber"
                  onChange={handleChange}
                  defaultValue={formData?.pivaNumber}
                  placeholder="Enter P.IVA Number"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="pceId" className="text-sm">
                  Pec ID
                </label>
                <input
                  type="text"
                  required
                  name="pceId"
                  onChange={handleChange}
                  defaultValue={formData?.pceId}
                  placeholder="Enter Pec ID"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="codiceUnivoco" className="text-sm">
                  Codice Univoco
                </label>
                <input
                  type="text"
                  name="codiceUnivoco"
                  onChange={handleChange}
                  defaultValue={formData?.codiceUnivoco}
                  placeholder="Enter Codice Univoco"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-2">
                <label htmlFor="address" className="text-sm">
                  Address
                </label>
                <input
                  name="address"
                  onChange={handleChange}
                  defaultValue={formData?.address}
                  type="text"
                  required
                  placeholder="Enter Address"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-2">
                <label htmlFor="city" className="text-sm">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  onChange={handleChange}
                  defaultValue={formData?.city}
                  placeholder="Enter City"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-2">
                <label htmlFor="country" className="text-sm">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  onChange={handleChange}
                  defaultValue={formData?.country}
                  placeholder="Enter Country"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="contactPaper" className="text-sm">
                  Contact Paper
                </label>
                <div className="flex justify-center items-center">
                  <input
                    type="file"
                    name="contactPaper"
                    onChange={handleChangeUploadImage}
                    className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                  />
                  <div>
                    <p
                      onClick={() => saveAs(formData?.contactPaper)}
                      className="px-6 py-4 text-2xl text-primary cursor-pointer"
                    >
                      <Icon icon="mingcute:download-fill" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="documentFile" className="text-sm">
                  Document File
                </label>
                <div className="flex justify-center items-center">
                  <input
                    type="file"
                    name="documentFile"
                    onChange={handleChangeUploadImage}
                    className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                  />
                  <div>
                    <p
                      onClick={() => saveAs(formData?.documentFile)}
                      className="px-6 py-4 text-2xl text-primary cursor-pointer"
                    >
                      <Icon icon="mingcute:download-fill" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="visuraFiles" className="text-sm">
                  Visura Files
                </label>
                <div className="flex justify-center items-center">
                  <input
                    type="file"
                    name="visuraFiles"
                    onChange={handleChangeUploadImage}
                    className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                  />
                  <div>
                    <p
                      onClick={() => saveAs(formData?.visuraFiles)}
                      className="px-6 py-4 text-2xl text-primary cursor-pointer"
                    >
                      <Icon icon="mingcute:download-fill" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="status" className="text-sm">
                  Status
                </label>
                <select
                  name="status"
                  onChange={handleChange}
                  defaultValue={formData?.status}
                  className="w-full rounded-md focus:ring bg-white border-2 p-2"
                >
                  <option
                    selected={formData?.status === "active"}
                    value="active"
                  >
                    Active
                  </option>
                  <option
                    selected={formData?.status === "inactive"}
                    value="inactive"
                  >
                    Inactive
                  </option>
                </select>
              </div>

              <div className="col-span-3">
                <label htmlFor="pin" className="text-sm">
                  Pin (Bkash/Riccarica)
                </label>
                <input
                  name="pin"
                  onChange={(e) => setPin(e.target?.value)}
                  type="text"
                  defaultValue={formData?.pin}
                  placeholder="Enter Pin"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 border-primary/20 p-2"
                />
              </div>
              {userInfo?.role === "admin" ? (
                <div className="col-span-3">
                  <label htmlFor="discount" className="text-sm">
                    Discount from Transaction Charge
                  </label>
                  <input
                    name="discount"
                    onChange={handleChange}
                    type="text"
                    defaultValue={formData?.discount}
                    placeholder="Enter Discount"
                    className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 border-primary/20 p-2"
                  />
                </div>
              ) : (
                <div className="col-span-3">
                  <div className="w-full">
                    <label htmlFor="agentCommission" className="text-sm">
                      Bkash Commission (1000 BDT)
                    </label>
                    <input
                      name="agentCommission"
                      onChange={handleChange}
                      type="text"
                      defaultValue={formData?.agentCommission}
                      placeholder="Enter Commission"
                      className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 border-primary/20 p-2"
                    />
                  </div>
                  <div className="w-full">
                    <label htmlFor="agentRiccaricaCommission" className="text-sm">
                      Riccarica Commission (100 EURO)
                    </label>
                    <input
                      name="agentRiccaricaCommission"
                      onChange={handleChange}
                      type="text"
                      defaultValue={formData?.agentRiccaricaCommission}
                      placeholder="Enter Commission"
                      className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 border-primary/20 p-2"
                    />
                  </div>
                  <div className="w-full">
                    <label htmlFor="agentCargoCommission" className="text-sm">
                      Cargo Commission (100 EURO)
                    </label>
                    <input
                      name="agentCargoCommission"
                      onChange={handleChange}
                      type="text"
                      defaultValue={formData?.agentCargoCommission}
                      placeholder="Enter Commission"
                      className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 border-2 border-primary/20 p-2"
                    />
                  </div>
                </div>
              )}
            </div>
          </fieldset>

          <fieldset className="grid grid-cols-4 gap-6 p-6 rounded-md shadow-sm dark:bg-gray-900">
            <div className="space-y-2 col-span-full lg:col-span-1">
              <p className="font-medium">Profile</p>
              <p className="text-xs">Provide the user's profile information!</p>
            </div>
            <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="userName" className="text-sm">
                  Username
                </label>
                <input
                  id="userName"
                  type="text"
                  name="userName"
                  onChange={handleChange}
                  value={formData?.userName}
                  required
                  disabled
                  placeholder="Username"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 p-2 border-2 cursor-not-allowed"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="password" className="text-sm">
                  Password
                </label>
                <input
                  type="text"
                  name="password"
                  onChange={handleChange}
                  placeholder="Enter new password if you want to change"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 p-2 border-2"
                />
              </div>
              <div className="col-span-full">
                <label htmlFor="additionalInformation" className="text-sm">
                  Additional Information
                </label>
                <textarea
                  name="additionalInformation"
                  onChange={handleChange}
                  defaultValue={formData?.additionalInformation}
                  placeholder="Enter any additional information"
                  className="w-full rounded-md focus:ring dark:border-gray-700 dark:text-gray-900 p-3 border-2"
                ></textarea>
              </div>
            </div>
          </fieldset>
          <div className="flex justify-end items-center">
            <button
              type="submit"
              className="px-10 py-3 text-white bg-primary hover:bg-primary max-w-lg rounded-md"
            >
              Update!
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default CommonUpdateUser;
