import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../Shared/Pagination";
import AuthUser from "../../../Hooks/authUser";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MobileBankingPendingOrder = () => {
  const { userInfo } = AuthUser();
  const [orders, setOrders] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/mobileBankingOrder/specific?fieldName=${"ordererId"}&&fieldValue=${
        userInfo?._id
      }&&fieldName2=${"status"}&&fieldValue2=${"pending"}&&size=${size}&&page=${page}`
    ).then((res) =>
      res.json().then((data) => {
        setQuantity(data?.total);
        setOrders(data?.data);
        setLoading(false);
      })
    );
  }, [size, page, refetch, userInfo?._id]);

  const reversedOrders = orders.slice().reverse();

  return (
    <div>
      <nav aria-label="Breadcrumb" className="flex">
        <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
          <li className="flex items-center">
            <a
              href="overview"
              className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>

              <span className="ms-1.5 text-xs font-medium"> Home </span>
            </a>
          </li>

          <li className="relative flex items-center">
            <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>

            <a
              href="#"
              className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900"
            >
              Pending Orders
            </a>
          </li>
        </ol>
      </nav>

      <div className="mt-5 bg-[#FFF0F6] rounded-md   p-5">
        <div className="flex justify-between mb-3">
          <h1 className="text-2xl">Pending Orders</h1>
          <Link
            to="/mobileBanking/newRequest"
            className="bg-bkashprimary p-2 text-white text-xs rounded hover:bg-bkashprimary/90"
          >
            New Request
          </Link>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-xs">
            <thead className="text-left bg-bkashprimary text-white">
              <tr>
                <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Tracking-ID
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Date
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Service
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">Type</th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Customer
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Recipient
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Entry By
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Amount
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Commission
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">Total</th>
              </tr>
            </thead>

            <tbody className="divide-y text-left divide-gray-200">
              {loading
                ? Array.from({ length: size }).map((_, i) => (
                    <tr key={i} className="odd:bg-gray-50">
                      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        <Skeleton />
                      </td>
                    </tr>
                  ))
                : reversedOrders.map((order, i) => (
                    <tr key={order?._id} className="odd:bg-gray-50">
                      <td
                        key={order?._id}
                        order={order}
                        className="whitespace-nowrap px-4 border py-2 font-medium text-gray-900"
                      >
                        {i + 1}
                      </td>
                      <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                        {order?.trackingId}
                      </td>
                      <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                        {order?.date}
                      </td>
                      <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                        {order?.paymentMethod}
                      </td>
                      <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                        {order?.serviceType}
                      </td>
                      <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                        {order?.customerName}
                      </td>
                      <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                        {order?.recipientNumber}
                      </td>
                      <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                        {order?.ordererUserName}
                      </td>
                      <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                        ৳ {order?.amount}
                      </td>
                      <td className="whitespace-nowrap px-4 border py-2 text-center text-gray-700">
                        ৳ {order?.commission.toFixed(2)}
                      </td>
                      <td className="whitespace-nowrap px-4 border py-2 text-center text-gray-700">
                        ৳ {order?.totalAmount}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

export default MobileBankingPendingOrder;
