import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function Robi() {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [packageType, setPackageType] = useState('Talk Time');
  const [currentPage, setCurrentPage] = useState(1);
  const packagesPerPage = 5;

  useEffect(() => {
    const fetchPackages = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://walletserver.genzamwallet.com/api/v1/packages/filtered-packages?operator=Robi Axiate Limited (Robi)&packageType=${packageType}&page=${currentPage}&size=${packagesPerPage}`
        );
        const data = await response.json();
        if (data.status === 'success') {
          setPackages(data.data || []);
        } else {
          console.error('Error fetching packages:', data.message);
        }
      } catch (error) {
        console.error('Error fetching packages:', error);
      }
      setLoading(false);
    };

    fetchPackages();
  }, [packageType, currentPage]);

  return (
    <div className="p-8 bg-gradient-to-r from-red-50 to-[#CC0D01] shadow-lg rounded-2xl">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
        Robi Packages
      </h2>
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 mb-6">
        {['Talk Time', 'Combo', 'Internet', 'Recharge'].map((type) => (
          <button
            key={type}
            onClick={() => {
              setPackageType(type);
              setCurrentPage(1); // Reset to first page when package type changes
            }}
            className={`relative px-4 py-2 font-semibold rounded-xl shadow-lg transform transition-transform ${
              packageType === type
                ? 'bg-[#CC0D01] text-white'
                : 'bg-red-500 text-white hover:scale-105 hover:bg-[#CC0D01]'
            }`}
          >
            {type}
            <span className="absolute inset-0 rounded-xl opacity-0 transition-opacity hover:opacity-10"></span>
          </button>
        ))}
      </div>
      {loading ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-[#CC0D01] text-white">
              <tr>
                <th className="py-2 px-4">#</th>
                <th className="py-2 px-4">Package Type</th>
                <th className="py-2 px-4">Package Details</th>
                <th className="py-2 px-4">Price Euro</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: packagesPerPage }).map((_, index) => (
                <tr key={index} className="border-t">
                  <td className="py-2 px-4"><Skeleton /></td>
                  <td className="py-2 px-4"><Skeleton /></td>
                  <td className="py-2 px-4"><Skeleton /></td>
                  <td className="py-2 px-4"><Skeleton /></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-[#CC0D01] text-white">
              <tr>
                <th className="py-2 px-4">#</th>
                <th className="py-2 px-4">Package Type</th>
                <th className="py-2 px-4">Package Details</th>
                <th className="py-2 px-4">Price Euro</th>
              </tr>
            </thead>
            <tbody>
              {packages.map((pkg, index) => (
                <tr key={pkg._id} className="border-t">
                  <td className="py-2 px-4">{index + 1 + (currentPage - 1) * packagesPerPage}</td>
                  <td className="py-2 px-4">{pkg.packageType}</td>
                  <td className="py-2 px-4">{pkg.packageDetails}</td>
                  <td className="py-2 px-4">€ {pkg.priceEuro.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex justify-center mt-6">
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={packagesPerPage}
          totalItemsCount={packages.length * packagesPerPage}
          pageRangeDisplayed={5}
          onChange={(pageNumber) => setCurrentPage(pageNumber)}
          innerClass="flex list-none"
          itemClass="mx-1"
          linkClass="px-3 py-1 rounded-lg bg-gray-200 text-gray-800"
          activeLinkClass="bg-[#CC0D01] text-white"
          disabledClass="cursor-not-allowed opacity-50"
        />
      </div>
    </div>
  );
}
