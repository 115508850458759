import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Make sure to import BrowserRouter as Router
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS
import LandingPage from "./Component/LandingPage/LandingPage";
import PublicRoutes from "./Routes/PublicRoutes";
import HomeIndex from "./Pages/Home/HomeIndex";
import AgentIndex from "./Pages/Agent/AgentIndex";
import AgentDashBord from "./Component/Agent/AgentDashBord/AgentDashBord";
import AgentRoutes from "./Routes/AgentRoutes";
import MobileBanking from "./Component/MobileBanking/MobileBanking";
import MobileBakingIndex from "./Component/MobileBanking/MobileBakingIndex";
import mobileBankingRoutes from "./Routes/MobileBankingRoutes";
import Riccarica from "./Component/Riccarica/Riccarica";
import RiccaricaIndex from "./Component/Riccarica/RiccaricaIndex";
import RiccaricaRoutes from "./Routes/RiccaricaRoutes";
import AdminRoute from "./Routes/AdminRoutes";
import AdminDashboard from "./Component/AdminDashboard/AdminDashboard";
import AdminDashboardIndex from "./Component/AdminDashboard/AdminDashboardIndex";
import Cargoindex from "./Component/Cargo/Cargoindex";
import CargoHome from "./Component/Cargo/CargoHome";
import CargoRoutes from "./Routes/CargoRoutes";
import Bot from "./Component/Bot/Bot";

function App() {
  return (
    <div className="App">
        <Bot></Bot>
      <Router>
        <Routes>
          {/* Wrap your application with Router
        //Public route */}

      
          <Route path="/" element={<HomeIndex />}>
            <Route index element={<LandingPage />} />
            {PublicRoutes.map(({ path, Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}
          </Route>

          {/* agent route */}
          <Route path="/agentDashboard" element={<AgentIndex></AgentIndex>}>
            <Route index element={<AgentDashBord></AgentDashBord>} />
            {AgentRoutes.map(({ path, Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}
          </Route>

          {/* User route */}
          <Route path="/userDashboard" element={<AgentIndex></AgentIndex>}>
            <Route index element={<AgentDashBord></AgentDashBord>} />
            {AgentRoutes.map(({ path, Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}
          </Route>

          {/* mobile banking route */}
          <Route path="/mobileBanking" element={<MobileBanking />}>
            <Route index element={<MobileBakingIndex />} />
            {mobileBankingRoutes.map(({ path, Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}
          </Route>

          {/* mobile banking route */}
          <Route path="/riccarica" element={<Riccarica />}>
            <Route index element={<RiccaricaIndex />} />
            {RiccaricaRoutes.map(({ path, Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}
          </Route>
          {/* //cargo Routes */}

          <Route path="/cargo" element={<Cargoindex/>}>
            <Route index element={<CargoHome/>} />
            {CargoRoutes.map(({ path, Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}
          </Route>


          {/* //admin */}
          <Route path="/AdminDashboard" element={<AdminDashboard />}>
            <Route index element={<AdminDashboardIndex />}></Route>
            {AdminRoute.map(({ path, Component }, index) => (
              <Route key={index} path={path} element={<Component />} />
            ))}
          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
