import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import AuthUser from "../../../Hooks/authUser";
import DeleteHook from "../../../Hooks/DeleteHook";
import Pagination from "../../../Shared/Pagination";

const AdminPaymentReport = ({ setShow }) => {
  const { userInfo } = AuthUser(); 
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [payment, setPayment] = useState([]);
  const [filterPayment, setFilterPayment] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(50);

  const data = ["Bkash", "Riccarica", "Cargo"];
  const data2 = [ "complete", "pending", "return"];

  const [formData, setFormData] = useState({
    userName: "",
    serviceName: "",
    status: "",
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/myPayment/specific?fieldName=${"agentId"}&&fieldValue=${
        userInfo?._id
      }&&size=${size}&&page=${page}`
    ).then((res) =>
      res.json().then((data) => {
        setPayment(data?.data);
        setFilterPayment(data?.data);
        setQuantity(data?.total);
        setLoading(false);
      })
    );
  }, [page, size, userInfo?._id, refetch]);

  const handleSearch = (e) => {
    e.preventDefault();
    const results =  payment?.filter((data) => {

      return (
        (formData?.userName?(data?.userName === formData?.userName):true) &&
        (formData?.serviceName?(data?.serviceName === formData?.serviceName):true) &&
        (formData?.status?(data?.status === formData?.status):true) &&
        
      ((formData?.fromDate&&formData?.toDate)?(moment(data?.date).isBetween(formData?.fromDate,formData?.toDate)):true )
      );
    });
    setFilterPayment(results);
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  return (
    <div className="my-10">
      <div>
        <form onSubmit={handleSearch}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div className="flex">
              <label className=" py-2 pl-4 w-56 rounded bg-gray-200">
                UserName:
              </label>
              <input
                name="userName"
                onChange={handleChange}
                type="text"
                className="border w-full px-3 outline-none rounded focus:border-white"
              ></input>
            </div>

            <div className="flex">
              <label className=" py-2 pl-4 w-56 rounded bg-gray-200">
                Service Name:
              </label>

              <select
                name="serviceName"
                onChange={handleChange}
                className="border border-stone-400 w-full px-3 outline-none  "
              >
                <option value=""  selected>
                  Select
                </option>

                {data?.map((cat, index) => (
                  <option key={index} cat={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-5 md:gap-2 my-5 md:my-10">
            <div className="grid grid-cols-1  md:grid-cols-3 gap-5">
              <div className="flex">
                <label className=" py-2 px-4 w-52 rounded bg-gray-200">
                  From Date:
                </label>
                <input
                  name="fromDate"
                  onChange={handleChange}
                  type="date"
                  className="border px-3 w-full outline-none rounded focus:border-white"
                ></input>
              </div>

              <div className="flex">
                <label className=" py-2 px-4 w-52 rounded bg-gray-200">
                  To Date:
                </label>
                <input
                  name="toDate"
                  onChange={handleChange}
                  type="date"
                  className="border px-3 w-full  outline-none rounded focus:border-white"
                ></input>
              </div>

              <div className="flex">
                <label className=" py-2 px-4 w-52  rounded bg-gray-200">
                  Payment Status:
                </label>

                <select
                  name="status"
                  onChange={handleChange}
                  className="border w-full border-stone-400 px-3 outline-none  "
                >
                  <option value=""  selected>
                    Select Any
                  </option>

                  {data2?.map((cat, index) => (
                    <option key={index} cat={cat} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-3 rounded"
            >
              Search
            </button>
          </div>
        </form>

        <div className="overflow-x-auto rounded-lg border border-gray-200">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
            <thead className="ltr:text-left bg-gray-200  rtl:text-right">
              <tr>
                <th className="whitespace-nowrap text-start px-4 py-3 font-semibold text-gray-900">
                  Payment Date
                </th>

                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold text-gray-900">
                  Entry
                </th>
                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold text-gray-900">
                  Recipient User Name
                </th>
            
                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold text-gray-900">
                  Service Name
                </th>
                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold text-gray-900">
                  Status
                </th>
                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold text-gray-900">
                  Amount
                </th>

                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold text-gray-900">
                  Notes
                </th>
                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold text-gray-900">
                  Edit/Delete
                </th>
              </tr>
            </thead>

            <tbody className="divide-y  divide-gray-200">
              {[...filterPayment]?.reverse()?.map((data) => (
                <tr key={data?._id}>
                  <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                    <p>{data?.date}</p>
                  </td>

                  <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                    {userInfo?.name}
                  </td>
                  <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                    {data?.recipientUserName}
                  </td>
                  <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                    {data?.serviceName}
                  </td>
                  <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                    <button className="px-3  py-[5px] text-center rounded bg-green-600 text-white font-semibold">
                      {data?.status}
                    </button>
                  </td>
                  <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                    {data?.amount}
                  </td>

                  <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                    {data?.note}
                  </td>
                  <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                    <Link to={`/adminDashboard/admin-edit-payment/${data?._id}`}
                     
                      className="px-3 mr-3  py-[5px] text-center rounded bg-blue-600 text-white font-semibold"
                    >
                      Edit
                    </Link>
                    <button
                      onClick={() => {
                        DeleteHook({
                          refetch,
                          setRefetch,
                          url: `https://walletserver.genzamwallet.com/api/v1/myPayment/deleteMyPayment/${data?._id}`,
                        });
                      }}
                      className="px-3 mr-3  py-[5px] text-center rounded bg-red-600 text-white font-semibold"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

export default AdminPaymentReport;
