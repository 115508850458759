import React from "react";

const PackageCard = ({packageItem,setData,isOpen,
  setIsOpen,}) => {

  return (
    <div onClick={()=>{
      setData(packageItem)
      setIsOpen(true)
    }} className="h-auto rounded-lg bg-[#008C44] text-white font-semibold p-2 text-center cursor-pointer hover:bg-[#008C44]/90">
    <div>
      <h1>Price: BDT {packageItem.priceBDT.toFixed(2)}</h1>
      <h1>Commission: € {packageItem.commissionEuro?.toFixed(2)}</h1>
    </div>
    <div className="border-t border-gray-300 my-4"></div>
    <div className="text-xs">
      <p>{packageItem.packageDetails}</p>
    </div>
    <div className="border-t border-gray-300 my-4">
     Price: € {packageItem.priceEuro?.toFixed(2)}
    </div>
  </div>
  );
};

export default PackageCard;
