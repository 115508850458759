import React from 'react';
import UpdateHooks from '../../../Hooks/UpdateHooks';
import { server_url } from '../../../Config/API';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Loading from '../../../Shared/Loading';

const AdminUpdateCargoDetails = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        name: "",
        charge: 0,
        agentCommission: 0,
        deliveryCharge: 0,
        chargePerKg: 0
    })

    useEffect(() => {
        setLoading(true)
        fetch(`${server_url}/cargoDetails/getCargoDetailsById/${id}`).then(res => res.json().then(data => {
            setFormData(data?.data)
            setLoading(false)
        }))
    }, [id])

    const handelUpdate = async (e) => {
        e.preventDefault()
        UpdateHooks(
            `${server_url}/cargoDetails/updateCargoDetails/${id}`,
            formData,
            "Cargo Details Updated !"
        );
    }


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    if (loading) {
        return <Loading />
    }
    return (
        <div>
            <div className=" rounded-lg">
                <div className="mt-3  text-2xl font-bold">
                    Post Cargo Details
                </div>

                <form onSubmit={handelUpdate}>
                    <div className="mt-5 shadow-md rounded-lg px-3 py-5">
                        <div className="grid md:grid-cols-2 grid-cols-1 w-full mb-5 gap-4">
                            <div className="w-full">
                                <label>Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    required
                                    onChange={handleChange}
                                    defaultValue={formData?.name}
                                    placeholder="Ex: Document"
                                    className="mt-1 w-full block rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                                />
                            </div>
                            <div className="w-full">
                                <label>Charge</label>
                                <input
                                    type="number"
                                    name="charge"
                                    defaultValue={formData?.charge}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                                    placeholder="Enter Charge"
                                />
                            </div>
                            <div className="w-full">
                                <label>Agent Commission (per kg/quantity)</label>
                                <input
                                    type="number"
                                    name="agentCommission"
                                    defaultValue={formData?.agentCommission}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                                    placeholder="Enter agent commission"
                                />
                            </div>
                            <div className="w-full">
                                <label>Delivery Charge</label>
                                <input
                                    type="number"
                                    name="deliveryCharge"
                                    defaultValue={formData?.deliveryCharge}
                                    onChange={handleChange}
                                    className="mt-1 block w-full rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                                    placeholder="Enter delivery charge"
                                />
                            </div>
                            {
                                formData?.name?.toLowerCase()?.includes("document") || <div className="w-full">
                                    <label>Charge per kg</label>
                                    <input
                                        type="number"
                                        name="chargePerKg"
                                        defaultValue={formData?.chargePerKg}
                                        onChange={handleChange}
                                        className="mt-1 block w-full rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                                        placeholder="Enter charge Per Kg"
                                    />
                                </div>
                            }
                        </div>
                        <button
                            type="submit"
                            className="cursor-pointer rounded-lg bg-primary px-4 py-3 font-semibold text-white hover:scale-105 duration-300"
                        >
                            Update ExchangeRate
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AdminUpdateCargoDetails;