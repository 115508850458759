import { Icon } from "@iconify/react";
import React from "react";
import TodayTransactions from "../Transactions/TodayTransactions";
import { Link } from "react-router-dom";

const MobileBankingHero = () => {
  return (
    <div>
      <nav aria-label="Breadcrumb" className="flex">
        <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
          <li className="flex items-center">
            <a
              href="#"
              className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>

              <span className="ms-1.5 text-xs font-medium"> Home </span>
            </a>
          </li>
        </ol>
      </nav>

      <div className="flex flex-col justify-center items-center">
        <Link to="/mobileBanking/newRequest">
          <h1 className="bg-bkashprimary  lg:px-36  text-white text-xl p-5 mx-auto rounded-md flex items-center gap-2">
            New Request
            <span>
              <Icon className="text-2xl" icon="gridicons:add-outline" />
            </span>
          </h1>
        </Link>

        <div className=" flex items-center mt-5 ">
          <div className="h-auto rounded-lg bg-white-100 shadow-xl mx-auto max-w-md p-5 text-justify">
            <p>
              আপনার একাউন্টে নিরাপত্তার দায়িত্ব আপনার নিজের, দয়া করে যেকোনো
              ধরনের অনাকাঙ্ক্ষিত সমস্যার সম্মুখীন হলে আমাদের সাথে যোগাযোগ করুন!
              আমরা খুব দ্রুত আপনার সমস্যার সমাধান করব। নিজের একাউন্টের
              পাসওয়ার্ড নিয়মিত পরিবর্তন করবেন এবং অন্য কারো সাথে নিজের
              পাসওয়ার্ড শেয়ার করবেন না। আপনার জন্য শুভকামনা!!
            </p>
          </div>
        </div>
      </div>
      {/* <TodayTransactions></TodayTransactions> */}
    </div>
  );
};

export default MobileBankingHero;
