import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import UpdateHooks from "../../../Hooks/UpdateHooks";
import { server_url } from "../../../Config/API";
import { toast } from "react-toastify";
import { AreaChart, Euro, PencilLine } from "lucide-react";
import { Icon } from "@iconify/react/dist/iconify.js";

const AdminRiccaricaOrderView = () => {
  const [status, setStatus] = useState("");
  const [orderer, setOrderer] = useState({});
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [exchangeRate, setExchangeRate] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${server_url}/riccaricaOrder/getRiccaricaOrderById/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setOrder(data?.data);
      });
  }, [id]);

  useEffect(() => {
    if (order?.ordererId) {
      fetch(`${server_url}/user/${order.ordererId}`)
        .then((res) => res.json())
        .then((data) => setOrderer(data?.data));
    }
  }, [order]);

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/exchangeRate/getExchangeRate`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data) {
          setExchangeRate(data?.data[0]);
        }
      });
  }, []);

  const handleSubmit = async () => {
    if (status) {
      if (status === "rejected") {
        // Update order status
        fetch(
          `${server_url}/riccaricaOrder/updateRiccaricaOrder/${order?._id}`,
          {
            method: "PATCH",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ status: status }),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              // Update user's riccarica balance
              fetch(`${server_url}/user/${order?.ordererId}`, {
                method: "PATCH",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  riccaricaBalance:
                    orderer?.riccaricaBalance + order?.totalAmount,
                }),
              }).then((res) => res.json());
            }
          });

        // Reset status and provide feedback
        setStatus("");
        toast.success("Order Status Updated");
        navigate(-1);
      } else {
        // Update order status for other cases
        await UpdateHooks(
          `${server_url}/riccaricaOrder/updateRiccaricaOrder/${order?._id}`,
          { status }
        );

        // Reset status and provide feedback
        setStatus("");
        toast.success("Order Status Updated");
        navigate(-1);
      }
    } else {
      // Handle case where status is not selected
      toast.error("Please select a status");
    }
  };

  return (
    <div className="p-4">
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-2xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          View Riccarica Orders (tracking-Id{" "}
          <span
            className="text-blue-500 cursor-pointer hover:underline"
            onClick={() => {
              navigator.clipboard
                .writeText(order?.trackingId)
                .then(() => toast.success("Copied to clipboard"));
            }}
          >
            {order?.trackingId}
          </span>
          )
        </h1>
      </div>

      <div className="space-y-1">
        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Orderer Reference UserName
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.referralUserName ? order?.referralUserName : "N/A"}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Orderer UserName
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.ordererUserName || <Skeleton />}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Orderer Phone
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.ordererPhone || <Skeleton />}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Recipient Phone Number
          </label>
          <h1
            className="border text-blue-500 hover:underline cursor-pointer font-bold p-2 rounded"
            onClick={() => {
              navigator.clipboard
                .writeText(order?.receiverMobileNumber)
                .then(() => toast.success("Copied to clipboard"));
            }}
          >
            {order?.receiverMobileNumber || <Skeleton />}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Package Type
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.packageType || <Skeleton />}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Package Details
          </label>
          <h1 className="border text-black font-bold p-2 rounded">
            {order?.package?.packageDetails
              ? order?.package?.packageDetails
              : "N/A"}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Amount Euro
          </label>
          <h1 className="border text-black flex items-center gap-1 font-bold p-2 rounded">
            <Euro /> {order?.amountEuro.toFixed(2) || <Skeleton />}
          </h1>
        </div>

        {order?.packageType === "Recharge" && (
          <div>
            <label htmlFor="" className="text-sm text-gray-600">
              Recharge BDT Amount
            </label>
            <h1 className="border text-black font-bold p-2 rounded">
              {(order?.amountEuro * exchangeRate?.bdtExchangeRate).toFixed(
                2
              ) || <Skeleton />}{" "}
              BDT
            </h1>
          </div>
        )}

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Service Charge Euro
          </label>
          <h1 className="border flex items-center gap-1 text-black font-bold p-2 rounded">
            <Euro /> {order?.serviceChargeEuro || <Skeleton />}
          </h1>
        </div>
        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Service Commission Euro
          </label>
          <h1 className="border flex items-center gap-1 text-black font-bold p-2 rounded">
            <Euro /> {order?.commissionEuro ? order?.commissionEuro : "N/A"}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Total Amount Euro
          </label>
          <h1 className="border flex items-center gap-1 text-black font-bold p-2 rounded">
          <Euro /> {order?.totalAmountEuro.toFixed(2) || <Skeleton />}
          </h1>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Note
          </label>
          <p className="border text-black font-bold p-2 rounded">
            {order?.note ? order?.note : "N/A"}
          </p>
        </div>

        <div>
          <label htmlFor="" className="text-sm text-gray-600">
            Status
          </label>
          <p
            className={`border ${
              order?.status === "pending"
                ? "text-yellow-500"
                : order?.status === "confirmed"
                ? "text-green-500"
                : "text-red-500"
            } font-bold p-2 rounded`}
          >
            {order?.status || <Skeleton />}
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between items-center mb-5 p-5 mt-6 bg-white shadow rounded-md">
        <div className="flex flex-col md:flex-row items-center mb-4 md:mb-0">
          <label
            htmlFor="status"
            className="text-sm flex items-center gap-1 px-6 py-2 bg-[#0A1849] text-white font-medium mr-3 mb-2 md:mb-0"
          >
            <AreaChart />
            Status
          </label>
          <select
            id="status"
            onChange={(e) => setStatus(e.target.value)}
            className="cursor-pointer px-6 py-3 border border-gray-300 text-sm text-gray-700 focus:ring-primary focus:border-primary"
            value={status}
          >
            <option disabled value="">
              Select Status
            </option>
            <option value="pending">Pending</option>
            <option value="confirmed">Confirmed</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
        <button
          onClick={handleSubmit}
          className="flex items-center justify-center px-6 py-2 bg-[#0A1849] text-white text-sm font-medium rounded-md hover:bg-primary-dark transition duration-300 ease-in-out"
        >
          <PencilLine /> Update!
        </button>
      </div>
    </div>
  );
};

export default AdminRiccaricaOrderView;
