import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

const RiccaricaIndex = () => {
  return (
    <div>
      <nav aria-label="Breadcrumb" className="flex mb-5">
        <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
          <li className="flex items-center">
            <a
              href="#"
              className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>

              <span className="ms-1.5 text-xs font-medium"> Home </span>
            </a>
          </li>

          <li className="relative flex items-center">
            <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>

            <a
              href="#"
              className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900"
            >
              Dashboard
            </a>
          </li>
        </ol>
      </nav>

      <div className="flex flex-col justify-center items-center">
        <Link to="/riccarica/agent-bd-recharge">
          {" "}
          <h1 className=" bg-[#008C44]  lg:px-36  text-white text-xl p-5 mx-auto rounded-md flex items-center gap-2">
            New Request
            <span>
              <Icon className="text-2xl" icon="gridicons:add-outline" />
            </span>
          </h1>
        </Link>

        <div className=" flex items-center mt-5 ">
          <div className="h-auto rounded-lg bg-white-100 shadow-xl mx-auto max-w-md p-5 text-justify">
            <p>
            আপনি বাংলাদেশের যে কোন অপারেটরে রিচার্জ করতে পারবেন আমাদের বর্তমান
            সেবা চালু রয়েছে গ্রামীণফোন বাংলালিংক রবি এয়ারটেল এবং টেলিটক এ
            সেবাগুলো আপনি আমাদের সফটওয়্যার এর মাধ্যমে পাবেন দয়া করে অপ্রীতিকর
            ঘটনা থেকে আপনার একাউন্ট কে সব সময় নিরাপদে রাখুন যেকোনো পরিস্থিতিতে
            আমাদের সাথে যোগাযোগ করুন নিয়মিত আপনার একাউন্টের পাসওয়ার্ড পরিবর্তন
            করুন।
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiccaricaIndex;
