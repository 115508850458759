import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import DeleteHook from "../../../Hooks/DeleteHook";
import { toast } from "react-toastify";

const AgentAllUserTable = ({
  u,
  index,
  setIsOpen,
  refetch,
  setRefetch,
  setData,
}) => {
  return (
    <>
      <tr className="shadow">
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {index + 1}
        </td>
        <td
          className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-blue-500 hover:underline cursor-pointer"
          onClick={() => {
            navigator.clipboard
              .writeText(u?.userName)
              .then(() => toast.success("Copied to clipboard"));
          }}
        >
          {u?.userName}
        </td>

        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {u?.email}
        </td>

        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          ৳ {u?.mobileBalance.toFixed(2)}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          € {u?.riccaricaBalance.toFixed(2)}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          € {u?.cargoBalance.toFixed(2)}
        </td>
        <td className="h-16 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
          {u?.status}
        </td>
        <td className="h-16 px-6 transition duration-300 border-slate-200 text-secondary text-lg flex gap-2 items-center cursor-pointer">
          <Link
            to={`/agentDashboard/updateUser/${u?._id}`}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="basil:edit-outline" />
          </Link>
          <button
            onClick={() => {
              setData(u);
              setIsOpen(true);
            }}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="streamline:money-cash-bag-dollar-bag-payment-cash-money-finance" />
          </button>
          <button
            aria-label="Open delete confirmation"
            onClick={() => {
              DeleteHook({
                refetch,
                setRefetch,
                url: `https://walletserver.genzamwallet.com/api/v1/user/${u?._id}`,
              });
            }}
            className="border border-secondary py-2 px-3 rounded-md hover:bg-secondary/10 duration-300"
          >
            <Icon icon="material-symbols:delete-outline" />
          </button>
        </td>
      </tr>
    </>
  );
};

export default AgentAllUserTable;
