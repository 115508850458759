import AgentAddUser from "../Component/Agent/AgentUser/AgentAddUser";
import AgentAllUser from "../Component/Agent/AgentUser/AgentAllUser";
import EditMypayment from "../Component/MyPayments/EditMypayment";
import MyPayments from "../Component/MyPayments/MyPayments";
import Payment from "../Component/Payment/Payment";
import PaymentReportEdit from "../Component/Payment/PaymentReportEdit";
import ChangePassword from "../Shared/ChangePassword";
import CommonUpdateUser from "../Shared/CommonUpdateUser";

const AgentRoutes = [
  { path: "add-user", Component: AgentAddUser },
  { path: "all-user", Component: AgentAllUser },
  { path: "updateUser/:id", Component: CommonUpdateUser },
  { path: "changePassword", Component: ChangePassword },
  { path: "myPayments", Component: MyPayments },
  { path: "payment", Component: Payment },
  { path: "editPayment/:id", Component: PaymentReportEdit },
  { path: "myPayments/edit-order/:id", Component: EditMypayment },



];

export default AgentRoutes;
