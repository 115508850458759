import React, { useEffect, useState, useCallback } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AuthUser from "../../Hooks/authUser";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const UserList = () => {
  const { userInfo } = AuthUser();
  const [filterUser, setFilterUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState([]);
  const [page] = useState(0);
  const [size] = useState(50);

  useEffect(() => {
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/user/specific?fieldName=${"referralId"}&&fieldValue=${
        userInfo?._id
      }&&size=${size}&&page=${page}`
    ).then((res) =>
      res.json().then((data) => {
        setUser(data?.data);
      })
    );
  }, [page, size, userInfo?._id]);

  const handleSearch = useCallback(() => {
    const results = user?.filter((data) => {
      return (
        data.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.phone === searchTerm
      );
    });
    setFilterUser(results);
  }, [user, searchTerm]);

  useEffect(() => {
    handleSearch();
  }, [searchTerm, user, handleSearch]);

  const renderTableRows = () => {
    return [...filterUser]?.reverse()?.map((data) => (
      <tr key={data?._id}>
        <td className="whitespace-nowrap px-4 py-4 text-gray-700">
          <p>{data?.name}</p>
          <p className="my-1">{data?.userName}</p>
          <p>{data?.email}</p>
        </td>
        <td className="whitespace-nowrap px-4 py-4 text-gray-700">
          {data?.cargoBalance}
        </td>
        <td className="whitespace-nowrap px-4 py-4 text-gray-700">
          {data?.mobileBalance}
        </td>
        <td className="whitespace-nowrap px-4 py-4 text-gray-700">
          {data?.riccaricaBalance}
        </td>
        <td className="whitespace-nowrap px-4 py-4 text-gray-700">
          {data?.role}
        </td>
        <td className="whitespace-nowrap px-4 py-4 text-gray-700">
          <button className="px-3 py-1 text-center rounded bg-green-600 text-white font-semibold">
            {data?.status}
          </button>
        </td>
        <td className="whitespace-nowrap px-4 py-4 text-gray-700">
          <Link
            to={`/agentDashboard/updateUser/${data?._id}`}
            className="px-3 py-1 text-center rounded bg-blue-600 text-white font-semibold"
          >
            Edit
          </Link>
          {/* Uncomment the following line to enable the delete button */}
          {/* <button className="px-3 py-1 text-center rounded bg-rose-600 text-white font-semibold">
            Delete
          </button> */}
        </td>
      </tr>
    ));
  };

  return (
    <div className="my-10">
      <div className="flex relative rounded-md w-full my-5">
        <input
          type="text"
          placeholder="Enter user Name, Email, or Phone"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 rounded-md border border-r-0 rounded-r-none border-gray-300 focus:outline-none"
        />
        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-primary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-primary-hover"
        >
          <span>Search</span>
          <span className="hidden md:block">
            <Icon icon="material-symbols:search" />
          </span>
        </button>
      </div>

      {/* Table */}
      <div className="overflow-x-auto rounded-lg border border-gray-200">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white  text-sm">
          <thead className="bg-primary text-white">
            <tr>
              <th className="whitespace-nowrap text-start px-4 py-3 font-semibold ">
                User
              </th>
              <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                Cargo Balance
              </th>
              <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                Bkash Balance
              </th>
              <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                Riccarica Balance
              </th>
              <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                Type
              </th>
              <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                Status
              </th>
              <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {user.length === 0
              ? Array(10)
                  .fill()
                  .map((_, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        <Skeleton width={200} />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        <Skeleton width={100} />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        <Skeleton width={100} />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        <Skeleton width={100} />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        <Skeleton width={50} />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        <Skeleton width={100} />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        <Skeleton width={100} />
                      </td>
                    </tr>
                  ))
              : renderTableRows()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserList;
