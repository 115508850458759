import MobileBakingIndex from "../Component/MobileBanking/MobileBakingIndex";
import MobileBankingOrderHistory from "../Component/MobileBanking/MobileBankingPenddingorders/MobileBankingOrderHistory";
import MobileBankingPaymentReport from "../Component/MobileBanking/MobileBankingPenddingorders/MobileBankingPaymentReport";
import MobileBankingPaymnetList from "../Component/MobileBanking/MobileBankingPenddingorders/MobileBankingPaymnetList";
import MobileBankingPenddingOrder from "../Component/MobileBanking/MobileBankingPenddingorders/MobileBankingPenddingOrder";
import MobileBankingSalesReport from "../Component/MobileBanking/MobileBankingPenddingorders/MobileBankingSalesReport";
import MobileBankingTransactionsReport from "../Component/MobileBanking/MobileBankingPenddingorders/MobileBankingTransactionsReport";
import MobileBankingUserSalesReports from "../Component/MobileBanking/MobileBankingPenddingorders/MobileBankingUserSalesReports";
import MobileBankingUserOrderHistory from "../Component/MobileBanking/MobileBankingPenddingorders/MobileBankingUserOrderHistory";
import MobileNewRequest from "../Component/MobileBanking/MobileNewRequest/MobileNewRequest";
import ChangePassword from "../Shared/ChangePassword";
import UserSalesList from "../Component/MobileBanking/MobileBankingReports/UserSalesList";
import UserSalesReport from "../Component/MobileBanking/MobileBankingReports/UserSalesReport";

const mobileBankingRoutes = [
  { path: "overview", Component: MobileBakingIndex },
  { path: "changePassword", Component: ChangePassword },
  { path: "newRequest", Component: MobileNewRequest },
  { path: "peding-Orders", Component: MobileBankingPenddingOrder },
  { path: "order-history", Component: MobileBankingOrderHistory },
  { path: "user-order-history", Component: MobileBankingUserOrderHistory },
  {
    path:"payment-list",Component:MobileBankingPaymnetList
  },
  {
    path:"payment-Report",Component:MobileBankingPaymentReport

  },
  {
    path:"sales-report",Component:MobileBankingSalesReport

  },
  {
    path:"user-sales-report",Component:MobileBankingUserSalesReports

  },
  {
    path:"user-sales-list",Component:UserSalesList

  },
  {
    path:"user-sales-report/:id",Component:UserSalesReport

  },
  {
    path:"transactions-report/:id",Component:MobileBankingTransactionsReport

  }
];

export default mobileBankingRoutes;
