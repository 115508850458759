import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../Shared/Loading";
import { toast } from "react-toastify";
import { server_url } from "../../../../Config/API";
import UpdateHooks from "../../../../Hooks/UpdateHooks";
import { singleImageUpload } from "../../../../Hooks/ImageUpload";

const AdminCargoUpdateStatus = () => {
  const { id } = useParams();
  const [cargoOrder, setCargoOrder] = useState([]);
  const [orderer, setOrderer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("pending"); // State for selected status
  const navigator = useNavigate();

  useEffect(() => {
    setLoading(true);
    // Fetch cargo order data based on the `id` parameter
    fetch(`https://walletserver.genzamwallet.com/api/v1/cargoOrder/getCargoOrderById/${id}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`API request failed with status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setCargoOrder(data.data); // Set cargo order data in state
        fetch(`${server_url}/user/${data?.data?.ordererId}`).then((res) =>
          res.json().then((data) => setOrderer(data?.data))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching cargo order:", error);
        setLoading(false);
        // Handle the error as needed, e.g., show an error message.
      });
  }, [id]);

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value); // Update the selected status
  };

  const handleStatusUpdate = async () => {
    if (label) {
      if (selectedStatus === "rejected") {
        fetch(`${server_url}/cargoOrder/updateCargoOrder/${id}`, {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ status: selectedStatus, label: label }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data?.status === "success") {
              fetch(`${server_url}/user/${cargoOrder?.ordererId}`, {
                method: "PATCH",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  cargoBalance:
                    orderer?.cargoBalance + cargoOrder?.orderDetails?.total,
                }),
              })
                .then((res) => res.json())
                .then((data) => console.log(data));
            }
          });

        setSelectedStatus("");
        toast.success("Order Status Updated");
      } else {
        await UpdateHooks(`${server_url}/cargoOrder/updateCargoOrder/${id}`, {
          status: selectedStatus,
          label: label,
        });
        setCargoOrder("");
        toast.success("Order Status Updated");
        navigator(-1);
      }
    } else {
      toast.error("Please select label");
    }
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    singleImageUpload(formData, setLabel);
  };

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Cargo Order Details</h2>
      <div className="bg-white shadow-lg rounded-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="text-xl font-semibold">Order Information</div>
          {/* <Link to={`/cargo-orders/${cargoOrder._id}/edit`} className="text-blue-500 hover:underline">
            Edit Order
          </Link> */}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">Order ID: {cargoOrder._id}</p>

            <p className="text-gray-600">Type: {cargoOrder?.name}</p>

            <p className="text-gray-600">
              Entry By: {cargoOrder?.ordererUserName}
            </p>

            <p className="text-gray-600">
              Referral User Name: {cargoOrder?.referralUserName}
            </p>

            <p className="text-gray-600">
              Referral Phone: {cargoOrder?.referralPhone}
            </p>
          </div>
          <div>
            <p className="text-gray-600">Order Date: {cargoOrder.date}</p>
          </div>
        </div>
      </div>

      <div className="mt-6 bg-white shadow-lg rounded-lg p-6">
        <div className="text-xl font-semibold mb-4">Sender Information</div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">
              Customer Name: {cargoOrder?.senderInfo?.firstName}{" "}
              {cargoOrder?.senderInfo?.lastName}
            </p>

            <p className="text-gray-600">
              City: {cargoOrder?.senderInfo?.city}
            </p>

            <p className="text-gray-600">
              E-mail : {cargoOrder?.senderInfo?.email}
            </p>

            <p className="text-gray-600">
              State : {cargoOrder?.senderInfo?.state}
            </p>

            <p className="text-gray-600">
              Address : {cargoOrder?.senderInfo?.address}
            </p>
          </div>
          <div>
            <p className="text-gray-600">
              Phone: {cargoOrder?.senderInfo?.phone}
            </p>

            <p className="text-gray-600">
              documentNo : {cargoOrder?.senderInfo?.documentNo}
            </p>

            <p className="text-gray-600">
              Codice Fiscale: {cargoOrder?.senderInfo?.codiceFiscale}
            </p>

            <p className="text-gray-600">
              Post Code : {cargoOrder?.senderInfo?.postCode}
            </p>
          </div>
          {/* Add more customer information fields as needed */}
        </div>
      </div>

      <div className="mt-6 bg-white shadow-lg rounded-lg p-6">
        <div className="text-xl font-semibold mb-4">Receiver Information</div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">
              Customer Name: {cargoOrder?.receiverInfo?.firstName}{" "}
              {cargoOrder?.receiverInfo?.lastName}
            </p>
            <p className="text-gray-600">
              City: {cargoOrder?.receiverInfo?.city}
            </p>

            <p className="text-gray-600">
              E-mail : {cargoOrder?.receiverInfo?.email}
            </p>

            <p className="text-gray-600">
              Address : {cargoOrder?.receiverInfo?.address}
            </p>
          </div>
          <div>
            <p className="text-gray-600">
              Phone: {cargoOrder?.receiverInfo?.phone}
            </p>

            <p className="text-gray-600">
              documentNo : {cargoOrder?.receiverInfo?.documentNo}
            </p>

            <p className="text-gray-600">
              Country : {cargoOrder?.receiverInfo?.country}
            </p>

            <p className="text-gray-600">
              Post Code : {cargoOrder?.receiverInfo?.postCode}
            </p>
          </div>
          {/* Add more customer information fields as needed */}
        </div>
      </div>

      <div className="mt-6 bg-white shadow-lg rounded-lg p-6">
        <div className="text-xl font-semibold mb-4">
          Order Details Information
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">
              Product Description:{" "}
              {cargoOrder?.orderDetails?.productDescription}
            </p>
            <p className="text-gray-600">
              Quantity: {cargoOrder?.orderDetails?.quantity}
            </p>

            <p className="text-gray-600">
              Charge : {cargoOrder?.orderDetails?.charge}
            </p>

            <p className="text-gray-600">
              Total : {cargoOrder?.orderDetails?.total}
            </p>
          </div>
          <div>
            <p className="text-gray-600">
              Service Charge: {cargoOrder?.orderDetails?.serviceCharge}
            </p>

            <p className="text-gray-600">
              Total Commission :{" "}
              {cargoOrder?.orderDetails?.totalAgentCommission}
            </p>

            <p className="text-gray-600">
              Total With Delivery Charge :{" "}
              {cargoOrder?.orderDetails?.totalWithDeliveryCharge}
            </p>

            <p className="text-gray-600">
              Note : {cargoOrder?.orderDetails?.note}
            </p>
          </div>
          {/* Add more customer information fields as needed */}
        </div>
      </div>

      <div className=" mt-5 shadow-md p-5 flex justify-between items-center">
        <div>
          <label>Upload Label</label>
          <br />
          <input
            onChange={handleChangeUploadImage}
            className="px-10 py-1 bg-primary text-white rounded-sm"
            type="file"
          />
        </div>
        <div>
          <label>Update Status</label>
          <br />
          <select
            className="px-10 py-1 bg-primary text-white rounded-sm"
            value={selectedStatus}
            onChange={handleStatusChange}
          >
            <option value="pending">Pending</option>
            <option value="confirmed">Confirm</option>
            <option value="rejected">Reject</option>
          </select>
        </div>
        <div>
          <label>Update</label>
          <br />
          <button
            className="bg-primary text-white px-10 py-1 rounded-sm"
            onClick={handleStatusUpdate}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminCargoUpdateStatus;
