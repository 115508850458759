import { Icon } from "@iconify/react";
import React from "react";

const AgentRechargeTransactionsReportsTable = () => {
  return (
    <div>
      <div className=" flex justify-between  mb-3">
        <h1 className=" text-2xl">
          Recharge Order List (Total Transaction: 520)
        </h1>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="text-left bg-[#008C44] text-white">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Code</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Date</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Order / Payment
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Details
              </th>

              <th className="whitespace-nowrap px-4 py-2 font-medium">Debit</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Credit
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Balance
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            <tr className="odd:bg-gray-50">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                1
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                AY1695579724
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                01.09.2023
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                OU1693551656
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                TopUp IT 3512004959
              </td>

              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                9.9400
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                9.9600
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                173.9500
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AgentRechargeTransactionsReportsTable;
