import AgentBbRecharge from "../Component/Agent/AgentRiccarica/AgentBbRecharge";
import AgentMRechargeList from "../Component/Agent/AgentRiccarica/AgentMRechargeList";
import AgentRechargeMypayment from "../Component/Agent/AgentRiccarica/AgentRechargeMypayment";
import AgentRechargeReports from "../Component/Agent/AgentRiccarica/AgentRechargeReports";
import AgentRechargeTransactionsReports from "../Component/Agent/AgentRiccarica/AgentRechargeTransactionsReports";
import AgentUserMRechargeList from "../Component/Agent/AgentRiccarica/AgentUserMRechargeList";
import RicaricaUserSalesList from "../Component/Agent/AgentRiccarica/RicaricaUserSalesList";
import RiccaricaOrderInvoice from "../Component/Agent/AgentRiccarica/RiccaricaOrderInvoice";
import RicaricaUsersalesReport from "../Component/MobileBanking/MobileBankingReports/RicaricaUsersalesReport";
import RiccaricaIndex from "../Component/Riccarica/RiccaricaIndex";
import ChangePassword from "../Shared/ChangePassword";

const RiccaricaRoutes = [
  { path: "overview", Component: RiccaricaIndex },
  { path: "changePassword", Component: ChangePassword },
  { path: "agent-bd-recharge", Component: AgentBbRecharge },
  { path: "agent-MRecharge", Component: AgentMRechargeList },
  { path: "agent-user-MRecharge", Component: AgentUserMRechargeList },
  {
    path: "agent-recharge-reports",
    Component: AgentRechargeReports,
  },
  {
    path: "agent-recharge-transactions-reports",
    Component: AgentRechargeTransactionsReports,
  },
  {
    path: "agent-recharge-mypayment",
    Component: AgentRechargeMypayment,
  },

  {
    path: "agent-MRecharge/recharge-invoice/:id",
    Component: RiccaricaOrderInvoice,
  },

  {
    path:"riccarica-user-sales-list",Component:RicaricaUserSalesList
  },

  {
    path:"riccarica-user-sales-report/:id",Component:RicaricaUsersalesReport
  },
  
];

export default RiccaricaRoutes;
