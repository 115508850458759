import React, { useState } from 'react';
import CargoPending from './CargoPending';
import CargoConfirmOrder from './CargoConfirmOrder';
import CargoRejectOrder from './CargoRejectOrder';

const CargoOrder = () => {
    const [activeSection, setActiveSection] = useState('pending');

    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    return (
        <div>
            <div className='flex'>
                <h1
                    className={`border p-3 cursor-pointer ${
                        activeSection === 'pending' ? 'bg-primary text-white' : ''
                    }`}
                    onClick={() => handleSectionChange('pending')}
                >
                    Pending Order
                </h1>
                <h1
                    className={`border p-3 cursor-pointer ${
                        activeSection === 'confirm' ? 'bg-primary text-white' : ''
                    }`}
                    onClick={() => handleSectionChange('confirm')}
                >
                    Confirm Order
                </h1>
                <h1
                    className={`border p-3 cursor-pointer ${
                        activeSection === 'reject' ? 'bg-primary text-white' : ''
                    }`}
                    onClick={() => handleSectionChange('reject')}
                >
                    Reject Order
                </h1>
            </div>

            <div>
                {activeSection === 'pending' && <CargoPending />}
                {activeSection === 'confirm' && <CargoConfirmOrder />}
                {activeSection === 'reject' && <CargoRejectOrder />}
            </div>
        </div>
    );
};

export default CargoOrder;
