import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect } from "react";
import { server_url } from "../Config/API";
import AuthUser from "../Hooks/authUser";
import { toast } from "react-toastify";
import MyPaymentPostHook from "../Hooks/MyPaymentPostHook";
import moment from "moment";

const AgentUpdatePaymentModal = ({
  isOpen,
  setIsOpen,
  user,
  setRefetch,
  refetch,
}) => {
  let date = moment().format("YYYY-MM-D");
  const { userInfo } = AuthUser();
  const [myData, setMyData] = useState({});

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setMyData(data?.data || {}));
  }, [userInfo?._id]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const cargoBalance = +e.target.cargoBalance.value;
    const riccaricaBalance = +e.target.riccaricaBalance.value;
    const mobileBalance = +e.target.mobileBalance.value;
    const note = e.target.note.value;

    if (
      cargoBalance <= myData?.cargoBalance &&
      riccaricaBalance <= myData?.riccaricaBalance &&
      mobileBalance <= myData?.mobileBalance
    ) {
      await fetch(`${server_url}/user/${user?._id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cargoBalance: cargoBalance
            ? (cargoBalance + (user?.cargoBalance || 0)).toFixed(2)
            : (user?.cargoBalance || 0).toFixed(2),
          riccaricaBalance: riccaricaBalance
            ? (riccaricaBalance + (user?.riccaricaBalance || 0)).toFixed(2)
            : (user?.riccaricaBalance || 0).toFixed(2),
          mobileBalance: mobileBalance
            ? (mobileBalance + (user?.mobileBalance || 0)).toFixed(2)
            : (user?.mobileBalance || 0).toFixed(2),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            fetch(`${server_url}/user/${myData?._id}`, {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                cargoBalance: (+myData?.cargoBalance - cargoBalance).toFixed(2),
                riccaricaBalance: (+myData?.riccaricaBalance - riccaricaBalance).toFixed(2),
                mobileBalance: (+myData?.mobileBalance - mobileBalance).toFixed(2),
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                if (data?.status === "success") {
                  const paymentData = {
                    userId: userInfo?._id,
                    userUserName: userInfo?.userName,
                    userPhone: userInfo?.phone,
                    recipientNumber: user?.phone,
                    recipientId: user?._id,
                    recipientUserName: user?.userName,
                    bkashAmount: mobileBalance,
                    riccaricaAmount: riccaricaBalance,
                    cargoAmount: cargoBalance,
                    date: date,
                    note: note,
                  };
                  setIsOpen(false);
                  MyPaymentPostHook(paymentData);
                  toast?.success("User Balance Updated!");
                }
              });
          }
        });
    } else {
      toast.error("You don't have sufficient balance!");
    }
    setTimeout(() => {
      setRefetch(!refetch);
    }, 1000);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-5/12 md:8/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all">
                <div className="bg-primary/10 py-2 flex justify-between items-center px-5">
                  <h2 className="text-lg font-semibold">Update Balance</h2>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>

                <div className="border m-5 p-2 rounded-lg grid grid-cols-3 gap-2">
                  <div className="border-r">
                    <p className="font-semibold text-sm">Mobile Balance</p>
                    <p className="text-sm text-primary">
                    ৳  {(myData?.mobileBalance || 0).toFixed(2)}
                    </p>
                  </div>
                  <div className="border-r">
                    <p className="font-semibold text-sm">Riccarica Balance</p>
                    <p className="text-sm text-primary">
                    €  {(myData?.riccaricaBalance || 0).toFixed(2)}
                    </p>
                  </div>
                  <div>
                    <p className="font-semibold text-sm">Cargo Balance</p>
                    <p className="text-sm text-primary">
                    €  {(myData?.cargoBalance || 0).toFixed(2)}
                    </p>
                  </div>
                </div>

                <form
                  onSubmit={handleUpdate}
                  className="pb-5 px-5 space-y-3 z-50"
                >
                  <p className="text-xs text-gray-500">
                    Please enter the amounts you wish to update. Ensure that you have sufficient balance before proceeding.
                  </p>
                  <div>
                    <label htmlFor="mobileBanking" className="text-sm">
                      Mobile Banking Balance *
                    </label>
                    <input
                      name="mobileBalance"
                      type="number"
                      defaultValue={0}
                      required
                      placeholder="Enter your mobile banking balance"
                      className="w-full rounded-md border border-gray-300 p-2 mt-1 focus:outline-none"
                    />
                  </div>
                  <div>
                    <label htmlFor="riccaricaBalance" className="text-sm">
                      Riccarica Balance *
                    </label>
                    <input
                      name="riccaricaBalance"
                      type="number"
                      defaultValue={0}
                      required
                      placeholder="Enter your riccarica balance"
                      className="w-full rounded-md border border-gray-300 p-2 mt-1 focus:outline-none"
                    />
                  </div>
                  <div>
                    <label htmlFor="cargoBalance" className="text-sm">
                      Cargo Balance *
                    </label>
                    <input
                      name="cargoBalance"
                      type="number"
                      defaultValue={0}
                      required
                      placeholder="Enter your cargo balance"
                      className="w-full rounded-md border border-gray-300 p-2 mt-1 focus:outline-none"
                    />
                  </div>
                  <div>
                    <label htmlFor="note" className="text-sm block mb-1">
                      Note
                    </label>
                    <textarea
                      name="note"
                      className="border border-black/20 p-2 w-full rounded-md focus:outline-none"
                      placeholder="Type your note"
                    />
                  </div>

                  <button
                    type="submit"
                    className="bg-primary py-2 px-4 rounded-md text-white font-semibold hover:scale-105 duration-300"
                  >
                    Update!
                  </button>
                </form>
                <div className="border-t h-6 bg-primary/10"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AgentUpdatePaymentModal;
