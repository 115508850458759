import React from "react";

const BangladeshSimCompany = () => {
  return (
    <div className="  p-2 rounded-md  bg-ricaricabg">
      <div className="p-2 text-xs text-white ">
        <p>
          For comprehensive information regarding services, offers, and other
          inquiries, please visit the official website of the respective mobile
          operators.
        </p>
      </div>
      <div className="border-t border-gray-300 my-4"></div>
      <ul className="space-y-1">
        <li>
          <a
            href="https://www.grameenphone.com/"
            className="block px-4 py-2 text-sm font-medium text-white hover:bg-gray-100 hover:text-gray-700"
          >
            Grameenphone (GP)
          </a>
        </li>

        <li>
          <a
            href="https://www.banglalink.net/en"
            className="block px-4 py-2 text-sm font-medium text-white hover:bg-gray-100 hover:text-gray-700"
          >
            Banglalink (BL)
          </a>
        </li>

        <li>
          <a
            href="https://www.robi.com.bd/bn"
            className="block px-4 py-2 text-sm font-medium text-white hover:bg-gray-100 hover:text-gray-700"
          >
            Robi Axiata Limited (Robi)
          </a>
        </li>

        <li>
          <a
            href="https://www.bd.airtel.com/bn"
            className="block px-4 py-2 text-sm font-medium text-white hover:bg-gray-100 hover:text-gray-700"
          >
            Airtel Bangladesh (Airtel)
          </a>
        </li>
        <li>
          <a
            href="https://www.teletalk.com.bd/bn/"
            className="block px-4 py-2 text-sm font-medium text-white hover:bg-gray-100 hover:text-gray-700"
          >
            Teletalk Bangladesh Limited (Teletalk)
          </a>
        </li>
      </ul>
    </div>
  );
};

export default BangladeshSimCompany;
