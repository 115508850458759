import React from 'react';

const SenderInfoForm = ({setSenderInfo,senderInfo}) => {
  const handleChange = (e) => {
    setSenderInfo({
      ...senderInfo,
      [e.target.name]: e.target.value,
    });
  };
    return (
        <div className=" mt-5">
        <div>
          <h1 className=" font-bold text-xl">Sender Information</h1>
        </div>
        <div className=" lg:grid grid-cols-2 gap-5    font-thin">
          <div className=" ">
            <div>
              <label >First Name:</label>
              <br />
              <input
                type="text"
                onChange={handleChange}
                name="firstName"
                className=" p-1 outline-none border-gray border w-full"
                placeholder="Enter Sender First Name"
              />
            </div>

            <div>
              <label >Document No.</label>
              <br />
              <input
                type="text"
                name="documentNo"
                onChange={handleChange}
                className=" p-1 outline-none border-gray border w-full"
                placeholder="Enter Document  No"
              />
            </div>

            <div>
              <label >Phone:</label>
              <br />
              <input
                type="text"
                name="phone"
                onChange={handleChange}
                className=" p-1 outline-none border-gray border w-full"
                placeholder="Enter Phone  No"
              />
            </div>

            <div>
              <label >City:</label>
              <br />
              <input
                type="text"
                name="city"
                onChange={handleChange}
                className=" p-1 outline-none border-gray border w-full"
                placeholder="Enter City "
              />
            </div>

            <div>
              <label >Post Code:</label>
              <br />
              <input
                type="number"
                name="postCode"
                onChange={handleChange}
                className=" p-1 outline-none border-gray border w-full"
                placeholder="Enter post code "
              />
            </div>

            <div>
              <label >Address:</label>
              <br />
              <textarea
                type="text"
                name="address"
                onChange={handleChange}
                className=" p-1 outline-none border-gray border w-full"
                placeholder="Enter address  "
              />
            </div>
          </div>

          <div>
            <div>
              <label >last Name:</label>
              <br />
              <input
                type="text"
                name="lastName"
                onChange={handleChange}
                className=" p-1 outline-none border-gray border w-full"
                placeholder="Enter Sender Last Name"
              />
            </div>

            <div>
              <label >Codice Fiscale :</label>
              <br />
              <input
                type="text"
                name="codiceFiscale"
                onChange={handleChange}
                className=" p-1 outline-none border-gray border w-full"
                placeholder="Enter Document  No"
              />
            </div>

            <div>
              <label >E-mail:</label>
              <br />
              <input
                type="email"
                name="email"
                onChange={handleChange}
                className=" p-1 outline-none border-gray border w-full"
                placeholder="Enter E-mail "
              />
            </div>

            <div>
              <label >State:</label>
              <br />
              <input
                type="text"
                name="state"
                onChange={handleChange}
                className=" p-1 outline-none border-gray border w-full"
                placeholder="Enter State "
              />
            </div>

            {/* <div>
              <label >Upload:</label>
              <br />
              <input
                type="file"
                name="documentFile"
                
                className=" p-1 outline-none border-gray border w-full"
             
              />
            </div> */}
          </div>
        </div>
      </div>
    );
};

export default SenderInfoForm;