import { Icon } from "@iconify/react";
import React from "react";
import { Link, useParams } from "react-router-dom";

const MyPaymentTable = ({ order, i }) => {

  const truncateNote = (text) => {
    const words = text?.split(' ');
    if (words?.length > 7) {
      return words?.slice(0, 7).join(' ') + '...';
    }
    return text;
  };
  return (
    <tr className="odd:bg-gray-50">
      <td className="whitespace-nowrap px-4 py-2 border font-medium text-gray-900">
        {i + 1}
      </td>

      <td className="whitespace-nowrap px-4 py-2 border text-gray-700">
        {order?.date}
      </td>
      <td className="whitespace-nowrap px-4 py-2 border text-gray-700">
        {order?.recipientUserName}
      </td>
      <td className="whitespace-nowrap px-4 py-2 border text-gray-700">
        {order?.recipientNumber}
      </td>
      <td className="whitespace-nowrap px-4 py-2 border text-gray-700">
         {order?.serviceName}
      </td>
      <td className="whitespace-nowrap px-4 py-2 border text-gray-700">
         {order?.amount}
      </td>
      <td className="whitespace-nowrap px-4 py-2 border text-gray-700">
      {order?.status}
      </td>
      <td className="whitespace-nowrap px-4  py-2 border text-gray-700">
      {truncateNote(order?.note)}
      </td>
      {/* <td className="whitespace-nowrap px-4 py-2 border text-gray-700">
        <Link to={`edit-order/${order?._id}`}>
          <Icon className="text-xl" icon="uil:edit" />
        </Link>
      </td> */}
    </tr>
  );
};

export default MyPaymentTable;
