import React, { useEffect, useState } from "react";

import SenderInfoForm from "./SenderInfoForm";
import ReceiverInfoForm from "./ReceiverInfoForm";
import DocumentOrderDetails from "./DocumentOrderDetails";
import InternationalOrderDetails from "./InternationalOrderDetails";
import { server_url } from "../../../Config/API";
import Loading from "../../../Shared/Loading";
import AuthUser from "../../../Hooks/authUser";
import moment from "moment";
import { toast } from "react-toastify";

const CargoNewRequest = () => {
  const [selectedOption, setSelectedOption] = useState("Document");
  const [data, setData] = useState([])
  let date = moment().format("YYYY-MM-D");
  const [loading, setLoading] = useState(false)
  const { userInfo } = AuthUser();
  const [user, setUser] = useState([]);
  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setUser(data?.data));
  }, [userInfo?._id,user]);


  const [senderInfo, setSenderInfo] = useState({
    firstName: "",
    lastName: "",
    documentNo: "",
    phone: "",
    city: "",
    codiceFiscale: "",
    email: "",
    state: "",
    postCode: "",
    address: "",
  })

  const [receiverInfo, setReceiverInfo] = useState({
    firstName: "",
    lastName: "",
    documentNo: "",
    phone: "",
    city: "",
    email: "",
    postCode: "",
    address: "",
  })
  const [documentDetails, setDocumentDetails] = useState({
    productDescription: "",
    quantity: 0,
    charge: 0,
    total: 0,
    serviceCharge: 0,
    totalAgentCommission: 0,
    totalWithDeliveryCharge: 0,
    note: "",
  })

  const [internationalDetails, setInternationalDetails] = useState({
    box: 1,
    productDescription: "",
    weight: 0,
    perKg: 0,
    total: 0,
    charge: 0,
    serviceCharge: 0,
    totalAgentCommission: 0,
    totalWithDeliveryCharge: 0,
    note: "",
  })
  const totalCommission = selectedOption?.toLowerCase()?.includes("Document".toLowerCase()) ? documentDetails?.totalAgentCommission : internationalDetails?.totalAgentCommission;

  const agentCommission = (Number(totalCommission) / 100) * user?.agentCargoCommission;
  const userCommission = Number(totalCommission) - Number(agentCommission);

  // console.log("total: ", totalCommission, "agent: ", agentCommission, "user: ", userCommission)
  useEffect(() => {
    setLoading(true)
    fetch(`${server_url}/cargoDetails/getCargoDetails`).then(res => res.json().then(data => {
      setData(data?.data)
      setLoading(false)
    }))
  }, [])
  const filterData = data?.filter(d => d?.name?.toLowerCase()?.includes(selectedOption.toLowerCase()));

  const totalAmount = selectedOption?.toLowerCase()?.includes("Document".toLowerCase()) ? documentDetails?.total : internationalDetails?.total + agentCommission;

  const orderDetails = selectedOption?.toLowerCase()?.includes("Document".toLowerCase()) ? documentDetails : internationalDetails;

  const handelSubmit = () => {
    if (Number(totalAmount) <= Number(user?.cargoBalance)) {
      fetch(`${server_url}/cargoOrder/addCargoOrder`, {
        method: "POST",
        body: JSON.stringify({ name: selectedOption, date: date, totalAmount: totalAmount, senderInfo, receiverInfo, orderDetails: orderDetails, ordererUserName: userInfo?.userName,  ordererId: userInfo?._id, referralPhone: userInfo?.referralPhone, referralUserName: userInfo?.referralUserName, ordererPhone: userInfo?.phone }),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === "success") {
            fetch(`${server_url}/user/${userInfo?._id}`, {
              method: "PATCH",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({
                cargoBalance: Number(user?.cargoBalance) - Number(totalAmount),
              }),
            })
              .then((res) => res.json())
              .then((d) => {

                if (d.status === "success") {
                  if (user?.agentCargoCommission) {
                    fetch(`${server_url}/user/${user?.referralId}`, {
                      method: "PATCH",
                      headers: {
                        "content-type": "application/json",
                      },
                      body: JSON.stringify({
                        agentCargoCommission: user?.agentCargoCommission + agentCommission,
                      })
                    })
                  }
                  toast?.success("Submit Cargo Request");
                }
              });
          }
        });
    } else {
      toast?.error("Insufficient Balance");
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <div className=" m-5 shadow-md p-3">
        <h2 className="text-lg font-medium text-right">Your Balance: € <span className="text-primary">{user?.cargoBalance}</span></h2>
        <h1 className=" text-black  font-bold text-2xl">Add Cargo Order</h1>
        <div className=" mt-3">
          <select
            className="p-2 w-80 border bg-white"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target?.value)}
          >

            {
              data?.map(d => <option key={d?._id} value={d?.name}>{d?.name}</option>)
            }
          </select>
        </div>

        <SenderInfoForm setSenderInfo={setSenderInfo} senderInfo={senderInfo} />

        <ReceiverInfoForm setReceiverInfo={setReceiverInfo} receiverInfo={receiverInfo} />

        {
          selectedOption?.toLowerCase()?.includes("Document".toLowerCase()) ? <DocumentOrderDetails documentDetails={documentDetails} setDocumentDetails={setDocumentDetails} data={filterData?.length ? filterData[0] : []} /> : <InternationalOrderDetails data={filterData?.length ? filterData[0] : []} internationalDetails={internationalDetails} setInternationalDetails={setInternationalDetails} />
        }



        <button disabled={totalAmount < 1} onClick={handelSubmit} className="font-semibold text-white bg-primary disabled:bg-gray-300 disabled:scale-100 disabled:cursor-not-allowed rounded-md py-2 px-4 hover:scale-105 duration-300 my-5" >Request Order</button>

      </div>
    </div>
  );
};

export default CargoNewRequest;
