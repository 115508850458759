import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import UpdateHooks from "../Hooks/UpdateHooks";
import { server_url } from "../Config/API";
import moment from "moment";
import AuthUser from "../Hooks/authUser";
import MyPaymentPostHook from "../Hooks/MyPaymentPostHook";

const AdminUpdatePaymentModal = ({
  isOpen,
  setIsOpen,
  user,
  setRefetch,
  refetch,
}) => {
  const {userInfo}=AuthUser()
  let date = moment().format("YYYY-MM-D");
  const handelUpdate = async (e) => {
    const cargoBalance = +e.target.cargoBalance.value;
    const riccaricaBalance = +e.target.riccaricaBalance.value;
    const mobileBalance = +e.target.mobileBalance.value;
    const note = e.target.note.value;
    e.preventDefault();
    await UpdateHooks(
      `${server_url}/user/${user?._id}`,
      {
        cargoBalance: cargoBalance
          ? Number(cargoBalance + user?.cargoBalance)
          : user?.cargoBalance,
        riccaricaBalance: riccaricaBalance
          ? Number(riccaricaBalance + user?.riccaricaBalance)
          : user?.riccaricaBalance,
        mobileBalance: mobileBalance
          ? Number(mobileBalance + user?.mobileBalance)
          : user?.mobileBalance,
      },
      true,
      "Balance Updated !"
    );
    const data={
      userId:userInfo?._id,
      userUserName:userInfo?.userName,
      userPhone:userInfo?.phone,

      recipientNumber:user?.phone,
      recipientId:user?._id,
      recipientUserName:user?.userName,

      bkashAmount:mobileBalance,
      riccaricaAmount:riccaricaBalance,
      cargoAmount:cargoBalance,

      date:date,
      note:note,

    }
    MyPaymentPostHook(data)
    setTimeout(() => {
      setRefetch(!refetch);
    }, 1000);
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-5/12 md:8/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle  alig shadow-xl transition-all">
                <div className="bg-primary/10 py-2 flex justify-between items-center px-5">
                  <h2 className="text-lg font-semibold">Update Balance</h2>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>
                <form onSubmit={handelUpdate} className=" p-5 space-y-3">
                  <div className="">
                    <label for="mobileBanking" className="text-sm">
                      Mobile Banking Balance  ৳ *
                    </label>
                    <input
                      name="mobileBalance"
                      type="number"
                      defaultValue={0}
                      required
                      placeholder="Enter Your mobile banking balance"
                      className="w-full rounded-md  border border-gray-300 p-2 mt-1 focus:outline-none"
                    />
                  </div>
                  <div className="">
                    <label for="riccaricaBalance" className="text-sm">
                      Riccarica Balance  € *
                    </label>
                    <input
                      name="riccaricaBalance"
                      type="number"
                      defaultValue={0}
                      required
                      placeholder="Enter Your riccarica balance"
                      className="w-full rounded-md  border border-gray-300 p-2 mt-1 focus:outline-none"
                    />
                  </div>
                  <div className="">
                    <label for="cargoBalance" className="text-sm">
                      Cargo Balance € *
                    </label>
                    <input
                      name="cargoBalance"
                      type="number"
                      defaultValue={0}
                      required
                      placeholder="Enter Your Cargo balance"
                      className="w-full rounded-md  border border-gray-300 p-2 mt-1 focus:outline-none"
                    />
                  </div>
                  <div className="">
                    <label for="note" className="text-sm block mb-1">
                      Note
                    </label>
                    <textarea name="note" className="border border-black/20 p-2 w-full rounded-md focus:outline-none" placeholder="type your note"/>
                  </div>
                  <button
                    type="submit"
                    className="bg-primary py-2 px-4 rounded-md text-white  font-semibold hover:scale-105 duration-300"
                  >
                    Update !
                  </button>
                </form>
                <div className="border-t h-6 bg-primary/10"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AdminUpdatePaymentModal;
