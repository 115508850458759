import React from "react";
import { Link } from "react-router-dom";

const PublicFooter = () => {
  return (
    <div>
      <footer class="bg-primary text-white py-4 px-3">
        <div class="container mx-auto flex flex-wrap items-center justify-between">
          <div class="w-full md:w-1/2 md:text-center md:mb-0 mb-8">
            <p class="text-xs text-white md:text-sm">
              Copyright 2024 &copy; All Rights Reserved Genzam-IT
            </p>
          </div>
          <div class="w-full md:w-1/2 md:text-center md:mb-0 mb-8">
            <ul class="list-reset flex justify-center flex-wrap text-xs md:text-sm gap-3">
              <li>
                <Link to="contact" class="text-white hover:text-black">
                  Contact
                </Link>
              </li>
              <li class="mx-4">
                <Link to="privacy-policy" class="text-white hover:text-black">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="terms-condition" class="text-white hover:text-black">
                  Terms of Use
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PublicFooter;
