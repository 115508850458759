import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="lg:w-[90%] mx-auto">
      <div className="w-full md:p-14 p-2 grid grid-cols-1 lg:grid-cols-1 gap-5">
        <div className="w-full">
          <h2 className="font-semibold pb-6">
            Privacy Policy of Genzam-IT.
          </h2>
          <p>
            Welcome to our Privacy Policy page. We value your trust and are
            committed to protecting your personal information. This policy
            outlines how we collect, use, and safeguard your data while using
            our services.
          </p>
        </div>
        <div className="w-full">
          <h2 className="font-semibold pb-6">Information Collection and Use</h2>
          <p>
          We may collect personal information, such as your name, contact details, and more, to provide you with our services effectively.
          </p>
        </div>
        <div className="w-full">
          <h2 className="font-semibold pb-6">
            We may use your personal information to:
          </h2>
          <li>Process your orders and provide customer support</li>
          <li>Improve our products and services</li>
          <li>
            Send you marketing communications about our products and services
          </li>
          <li>
            Conduct research and analytics to better understand our customers
            and their needs
          </li>
          <li>Comply with legal obligations and protect our rights</li>
          <p>
            We may share your personal information with third-party service
            providers who help us operate our business, such as payment
            processors, shipping companies, and marketing agencies. We require
            these service providers to maintain the confidentiality and security
            of your personal information and to use it only for the purposes we
            specify.
          </p>
        </div>
        <div className="w-full">
          <h2 className="font-semibold pb-6">Data Security</h2>
          <p>
            We take reasonable measures to protect the security and integrity of
            your personal information. We implement appropriate technical and
            organizational measures to prevent unauthorized access, disclosure,
            alteration, or destruction of your personal information. However, no
            security measures can guarantee 100% protection against all
            potential security threats.
          </p>
        </div>
        <div className="w-full">
          <h2 className="font-semibold pb-6">Data Retention</h2>
          <p>
            We retain your personal information only for as long as necessary to
            fulfill the purposes for which it was collected, unless a longer
            retention period is required by law or for legitimate business
            purposes.
          </p>
        </div>
        <div className="w-full">
          <h2 className="font-semibold pb-6">Your Rights</h2>
          <p>
            You have the right to access, correct, and delete your personal
            information, as well as to object to or restrict its processing. You
            also have the right to withdraw your consent to our use of your
            personal information at any time, where we rely on your consent as
            our legal basis for processing your personal information. To
            exercise any of these rights, please contact us at the email address
            provided below.
          </p>
        </div>
        <div className="w-full">
          <h2 className="font-semibold pb-6">Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or applicable laws. We encourage you to
            review this Privacy Policy periodically to stay informed about our
            data practices.
          </p>
        </div>
        <div className="w-full">
          <h2 className="font-semibold pb-6">Contact Us</h2>
          <p>
            If you have any questions or concerns about our Privacy Policy,
            please contact us at info@genzamit.com.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
