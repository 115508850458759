import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { server_url } from "../../../Config/API";
import Loading from "../../../Shared/Loading";
import Pagination from "../../../Shared/Pagination";
import AdminBkashOrderTable from "./AdminBkashOrderTable";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AdminBkashOrders = () => {
  const [status, setStatus] = useState("pending");
  const [search, setSearch] = useState("");
  const [filterOrders, setFilterOrders] = useState([]);
  const [refetch, setRefetch] = useState(false);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [orders, setOrders] = useState([]);

  const [pendingLength, setPendingLength] = useState(0);
  const [confirmedLength, setConfirmedLength] = useState(0);
  const [rejectedLength, setRejectedLength] = useState(0);

  useEffect(() => {
    fetch(
      `${server_url}/mobileBankingOrder/specific/?size=${size}&&page=${page}&&fieldName=${"status"}&&fieldValue=${status}`
    )
      .then((res) => res.json())
      .then((data) => {
        setQuantity(data?.total);
        setOrders(data?.data);
        setFilterOrders(data?.data);
      });
  }, [page, size, refetch, status]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  useEffect(() => {
    const pending = orders.filter((order) => order.status === "pending");
    const confirmed = orders.filter((order) => order.status === "confirmed");
    const rejected = orders.filter((order) => order.status === "rejected");
    setPendingLength(pending.length);
    setConfirmedLength(confirmed.length);
    setRejectedLength(rejected.length);
  }, [orders]);

  const handleSearch = () => {
    const results = orders.filter((data) => {
      return (
        data?._id?.toLowerCase().includes(search.toLowerCase()) ||
        data?.ordererUserName?.toLowerCase().includes(search.toLowerCase()) ||
        data?.recipientNumber === search ||
        data?.totalAmount === search ||
        data?.status === search
      );
    });
    setFilterOrders(results);
  };

  return (
    <div className="p-4">
      <div className="flex border w-fit rounded-lg overflow-hidden">
        <button
          onClick={() => setStatus("pending")}
          className={`${
            status === "pending"
              ? "bg-primary text-white"
              : "bg-white text-black"
          } font-semibold py-2 px-4`}
        >
          Pending Orders
        </button>
        <button
          onClick={() => setStatus("confirmed")}
          className={`${
            status === "confirmed"
              ? "bg-primary text-white"
              : "bg-white text-black"
          } font-semibold py-2 px-4`}
        >
          Confirmed Orders
        </button>
        <button
          onClick={() => setStatus("rejected")}
          className={`${
            status === "rejected"
              ? "bg-primary text-white"
              : "bg-white text-black"
          } font-semibold py-2 px-4`}
        >
          Rejected Orders
        </button>
      </div>

      <div className="lg:grid lg:grid-cols-3 gap-5 md:grid md:grid-cols-3 font-bold mb-4 mt-5">
        <div className="flex justify-center items-center bg-[#900C3F] shadow text-white h-24 mb-2 max-w-xs rounded-md gap-2 text-lg">
          <Icon icon="subway:admin-1" />
          <p>Pending:</p>
          <p>{pendingLength}</p>
        </div>
        <div className="flex justify-center items-center bg-[#C70039] text-white shadow h-24 mb-2 max-w-xs rounded-md gap-2 text-lg">
          <Icon icon="subway:admin-1" />
          <p>Confirmed:</p>
          <p>{confirmedLength}</p>
        </div>
        <div className="flex justify-center items-center bg-[#F94C10] text-white shadow h-24 mb-2 max-w-xs rounded-md gap-2 text-lg">
          <Icon icon="subway:admin-1" />
          <p>Rejected:</p>
          <p>{rejectedLength}</p>
        </div>
      </div>

      <div className="flex justify-between gap-5 items-center mt-3">
        <div className="flex relative rounded-md w-full">
          <input
            type="text"
            placeholder="Search by Recipient Number, Name, or UserName"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full p-2 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none text-sm"
          />
          <button
            onClick={handleSearch}
            className="inline-flex items-center gap-2 bg-primary text-white text-sm font-semibold py-2 px-4 rounded-r-md hover:bg-primary/90"
          >
            <span>Search</span>
            <span className="hidden md:block">
              <Icon icon="material-symbols:search" />
            </span>
          </button>
        </div>
      </div>

      <div className="w-full overflow-x-auto mt-10">
        <table className="w-full text-left rounded w-overflow-x-auto" cellSpacing="0">
          <thead className=" bg-primary text-white">
            <tr>
              <th className="h-12 px-4 text-xs font-medium ">
                #
              </th>
              <th className="h-12 px-4 text-xs font-medium ">
                Date
              </th>
              <th className="h-12 px-4 text-xs font-medium">
                Service
              </th>
              <th className="h-12 px-4 text-xs font-medium ">
                Type
              </th>
              <th className="h-12 px-4 text-xs font-medium ">
                Recipient
              </th>
              <th className="h-12 px-4 text-xs font-medium ">
                Entry
              </th>
              <th className="h-12 px-4 text-xs font-medium ">
                R.userName
              </th>
              <th className="h-12 px-4 text-xs font-medium ">
                Amount
              </th>
              <th className="h-12 px-4 text-xs font-medium ">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filterOrders.length === 0 ? (
              Array.from({ length: 5 }).map((_, index) => (
                <tr key={index}>
                  {Array.from({ length: 9 }).map((_, subIndex) => (
                    <td key={subIndex} className="px-4">
                      <Skeleton height={20} />
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              filterOrders.map((order, i) => (
                <AdminBkashOrderTable
                  key={order?._id}
                  i={i}
                  order={order}
                  refetch={refetch}
                  setRefetch={setRefetch}
                />
              ))
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

export default AdminBkashOrders;
