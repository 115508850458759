import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import Loading from "../../Shared/Loading";

const AdminDashboardIndex = () => {
  const [rate, setRate] = useState({});
  const [user, setUser] = useState([]);
  const [mobileOrder, setMobileOrder] = useState(0);
  const [riccaricaOrder, setRiccaricaOrder] = useState(0);
  const [totals, setTotals] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());



  const [totalAmountRicarica, setTotalAmountRicarica] = useState(0);
  const [quantityRicarica, setQuantityRicarica] = useState(0);

  const [userTimeZone, setUserTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    setLoading(true);
    fetch(`https://walletserver.genzamwallet.com/api/v1/exchangeRate/getExchangeRate`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data) {
          setRate(data?.data[0]);
        }
      })
      .catch((error) => {
        console.error("Error fetching exchange rate: ", error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`https://walletserver.genzamwallet.com/api/v1/user`)
      .then((res) =>
        res.json().then((data) => {
          setUser(data?.data);
          setLoading(false);
        })
      )
      .catch((error) => {
        console.error("Error fetching user data: ", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`https://walletserver.genzamwallet.com/api/v1/mobileBankingOrder/getMobileBankingOrder`)
      .then((res) =>
        res.json().then((data) => {
          setMobileOrder(data?.total);
          const confirmOrders = data?.data.filter((order) => order.status === "confirmed");
          const totalQuantity = confirmOrders.length;
          setQuantity(totalQuantity);

          const total = confirmOrders.reduce((acc, order) => acc + order.totalAmount, 0);
          setTotals(total);

          const totalAmounts = confirmOrders.reduce((acc, order) => acc + order.amount, 0);
          setTotalAmount(totalAmounts);

          const totalCommissions = confirmOrders.reduce((acc, order) => acc + order.commission, 0);
          setTotalCommission(totalCommissions);

          setLoading(false);
        })
      )
      .catch((error) => {
        console.error("Error fetching mobile orders: ", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`https://walletserver.genzamwallet.com/api/v1/riccaricaOrder/getRiccaricaOrder`)
      .then((res) =>
        res.json().then((data) => {
          setRiccaricaOrder(data?.total);
          const confirmOrders = data?.data.filter((order) => order.status === "confirmed");
          const totalQuantityRicarica = confirmOrders.length;
          setQuantityRicarica(totalQuantityRicarica);

          const riccaricaTotals = confirmOrders.reduce((acc, order) => acc + order.totalAmount, 0);
        setTotalAmountRicarica  (riccaricaTotals);
          setLoading(false);


        })
      )
      .catch((error) => {
        console.error("Error fetching riccarica orders: ", error);
        setLoading(false);
      });
  }, []);

  const totalAgent = user?.filter((u) => u?.role === "agent");
  const userQuantity = user?.filter((u) => u?.role === "user");
useEffect(()=>{   
    fetch(`https://walletserver.genzamwallet.com/api/v1/exchangeRate/getExchangeRate`).then(res=>res.json()).then(data=>{
        if(data?.data){
          setRate(data?.data[0]) 
        }      
    })
},[])

useEffect(()=>{
fetch(`https://walletserver.genzamwallet.com/api/v1/user`).then(res=>res.json().then(data=>{
  setUser(data?.data)
}))
},[])

useEffect(()=>{
fetch(`https://walletserver.genzamwallet.com/api/v1/mobileBankingOrder/getMobileBankingOrder`).then(res=>res.json().then(data=>{
  setMobileOrder(data?.total)
}))
},[])
useEffect(()=>{
fetch(`https://walletserver.genzamwallet.com/api/v1/riccaricaOrder/getRiccaricaOrder`).then(res=>res.json().then(data=>{
  setRiccaricaOrder(data?.total)
}))
},[])

  return (
    <div>
      {loading ? (
     <Loading></Loading>
      ) : (
        <>
        <div>
      <div className="lg:grid lg:grid-cols-3 gap-5 md:grid md:grid-cols-3 font-bold mb-4">
        <div className="flex justify-center items-center bg-[#900C3F] shadow text-white h-40 mb-2 max-w-xs rounded-md gap-2 text-xl">
          <Icon icon="subway:admin-1"></Icon>
          <p>Total User: </p>
          <p>{userQuantity?.length}</p>
        </div>
        <div className="flex justify-center items-center bg-[#C70039] text-white shadow h-40 mb-2 max-w-xs rounded-md gap-2 text-xl">
          <Icon icon="subway:admin-1"></Icon>
          <p>Total Agent:</p>
          <p>{totalAgent?.length}</p>
        </div>
        <div className="justify-center flex items-center bg-[#F94C10] text-white shadow h-40 mb-2 max-w-xs rounded-md gap-2 text-xl">
          <Icon icon="subway:admin-1"></Icon>
          <p>Total Order: </p>
          <p>{Number(riccaricaOrder) + Number(mobileOrder)}</p>
        </div>
      </div>

      <div className="bg-gray-200 h-[1px] w-full "></div>
      
      <div className="bg-[#12486B] text-white shadow-md p-4 mt-3 rounded-lg">
        <h1 className="text-xl font-bold mb-2">Conversion Rate Today</h1>
        <p className=" font-thin mb-2">
          {currentTime.toLocaleDateString("en-US", { weekday: "long" })} (
          {userTimeZone})
        </p>
        <p className=" font-thin mb-4">{currentTime.toLocaleString()}</p>

        <div className="border-t border-gray-300 pt-2">
          <p className="text-lg font-thin">
            1 EURO to BDT Exchange Rate: ৳ {rate?.bdtExchangeRate} BDT
          </p>
          <p className="text-lg font-thin">
            1 BDT to EURO Exchange Rate: ৳ {rate?.euroExchangeRate} EURO
          </p>
        </div>
      </div>

      <div className="bg-[#12486B] text-white shadow-md p-4 mt-3 rounded-lg">
        <h1 className="text-xl font-bold mb-2">Bkash Total Sales</h1>
          <p className=" font-thin" >Total Sales: ৳ {totalAmount}</p>
          <p className=" font-thin mb-4">Total Transaction Fee: ৳ {totalCommission}</p>

        <div className="border-t border-gray-300 pt-2">
        <p className=" font-thin ">Agent Commission with Sales & Transaction Fee : ৳ {totals.toFixed(2)}</p>

        <p className=" font-thin mb-4">Total Order :  {quantity}</p>

        </div>
      </div>


      <div className="bg-[#12486B] text-white shadow-md p-4 mt-3 rounded-lg">
        <h1 className="text-xl font-bold mb-2">Ricarica Total Sales</h1>
          <p className=" font-thin" >Total Sales: € {totalAmountRicarica.toFixed(2)}</p>

        <div className="border-t border-gray-300 pt-2">

        <p className=" font-thin mb-4">Total Order :  {quantityRicarica}</p>

        </div>
      </div>

      
    </div>
        </>
      )}
    </div>
  );
};

export default AdminDashboardIndex;
