import flatpickr from "flatpickr";
import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../Shared/Loading";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AdminRiccaricaReports = () => {
  const { id } = useParams();

  // console.log(id)
  // useEffect(() => {
  //   flatpickr("#datepicker", {
  //     // Configuration options for Flatpickr
  //     // You can customize the appearance and behavior here
  //   });
  // }, []);

  const [orders, setOrders] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterOrders, setFilterOrders] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const [quantity, setQuantity] = useState(0);

  const [selectedMonthTotalAmount, setSelectedMonthTotalAmount] = useState(0);
  const [selectedMonthTotalOrders, setSelectedMonthTotalOrders] = useState(0);

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/riccaricaOrder/getRiccaricaOrder`
    ).then((res) =>
      res.json().then((data) => {
        setQuantity(data?.total);

        const confirmOrders = data?.data.filter((order) => {
          return order.status === "confirmed" && order.ordererId === id;
        });

        setOrders(confirmOrders);
        setFilterOrders(confirmOrders);
        setLoading(false);
      })
    );
  }, [refetch, id]);

  const filterOrdersByDate = () => {
    if (selectedMonth) {
      const results = orders?.filter((data) => {
        const orderDate = new Date(data.date);
        return (
          orderDate.getMonth() === selectedMonth.getMonth() &&
          orderDate.getFullYear() === selectedMonth.getFullYear()
        );
      });
      setFilterOrders(results);
      const totalAmount = results.reduce((acc, order) => acc + order.totalAmount, 0);
      const totalOrders = results.length;
      setSelectedMonthTotalAmount(totalAmount);
      setSelectedMonthTotalOrders(totalOrders);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div>
        <div class="h-auto p-3 rounded-lg bg-gray-200 mb-5">
          <div className="max-w-md mx-auto lg:flex items-center justify-center border-2  gap-5 border-[#008C44] p-5 shadow-md">
            <div className=" mx-auto  text-center">
              <label htmlFor="month">
                <p className="text-[#008C44] font-medium">
                  Select Month <span className="text-[#008C44]">*</span>
                </p>
                <DatePicker
                  selected={selectedMonth}
                  onChange={(date) => setSelectedMonth(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  className="w-full  px-10 py-2 mt-1 bg-white border-2 border-[#008C44] outline-none cursor-pointer p-2 rounded"
                  id="month"
                />
              </label>
            </div>

            <div className="  text-center">
              <p className="text-[#008C44] font-medium">
                Filter Now <span className="text-[#008C44]">*</span>
              </p>
              <button
                className="bg-[#008C44]  px-10  w-full text-white  text-xl py-2 rounded-md"
                onClick={filterOrdersByDate}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="text-center mb-5">
          <p className="text-bkashprimary font-medium">
            Total Amount for Selected Month: ৳ {selectedMonthTotalAmount.toFixed(2)}
          </p>
          <p className="text-bkashprimary font-medium">
            Total Orders for Selected Month: {selectedMonthTotalOrders}
          </p>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-xs">
            <thead className="text-left bg-[#008C44] text-white">
              <tr>
                <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Entry By
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Date
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Receiver Number
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Package Type
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Amount 
                </th>

                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Status
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {[...filterOrders]?.reverse()?.map((order, i) => (
                <tr key={order?._id} order={order} className="odd:bg-gray-50">
                  <td
                    key={order?._id}
                    order={order}
                    className="whitespace-nowrap px-4 py-2 font-medium text-gray-900"
                  >
                    {i + 1}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.ordererUserName}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.date}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.receiverMobileNumber}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.packageType}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.totalAmount.toFixed(2)}
                  </td>

                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    <button className="bg-[#008C44] px-2 text-white pb-1 rounded-full">
                      {" "}
                      {order?.status}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* ... Your table and pagination components ... */}
      </div>
    </div>
  );
};

export default AdminRiccaricaReports;
