import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import AuthUser from "../../Hooks/authUser";
import { useNavigate } from "react-router-dom";
import Loading from "../../Shared/Loading";


const Login = () => {
  const { http, setToken } = AuthUser();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // State to store user input
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    await http
      .post("/user/login", formData)
      .then((res) => {
        if (res?.data?.status === "success") {
          toast.success("Login Successfully");
          setToken(
            res.data.data.user.userName,
            res.data.data.token,
            res.data.data.user.role,
            res.data.data.user,
            res.data.data.userIp
          );
          navigate(`/${res.data?.data?.data?.role}Dashboard`);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(
          "Error",
          err.response.data.error || err.response.data.message
        );
        swal(
          "Error",
          `${err.response.data.message || err.response.data.error}`,
          "error"
        );
        setLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="h-screen overflow-hidden">
      <section className="bg-white h-full">
        <div className="lg:grid lg:h-full lg:grid-cols-12">
          <section className="relative flex h-32 items-end bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6">
            <img
              alt="Night"
              src="/money.png"
              className="absolute inset-0 h-full w-full object-cover opacity-80"
            />


            <div className="hidden lg:relative lg:block lg:p-12">
              <a className="block text-white" href="/">
                <span className="sr-only">Home</span>
                <Icon
                  className="text-4xl"
                  icon="twemoji:man-technologist-light-skin-tone"
                ></Icon>
              </a>

              <h2 className="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl">
                Welcome to Genzam-IT 🦑
              </h2>

              <p className="mt-4 leading-relaxed text-white/90">
                "Genzam-IT is your gateway to the digital product transfer
                industry. Join our platform to become a versatile digital
                product provider, offering a wide range of digital goods and
                services. Transform into a multi-service business hub,
                connecting customers with the digital products they need."
              </p>
            </div>
          </section>

          <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
            <div className="max-w-xl lg:max-w-3xl">
              <div className="relative -mt-16 block lg:hidden">
                <a
                  className="inline-flex h-16 w-16 items-center justify-center rounded-full bg-white text-blue-600 sm:h-20 sm:w-20"
                  href="/"
                >
                  <span className="sr-only">Home</span>
                  <Icon
                    className="text-4xl"
                    icon="twemoji:man-technologist-light-skin-tone"
                  ></Icon>
                </a>

                <h1 className="mt-2 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                  Welcome to Genzam-IT 🦑
                </h1>

                <p className="mt-4 leading-relaxed text-gray-500">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Eligendi nam dolorum aliquam, quibusdam aperiam voluptatum.
                </p>
              </div>

              <div>
                <div className="text-2xl text-text font-bold">
                  <h1>Genzam-IT</h1>
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="mt-8 grid grid-cols-6 gap-6"
                >
                  <div className="col-span-6">
                    <label
                      htmlFor="userName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      UserName
                    </label>

                    <input
                      type="text"
                      value={formData.userName}
                      onChange={handleChange}
                      name="userName"
                      required
                      className="mt-1 w-full rounded-md border-2 bg-gray-100 text-sm text-gray-700 shadow-sm p-2"
                      placeholder="Enter Your userName"
                    />
                  </div>
                  <div className="col-span-6">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>

                    <input
                      type="password"
                      name="password"
                      required
                      value={formData.password}
                      onChange={handleChange}
                      className="mt-1 w-full rounded-md border-2 bg-gray-100 text-sm text-gray-700 shadow-sm p-2"
                      placeholder="Enter Your Password"
                    />
                  </div>

                  <div className="col-span-6">
                    <label htmlFor="MarketingAccept" className="flex gap-4">
                      <input
                        type="checkbox"
                        id="MarketingAccept"
                        name="marketing_accept"
                        className="h-5 w-5 rounded-md border-gray-200 bg-white shadow-sm"
                      />

                      <span className="text-sm text-gray-700">
                        I want to receive emails about events, product updates
                        and company announcements.
                      </span>
                    </label>
                  </div>

                  <div className="col-span-6">
                    <p className="text-sm text-gray-500">
                      By Log in an account, you agree to our
                      <a href="#" className="text-gray-700 underline">
                        terms and conditions
                      </a>
                      and
                      <a href="#" className="text-gray-700 underline">
                        privacy policy
                      </a>
                      .
                    </p>
                  </div>

                  <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                    <button
                      type="submit"
                      className="inline-block shrink-0 rounded-md border hover:border-blue-600 bg-primary px-12 py-3 text-sm font-medium text-white duration-300 transition hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring active:text-blue-500"
                    >
                      Log In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </main>
        </div>
      </section>
    </div>
  );
};

export default Login;