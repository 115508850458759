import React, { useState } from 'react';
import GrameenPhone from './Operator/GrameenPhone';
import Banglalink from './Operator/Banglalink';
import Robi from './Operator/Robi';
import Airtel from './Operator/Airtel';
import Teletalk from './Operator/Teletalk';
import useOperatorStore from '../../../Store/operatorStore';

const AdminAllPackage = () => {
  const { currentOperator, setCurrentOperator } = useOperatorStore();

  const renderOperatorComponent = () => {
    switch (currentOperator) {
      case 'GrameenPhone':
        return <GrameenPhone />;
      case 'Banglalink':
        return <Banglalink />;
      case 'Robi':
        return <Robi />;
      case 'Airtel':
        return <Airtel />;
      case 'Teletalk':
        return <Teletalk />;
      default:
        return <GrameenPhone />;
    }
  };

  return (
    <div className="container mx-auto p-6">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-4">
          All Packages
        </h1>
        <p className="text-lg text-gray-700 mb-8">
          You can update, delete & view packages.
        </p>
      </div>

      <div className="flex justify-center gap-4 mb-6">
        {['GrameenPhone', 'Banglalink', 'Robi', 'Airtel', 'Teletalk'].map((operator) => (
          <button
            key={operator}
            onClick={() => setCurrentOperator(operator)}
            className={`px-4 py-2 rounded-lg font-medium ${
              currentOperator === operator ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'
            }`}
          >
            {operator}
          </button>
        ))}
      </div>

      <div className="mb-6">{renderOperatorComponent()}</div>
    </div>
  );
};

export default AdminAllPackage;
