import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const AdminAddPackage = () => {
  const [packageType, setPackageType] = useState("");
  const [priceBDT, setPriceBDT] = useState("");
  const [priceEuro, setPriceEuro] = useState("");
  const [commissionEuro, setCommissionEuro] = useState("");
  const [commissionBdt, setCommissionBdt] = useState("");
  const [packageDetails, setPackageDetails] = useState("");
  const [operator, setOperator] = useState("");
  const [exchangeRate, setExchangeRate] = useState([])

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/exchangeRate/getExchangeRate`).then(res => res.json()).then(data => {
      if (data?.data) {
        setExchangeRate(data?.data[0])
      }
    })
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPackage = {
      packageType,
      priceBDT: Number(priceBDT),
      commissionBdt: Number(commissionBdt),
      commissionEuro: Number(commissionEuro),
      priceEuro: Number(priceEuro),
      packageDetails,
      operator,
    };
    

    try {
      const response = await fetch("https://walletserver.genzamwallet.com/api/v1/packages/packages", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPackage),
      });

      if (response.status === 201) {
        toast.success("Package added successfully!");

        // Reset the form fields
        setPackageType("");
        setPriceBDT("");
        setPackageDetails("");
        setOperator("");
      } else {
        toast.error("Failed to add package. Please try again.", {});
      }
    } catch (error) {
      console.error("Error adding package:", error);
    }
  };

  return (
    <div>
      <div className="border-2 border-primary rounded-lg">
        <div className="mt-3 text-center text-4xl font-bold">
          Add Your Package
        </div>
        <form onSubmit={handleSubmit}>
          <div className="p-8">
            <div className="lg:flex gap-4">
              <div className="w-full">
                <label htmlFor="">Select Operator</label>
                <select
                  name="operator"
                  value={operator}
                  onChange={(e) => setOperator(e.target.value)}
                  required
                  className="block w-full mt-1 rounded-md border border-slate-300 bg-white px-3 py-4 font-semibold text-gray-500 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                >
                  <option className="font-semibold text-slate-300">
                    Please Select Operator
                  </option>
                  <option
                    value="GrameenPhone (GP)"
                    className="font-semibold text-slate-300"
                  >
                    GrameenPhone (GP)
                  </option>
                  <option
                    value="Banglalink (BL)"
                    class="font-semibold text-slate-300"
                  >
                    Banglalink (BL)
                  </option>{" "}
                  <option
                    value="Robi Axiate Limited (Robi)"
                    class="font-semibold text-slate-300"
                  >
                    Robi Axiate Limited (Robi)
                  </option>{" "}
                  <option
                    value="Airtel Bangladesh (Airtel)"
                    class="font-semibold text-slate-300"
                  >
                    Airtel Bangladesh (Airtel)
                  </option>{" "}
                  <option
                    value="Teletalk Bangladesh Limited (Teletalk)"
                    class="font-semibold text-slate-300"
                  >
                    Teletalk Bangladesh Limited (Teletalk)
                  </option>{" "}
                  {/* Add other operator options here */}
                </select>
              </div>
              <div className="w-full">
                <label>Select Package type</label>
                <select
                  name="packageType"
                  value={packageType}
                  onChange={(e) => setPackageType(e.target.value)}
                  required
                  className="block w-full mt-1 rounded-md border border-slate-300 bg-white px-3 py-4 font-semibold text-gray-500 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                >
                  <option className="font-semibold text-slate-300">
                    Please Select Package Type
                  </option>
                  <option
                    value="Combo"
                    className="font-semibold text-slate-300"
                  >
                    Combo
                  </option>
                  <option
                    value="Talk Time"
                    className="font-semibold text-slate-300"
                  >
                    Talk Time
                  </option>
                  <option
                    value="Internet"
                    className="font-semibold text-slate-300"
                  >
                    Internet
                  </option>
                  <option
                    value="Recharge"
                    className="font-semibold text-slate-300"
                  >
                    Recharge
                  </option>
                </select>
              </div>
            </div>

            <div className="lg:flex w-full my-5 gap-4">
              <div className="w-full">
                <label>Package Price (Euro)</label>
                <input
                  type="number"
                  name="priceEuro"
                  value={priceEuro}
                  required
                  onChange={(e) => setPriceEuro(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                  placeholder="Enter a Price Euro *"
                />
              </div>

              <div className="w-full">
                <label>Package Price (BDT)</label>
                <input
                  type="number"
                  name="priceBDT"
                  value={priceBDT}
                  required
                  onChange={(e) => setPriceBDT(e?.target?.value)}
                  className="mt-1 w-full block rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                  placeholder="Enter a price BDT *"
                  min="0"
                />
              </div>

            </div>
            <div className="lg:flex w-full mb-5 gap-4">
              <div className="w-full">
                <label>Commission (Euro)</label>
                <input
                  type="number"
                  name="commissionEuro"
                  value={commissionEuro}
                  required
                  onChange={(e) => setCommissionEuro(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                  placeholder="Enter a Price Euro *"
                />
              </div>

              <div className="w-full">
                <label>Commission (BDT)</label>
                <input
                  type="number"
                  name="commissionBdt"
                  value={commissionBdt}
                  onChange={(e) => setCommissionBdt(e?.target?.value)}
                  className="mt-1 w-full block rounded-md border border-slate-300 bg-white px-3 py-4 placeholder-slate-400 shadow-sm placeholder:font-semibold placeholder:text-gray-500 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
                  placeholder="Enter a price BDT *"
                  min="0"
                />
              </div>

            </div>
            {
              packageType!=="Recharge"&& <div className="">
              <label>Package Details</label>
              <textarea
                name="packageDetails"
                value={packageDetails}
                required
                onChange={(e) => setPackageDetails(e.target.value)}
                cols="30"
                rows="10"
                className="mb-10 mt-1 h-40 w-full resize-none rounded-md border border-slate-300 p-5 font-semibold text-black "
                placeholder="Enter package Details"
              ></textarea>
            </div>
            }
           
            <div className="text-center">
              <button
                type="submit"
                className="cursor-pointer rounded-lg bg-primary px-8 py-5 text-sm font-semibold text-white"
              >
                Add Package
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminAddPackage;
