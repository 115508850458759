import React, { useState } from "react";
import {
  Users,
  DollarSign,
  FileText,
  Edit,
  UserPlus,
  CirclePlus,
  History,
} from "lucide-react"; // Corrected icon imports
import UserListComponent from "./UserList";
import AddBalance from "./AddBalance";
import PaymentReport from "./PaymentReport";
import PaymentReportEdit from "./PaymentReportEdit";

const Payment = () => {
  const [show, setShow] = useState("userList");

  const buttonStyles =
    "duration-300 px-2 md:px-3 py-1 text-center rounded mr-2 md:mr-5 text-white text-sm md:text-lg font-semibold";
  const selectedButtonStyles = "bg-blue-600";
  const defaultButtonStyles = "bg-primary hover:bg-blue-700";

  return (
    <div className="px-5 md:px-10 min-h-screen">
      <div>
        {show === "userList" && (
          <h1 className="text-3xl my-8 font-bold text-stone-400 text-center mb-5 flex items-center justify-center">
            <Users className="mr-2" /> User List
          </h1>
        )}
        {show === "addBalance" && (
          <h1 className="text-3xl my-8 font-bold text-stone-400 text-center mb-5 flex items-center justify-center">
            <DollarSign className="mr-2" /> User Balance Add
          </h1>
        )}
        {show === "paymentReport" && (
          <h1 className="text-3xl my-8 font-bold text-stone-400 text-center mb-5 flex items-center justify-center">
            <FileText className="mr-2" /> User Payment Report
          </h1>
        )}
        {show === "edit" && (
          <h1 className="text-3xl my-8 font-bold text-stone-400 text-center mb-5 flex items-center justify-center">
            <Edit className="mr-2" /> User Payment Report Edit
          </h1>
        )}
      </div>

      <div className="flex justify-center mb-8">
        <button
          onClick={() => setShow("userList")}
          className={`${buttonStyles} ${
            show === "userList" ? selectedButtonStyles : defaultButtonStyles
          }`}
        >
          <div className=" flex items-center gap-1 justify-center">
            User List
            <UserPlus />
          </div>
        </button>
        <button
          onClick={() => setShow("addBalance")}
          className={`${buttonStyles} ${
            show === "addBalance" ? selectedButtonStyles : defaultButtonStyles
          }`}
        >
          <div className=" flex gap-1 justify-center">
            Add Balance
            <CirclePlus />
          </div>
        </button>
        <button
          onClick={() => setShow("paymentReport")}
          className={`${buttonStyles} ${
            show === "paymentReport"
              ? selectedButtonStyles
              : defaultButtonStyles
          }`}
        >
          <div className=" flex justify-center items-center gap-1">
            Payment Report
            <History />
          </div>
        </button>
      </div>

      <div>
        {show === "userList" && <UserListComponent />}
        {show === "addBalance" && <AddBalance />}
        {show === "paymentReport" && <PaymentReport setShow={setShow} />}
        {show === "edit" && <PaymentReportEdit />}
      </div>
    </div>
  );
};

export default Payment;
