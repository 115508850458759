import About from "../Component/Agent/AgentPublicComponent/About";
import Contact from "../Component/Agent/AgentPublicComponent/Contact";
import PrivacyPolicy from "../Component/Agent/AgentPublicComponent/PrivacyPolicy";
import TermsOfCondition from "../Component/Agent/AgentPublicComponent/TermsOfCondition";
import Login from "../Component/Login/Login";

const PublicRoutes = [{ path: "/login", Component: Login },
{path:"about",Component:About},
{path:"contact",Component:Contact},
{path:"privacy-policy",Component:PrivacyPolicy},
{path:"terms-condition",Component:TermsOfCondition}


];

export default PublicRoutes;
