import React from "react";
import PublicWebNavbar from "../../Component/WebNavBar/PublicWebNavbar";
import { Outlet } from "react-router-dom";
import PublicFooter from "../../Component/Footer/PublicFooter";

const HomeIndex = () => {
  return (
    <div>
      <PublicWebNavbar></PublicWebNavbar>
      <Outlet></Outlet>
      <PublicFooter></PublicFooter>
    </div>
  );
};

export default HomeIndex;
