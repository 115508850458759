import React from 'react';

const MobileBankingPaymentReport = () => {
  return (
<div>
  {/*
  Heads up! 👋

  This component comes with some `rtl` classes. Please remove them if they are not needed in your project.
*/}

<nav aria-label="Breadcrumb" className="flex">
  <ol
    className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600"
  >
    <li className="flex items-center">
      <a
        href="#"
        className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>

        <span className="ms-1.5 text-xs font-medium"> Home </span>
      </a>
    </li>

    <li className="relative flex items-center">
      <span
        className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"
      >
      </span>

      <a
        href="#"
        className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900"
      >
       Payment Reports
      </a>
    </li>
  </ol>
</nav>

<div className="max-w-md mx-auto flex flex-col items-center border-2 border-bkashprimary p-5 shadow-md">
      <div className="md:w-11/12 mx-auto">
        <label htmlFor="payment">
          <p className="text-bkashprimary font-medium">
            Payment method <span className="text-red-500">*</span>
          </p>
          <select
            className="w-full py-2 mt-1 bg-white border-2 border-bkashprimary p-2 rounded"
            required
            name="method"
            id="payment"
          >
            <option value="">Select</option>
            <option value="bkash">Paid Date</option>
            <option value="nagad">Received Date</option>
          </select>
        </label>
      </div>

      <div className="md:w-11/12 mx-auto mt-5">
        <label htmlFor="date">
          <p className="text-bkashprimary font-medium">
            Select Date <span className="text-red-500">*</span>
          </p>
          <input
            type="date"
            className="w-full py-2 mt-1 bg-white border-2 border-bkashprimary outline-none cursor-pointer p-2 rounded"
            required
            name="date"
            id="date"
          />
        </label>
      </div>

      <div className="mt-3">
        <button className="bg-bkashprimary text-white px-8 text-xl py-2 rounded-md">
          Search
        </button>
      </div>




      
    </div>

     
    <div className="overflow-x-auto mt-10">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="text-left bg-bkashprimary text-white">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Code</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Date</th>
             
              <th className="whitespace-nowrap px-4 py-2 font-medium">Type</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
              Received/Return	
              </th>
             
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Entry By
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
              Paid To	
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
              Amount
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Status
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            <tr className="odd:bg-gray-50">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                1
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                OT1691740564
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                11.08.2023
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                bKash
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                Personal
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                Amir Faysal
              </td>
         
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                osthirstore1250
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">50</td>
            
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                Pedding
              </td>
            </tr>
          </tbody>
          
        </table>

        
      </div>

</div>
  );
};

export default MobileBankingPaymentReport;
