import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect } from "react";
import { server_url } from "../Config/API";
import AuthUser from "../Hooks/authUser";
import { toast } from "react-toastify";

const AgentBkashModal = ({
  isOpen,
  setIsOpen,
  formData,
  agentCommission,
  setFormSubmitted,
}) => {
  const { userInfo } = AuthUser();
  const [pin, setPin] = useState("");
  const [myData, setMyData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timer, setTimer] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(5);

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setMyData(data?.data));
  }, [userInfo?._id]);

  const handelUpdate = async () => {
    if (isSubmitting) return; // Prevent multiple clicks

    if (myData?.pin === pin) {
      if (formData?.totalAmount <= myData?.mobileBalance) {
        setIsSubmitting(true); // Disable the button
        setTimeRemaining(5); // Reset the timer to 5 seconds

        fetch(`${server_url}/mobileBankingOrder/addMobileBankingOrder`, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "content-type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "success") {
              fetch(`${server_url}/user/${userInfo?._id}`, {
                method: "PATCH",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  mobileBalance: myData?.mobileBalance - formData?.totalAmount,
                }),
              })
                .then((res) => res.json())
                .then((d) => {
                  if (myData?.agentCommission) {
                    fetch(`${server_url}/user/${myData?.referralId}`, {
                      method: "PATCH",
                      headers: {
                        "content-type": "application/json",
                      },
                      body: JSON.stringify({
                        mobileBalance: myData?.mobileBalance + agentCommission,
                      }),
                    });
                  }
                  toast?.success("Submit Mobile Banking Request");
                  setFormSubmitted(true);

                  setTimer(setInterval(() => {
                    setTimeRemaining((prev) => prev - 1);
                  }, 1000));
                });
            }
          })
          .catch((error) => {
            toast?.error("Error submitting request");
            setIsSubmitting(false); // Re-enable the button in case of error
          });
      } else {
        toast?.error("Insufficient Balance");
      }
    } else {
      toast?.error("Your Password was wrong!");
    }
  };

  // Handle modal close and clear timer
  useEffect(() => {
    if (timeRemaining === 0) {
      clearInterval(timer);
      setIsSubmitting(false);
      setTimer(null);
      setIsOpen(false);
    }
  }, [timeRemaining, timer, setIsOpen]);

  useEffect(() => {
    if (!isOpen && timer) {
      clearInterval(timer);
      setIsSubmitting(false);
      setTimer(null);
      setTimeRemaining(5);
    }
  }, [isOpen, timer]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto z-50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-5/12 md:8/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all">
                <div className="bg-primary/10 py-2 flex justify-between items-center px-5">
                  <div className="flex flex-col gap-1">
                    <h2 className="text-lg font-semibold">
                      Verification Instructions
                    </h2>
                    <p className="text-xs">
                      To ensure a smooth and successful submission of your
                      mobile banking request.
                    </p>
                  </div>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>

                <div className="border m-5 p-2 rounded-lg grid grid-cols-2 gap-2">
                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Payment Method</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      {formData?.paymentMethod}
                    </p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Service Type</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      {formData?.serviceType}
                    </p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Recipient Number</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      {formData?.recipientNumber}
                    </p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Amount</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      {" "}
                      ৳ {formData?.amount}{" "}
                    </p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Charge</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      ৳ {formData?.commission}
                    </p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Total Amount</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      ৳ {formData?.totalAmount}
                    </p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Your Note</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      {formData?.message?.slice(0, 60)}...
                    </p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm mt-1">Password</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <input
                      name="mobileBalance"
                      type="password"
                      onChange={(e) => setPin(e.target?.value)}
                      required
                      placeholder="Enter Your Password"
                      className="w-full rounded-md border border-gray-300 px-2 py-1 focus:outline-none"
                    />
                  </div>
                </div>
                <div className="flex flex-col m-5">
                  <p className="text-xs">
                    Ensure all fields are correctly filled and double-check
                    your entries before submitting the form to avoid any
                    delays or issues with your transaction.
                  </p>
                  <button
                    onClick={handelUpdate}
                    className={`bg-primary w-fit my-2 py-2 px-4 rounded-md text-white font-semibold hover:scale-105 duration-300 ${
                      isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isSubmitting}
                  >
                    Send Request! {isSubmitting && `(${timeRemaining}s)`}
                  </button>
                </div>
                <div className="border-t h-6 bg-primary/10"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AgentBkashModal;
