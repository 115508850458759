import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { server_url } from "../../Config/API";
import UpdateHooks from "../../Hooks/UpdateHooks";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const EditMypayment = () => {
  const { id } = useParams();
  const navigator = useNavigate();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    note: "",
  });

  useEffect(() => {
    fetch(`${server_url}/myPayment/getMyPaymentById/${id}`).then((res) =>
      res.json().then((data) => {
        setFormData(data?.data);
        setLoading(false);
      })
    );
  }, [id]);

  const handelUpdate = async (e) => {
    e.preventDefault();
    UpdateHooks(
      `${server_url}/myPayment/updateMyPayment/${id}`,
      formData,
      "Note Details Updated !"
    );
    navigator(-1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="min-h-screen p-5">
      <h1 className="text-3xl font-bold text-center mb-5">Edit Payment Details</h1>
      <p className="text-center text-gray-600 mb-10">
        Use this form to update the details of a specific payment. Ensure all information is accurate before submitting.
      </p>
      <form className="max-w-2xl mx-auto border p-5 rounded-lg shadow-lg" onSubmit={handelUpdate}>
        <h2 className="text-xl font-semibold mb-4 text-center">Recipient Transaction Information</h2>
        <div className="mb-5">
          <label className="block text-gray-700 font-semibold mb-2">Recipient User Name</label>
          <p className="text-gray-900">
            {loading ? <Skeleton width={200} /> : formData.recipientUserName}
          </p>
        </div>
        <div className="mb-5">
          <label className="block text-gray-700 font-semibold mb-2">Bkash Amount</label>
          <p className="text-gray-900">
            {loading ? <Skeleton width={50} /> : `৳ ${formData?.bkashAmount}`}
          </p>
        </div>
        <div className="mb-5">
          <label className="block text-gray-700 font-semibold mb-2">Ricarica Amount</label>
          <p className="text-gray-900">
            {loading ? <Skeleton width={50} /> : `€ ${formData?.riccaricaAmount}`}
          </p>
        </div>
        <div className="mb-5">
          <label className="block text-gray-700 font-semibold mb-2">Cargo Amount</label>
          <p className="text-gray-900">
            {loading ? <Skeleton width={50} /> : `€ ${formData?.cargoAmount}`}
          </p>
        </div>
        <div className="mb-5">
          <label className="block text-gray-700 font-semibold mb-2">Previous Note</label>
          <p className="text-gray-900">
            {loading ? <Skeleton width={300} /> : formData?.note}
          </p>
        </div>
        <div className="mb-5">
          <label className="block text-gray-700 font-semibold mb-2">Update Your Note</label>
          <textarea
            className="border w-full h-32 p-3 rounded-md outline-none focus:border-blue-500 transition duration-300"
            name="note"
            onChange={handleChange}
            defaultValue={formData?.note}
            placeholder="Please Enter Your Note!"
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full py-3 bg-blue-600 text-white rounded-md font-semibold hover:bg-blue-700 transition duration-300"
        >
          Update Note
        </button>
      </form>
    </div>
  );
};

export default EditMypayment;
