import { Icon } from "@iconify/react";
import React, { useState, useEffect } from "react";
import AuthUser from "../../../Hooks/authUser";
import Pagination from "../../../Shared/Pagination";
import AgentUpdatePaymentModal from "../../../Shared/AgentUpdatePaymentModal";
import AgentAllUserTable from "./AgentAllUserTable";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AgentAllUser = () => {
  const { userInfo } = AuthUser();
  const [filterUser, setFilterUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(50);

  useEffect(() => {
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/user/specific?fieldName=referralId&&fieldValue=${
        userInfo?._id
      }&&size=${size}&&page=${page}`
    )
      .then((res) => res.json())
      .then((data) => {
        setUser(data?.data);
        setQuantity(data?.total);
      });
  }, [page, size, userInfo?._id, refetch]);

  useEffect(() => {
    handleSearch();
  }, [searchTerm, user]);

  const handleSearch = () => {
    const results = user?.filter((data) => {
      return (
        data.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.phone === searchTerm
      );
    });
    setFilterUser(results);
  };

  return (
    <div className="min-h-screen m-5">
      <div className="mt-3">
        <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
          All User List
        </h1>
        <p className="mt-1.5 text-sm text-gray-500">
          Efficiently update user profiles, including personal information,
          contact details! 🚀
        </p>
      </div>

      <div className="flex relative rounded-md w-full my-5">
        <input
          type="text"
          placeholder="Enter Reseller Name, Email, or Phone"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
        />
        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-primary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
        >
          <span>Search</span>
          <span className="hidden md:block">
            <Icon icon="material-symbols:search" />
          </span>
        </button>
      </div>

      <div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
            <thead className="bg-secondary shadow-sm text-white">
              <tr>
                <th scope="col" className=" py-2 px-6 text-sm font-medium  bg-primary">
                  S.No
                </th>
                {["User Name", "Email", "Mobile Balance", "Riccarica Balance", "Cargo Balance", "Status", "Action"].map((header) => (
                  <th
                    key={header}
                    scope="col"
                    className=" py-2 px-6 text-sm font-medium  bg-primary"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-50 text-center">
              {user.length > 0 ? (
                [...filterUser]?.reverse()?.map((u, index) => (
                  <AgentAllUserTable
                    key={u?._id}
                    u={u}
                    index={index}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    refetch={refetch}
                    setRefetch={setRefetch}
                    setData={setData}
                  />
                ))
              ) : (
                Array.from({ length: 10 }).map((_, index) => (
                  <tr key={index} className="shadow">
                    <td className="h-16 px-6 text-sm">
                      <Skeleton />
                    </td>
                    <td className="h-16 px-6 text-sm">
                      <Skeleton />
                    </td>
                    <td className="h-16 px-6 text-sm">
                      <Skeleton />
                    </td>
                    <td className="h-16 px-6 text-sm">
                      <Skeleton />
                    </td>
                    <td className="h-16 px-6 text-sm">
                      <Skeleton />
                    </td>
                    <td className="h-16 px-6 text-sm">
                      <Skeleton />
                    </td>
                    <td className="h-16 px-6 text-sm">
                      <Skeleton />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      
      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
      
      <AgentUpdatePaymentModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        user={data}
        setRefetch={setRefetch}
        refetch={refetch}
      />
    </div>
  );
};

export default AgentAllUser;
