import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Dropdown, initTE } from "tw-elements";
import AuthUser from "../../../Hooks/authUser";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function AgentWebNavBar() {
  const { logout, userInfo } = AuthUser();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initTE({ Collapse, Dropdown });
  }, []);

  useEffect(() => {
    fetchUserData();
    const interval = setInterval(() => {
      fetchUserData();
    }, 5000); // Adjust the interval as needed

    return () => {
      clearInterval(interval);
    };
  }, [userInfo._id]);

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        `https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`
      );
      const data = await response.json();
      setUser(data?.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <nav
      className="sticky top-0 z-50 flex w-full flex-nowrap items-center justify-between lg:text-black text-white text-[18px] lg:flex-wrap lg:justify-start lg:shadow-md bg-primary lg:py-0 py-4"
      data-te-navbar-ref
    >
      <div className="flex w-full flex-wrap items-center justify-between">
        <div className="flex lg:hidden justify-between items-center w-full px-5">
          <Link to="/" className="w-20">
            <p>Wallet</p>
          </Link>
          <button
            className="block border-0 bg-transparent px-2 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
            type="button"
            data-te-collapse-init
            data-te-target="#navbarSupportedContent8"
            aria-controls="navbarSupportedContent8"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="[&>svg]:w-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                className="h-7 w-7"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </button>
        </div>
        <div className="flex justify-center items-center gap-10">
          <div>
            <Link
              to={`/${userInfo?.role}Dashboard`}
              className="pl-5 hidden lg:block text-white font-bold"
            >
              <p>Wallet</p>
            </Link>
          </div>
          <div className="flex justify-center items-center gap-5 lg:pl-28 text-white text-xs font-bold">
            {loading ? (
              <div className="flex gap-5">
                <Skeleton width={50} height={20} />
                <Skeleton width={50} height={20} />
                <Skeleton width={50} height={20} />
              </div>
            ) : (
              <>
                <p>B- ৳ {user?.mobileBalance?.toFixed(2)}</p>
                <p>R- € {user?.riccaricaBalance?.toFixed(2)}</p>
                <p>C- € {user?.cargoBalance?.toFixed(2)}</p>
              </>
            )}
          </div>
        </div>
        <div
          className="!visible hidden flex-grow basis-[100%] items-center justify-center lg:mt-0 lg:!flex lg:basis-auto"
          id="navbarSupportedContent8"
          data-te-collapse-item
        >
          <ul
            className="list-style-none flex text-left flex-col lg:gap-7 gap-5 pl-0 lg:flex-row relative w-full flex-nowrap items-center justify-between py-2 text-white text-[16px] lg:flex-wrap lg:justify-end lg:py-4 lg:pr-5"
            data-te-navbar-nav-ref
          >
            <li className="border-b-2 hover:border-white transition-all duration-500 border-primary">
              <Link to={`/${userInfo?.role}Dashboard`}>Dashboard</Link>
            </li>
            <li className="border-b-2 border-primary hover:border-white transition-all duration-500">
              <Link to="/mobileBanking">Mobile Banking</Link>
            </li>
            <li className="border-b-2 border-primary hover:border-white transition-all duration-500">
              <Link to="/riccarica">Ricarica</Link>
            </li>
            <li className="border-b-2 border-primary hover:border-white transition-all duration-500">
              <Link to="/cargo">Cargo</Link>
            </li>
            {userInfo?.role === "user" && (
              <li>
                <Link
                  className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal hover:bg-primary hover:text-white"
                  to={`/${userInfo?.role}Dashboard/myPayments`}
                  data-te-dropdown-item-ref
                >
                  My Payments
                </Link>
              </li>
            )}
            {userInfo?.role === "agent" && (
              <li
                className="pl-2 lg:mb-0 lg:pl-0 lg:pr-1"
                data-te-nav-item-ref
                data-te-dropdown-ref
              >
                <a
                  className="flex items-center"
                  href="/"
                  type="button"
                  id="dropdownMenuButton2"
                  data-te-dropdown-toggle-ref
                  aria-expanded="false"
                >
                  Users
                  <span className="ml-1 w-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </a>
                <ul
                  className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg text-black font-medium [&[data-te-dropdown-show]]:block"
                  aria-labelledby="dropdownMenuButton2"
                  data-te-dropdown-menu-ref
                >
                  <li>
                    <Link
                      className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal hover:bg-primary hover:text-white"
                      to={`/${userInfo?.role}Dashboard/add-user`}
                      data-te-dropdown-item-ref
                    >
                      Add User
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal hover:bg-primary hover:text-white"
                      to={`/${userInfo?.role}Dashboard/all-user`}
                      data-te-dropdown-item-ref
                    >
                      All User
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal hover:bg-primary hover:text-white"
                      to={`/${userInfo?.role}Dashboard/myPayments`}
                      data-te-dropdown-item-ref
                    >
                      My Payments
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal hover:bg-primary hover:text-white"
                      to="/agentDashboard/payment"
                      data-te-dropdown-item-ref
                    >
                      Payments
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            <li
              className="pl-2 lg:mb-0 lg:pl-0"
              data-te-nav-item-ref
              data-te-dropdown-ref
            >
              <a
                className="flex items-center"
                href="/"
                type="button"
                id="dropdownMenuButton2"
                data-te-dropdown-toggle-ref
                aria-expanded="false"
              >
                <Icon className="text-2xl" icon="carbon:user-profile" />
              </a>
              <ul
                className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg text-black font-medium right-0 [&[data-te-dropdown-show]]:block"
                aria-labelledby="dropdownMenuButton2"
                data-te-dropdown-menu-ref
              >
                <li>
                  <p
                    className="block w-full whitespace-nowrap bg-transparent px-4 cursor-pointer py-2 text-sm font-normal hover:bg-primary hover:text-white"
                    data-te-dropdown-item-ref
                  >
                    {userInfo?.name}
                  </p>
                </li>
                <li>
                  <Link
                    className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal hover:bg-primary hover:text-white"
                    to="changePassword"
                    data-te-dropdown-item-ref
                  >
                    Change Password
                  </Link>
                </li>
                <li onClick={logout}>
                  <p
                    className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal cursor-pointer hover:bg-primary hover:text-white"
                    data-te-dropdown-item-ref
                  >
                    LogOut
                  </p>
                </li>
              </ul>
            </li>
            <li className="border-b-2 border-primary hover:border-white transition-all duration-500">
              {userInfo?.userName}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default AgentWebNavBar;
