import React, { useState } from "react";
import AuthUser from "../../../Hooks/authUser";
import CreateUserHook from "../../../Hooks/createUserHook";
import { singleImageUpload } from "../../../Hooks/ImageUpload";

const AdminAddAgent = () => {
  const { userInfo } = AuthUser();
  const [contactPaper, setContactPaper] = useState("");
  const [documentFile, setDocumentFile] = useState("");
  const [visuraFiles, setVisuraFiles] = useState("");
  const [formData, setFormData] = useState({
    additionalInformation: "",
    password: "",
    userName: "",
    pceId: "",
    companyName: "",
    name: "",
    phone: "",
    email: "",
    codiceFiscale: "",
    pivaNumber: "",
    codiceUnivoco: "",
    address: "",
    city: "",
    country: "",
    role: "agent",
    status: "active",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      ...formData,
      contactPaper,
      documentFile,
      visuraFiles,
      referralId: userInfo?._id,
      referralName: userInfo?.name,
      referralPhone: userInfo?.phone,
      referralUserName: userInfo?.userName,
      confirmPassword: formData?.password,
    };
    await CreateUserHook(data);
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    if (event?.target?.name === "contactPaper") {
      singleImageUpload(formData, setContactPaper);
    }
    if (event?.target?.name === "visuraFiles") {
      singleImageUpload(formData, setVisuraFiles);
    }
    if (event?.target?.name === "documentFile") {
      singleImageUpload(formData, setDocumentFile);
    }
  };

  // set data in state
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className=" min-h-screen  text-black ">
      <section className="">
        <form
          onSubmit={handleSubmit}
          className="container flex flex-col mx-auto space-y-12   rounded-md"
        >
          <fieldset className="grid  grid-cols-4 gap-6  rounded-md  dark:bg-gray-900">
            <div className="space-y-2 col-span-full lg:col-span-1">
              <p className="font-medium">Personal Inormation</p>
              <p className="text-xs">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Adipisci fuga autem eum!
              </p>
            </div>
            <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
              <div className="col-span-full sm:col-span-3">
                <label for="firstname" className="text-sm">
                  Company/Shop Name
                </label>
                <input
                  name="companyName"
                  type="text"
                  onChange={handleChange}
                  required
                  placeholder="Enter Your Company or Shop Name"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for="lastname" className="text-sm">
                  Customer Name
                </label>
                <input
                  type="text"
                  required
                  name="name"
                  onChange={handleChange}
                  placeholder="Enter Your User Full Name"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for="number" className="text-sm">
                  Phone Number
                </label>
                <input
                  type="number"
                  required
                  name="phone"
                  onChange={handleChange}
                  placeholder="Enter Your User Phone Number"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for="firstname" className="text-sm">
                  E-mail
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  required
                  placeholder="Enter Your User E-mail"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for="firstname" className="text-sm">
                  Codice Fiscale
                </label>
                <input
                  type="text"
                  name="codiceFiscale"
                  onChange={handleChange}
                  placeholder="Enter Your User Codice Fiscale"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for="firstname" className="text-sm">
                  P.IVA Number
                </label>
                <input
                  type="number"
                  name="pivaNumber"
                  onChange={handleChange}
                  placeholder="Enter Your User P.IVA Number
                  "
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for="firstname" className="text-sm">
                  Pec ID
                </label>
                <input
                  type="text"
                  required
                  name="pceId"
                  onChange={handleChange}
                  placeholder="Enter Your User Pec Id"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for="firstname" className="text-sm">
                  Codice Univoco
                </label>
                <input
                  type="text"
                  name="codiceUnivoco"
                  onChange={handleChange}
                  placeholder="Enter Your User Codice Univoco

                  "
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full">
                <label for="address" className="text-sm">
                  Address
                </label>
                <input
                  name="address"
                  onChange={handleChange}
                  type="text"
                  required
                  placeholder="Enter Your User Address"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-2">
                <label for="city" className="text-sm">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  onChange={handleChange}
                  placeholder="Enter Your User City"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-2">
                <label for="state" className="text-sm">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  onChange={handleChange}
                  placeholder="Enter Your User Country"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for="firstname" className="text-sm">
                  Contact Paper
                </label>
                <input
                  type="file"
                  name="contactPaper"
                  onChange={handleChangeUploadImage}
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for="firstname" className="text-sm">
                  Document File
                </label>
                <input
                  type="file"
                  name="documentFile"
                  onChange={handleChangeUploadImage}
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for="firstname" className="text-sm">
                  Visura Files
                </label>
                <input
                  type="file"
                  name="visuraFiles"
                  onChange={handleChangeUploadImage}
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 border-2 p-2"
                />
              </div>{" "}
              <div className="col-span-full sm:col-span-3">
                <label htmlFor="status" className="text-sm">
                  Status
                </label>
                <select
                  name="status"
                  onChange={handleChange}
                  className="w-full rounded-md focus:ring focus:ri focus:ri bg-white border-2 p-2"
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>
          </fieldset>
          <fieldset className="grid grid-cols-4 gap-6 p-6 rounded-md shadow-sm dark:bg-gray-900">
            <div className="space-y-2 col-span-full lg:col-span-1">
              <p className="font-medium">Profile</p>
              <p className="text-xs">Provide a user profile Information!</p>
            </div>
            <div className="grid grid-cols-6 gap-4 col-span-full lg:col-span-3">
              <div className="col-span-full sm:col-span-3">
                <label for="username" className="text-sm">
                  Username
                </label>
                <input
                  id="userName"
                  type="text"
                  name="userName"
                  onChange={handleChange}
                  required
                  placeholder="Username"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 p-2 border-2"
                />
              </div>
              <div className="col-span-full sm:col-span-3">
                <label for="Password" className="text-sm">
                  Password
                </label>
                <input
                  type="Password"
                  name="password"
                  onChange={handleChange}
                  required
                  placeholder="Enter Your User Password"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 p-2 border-2"
                />
              </div>
              <div className="col-span-full">
                <label for="Additional Information" className="text-sm">
                  Additional Information
                </label>
                <textarea
                  name="additionalInformation"
                  onChange={handleChange}
                  placeholder="Enter Your User Additional Information"
                  className="w-full rounded-md focus:ring focus:ri focus:ri dark:border-gray-700 dark:text-gray-900 p-3 border-2"
                ></textarea>
              </div>
            </div>
          </fieldset>
          <div className=" flex  justify-end items-center">
            <button
              type="submit"
              className="px-10 py-3 text-white bg-primary hover:bg-primary  max-w-lg rounded-md "
            >
              Create Agent
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default AdminAddAgent;
