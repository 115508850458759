import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { server_url } from "../Config/API";
import AuthUser from "../Hooks/authUser";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";

const AgentBdRechargeModal = ({
  open,
  setOpen,
  formData,
  total,
  onSuccess,
}) => {
  let date = moment().format("YYYY-MM-D");
  const { userInfo } = AuthUser();
  const [pin, setPin] = useState("");
  const [myData, setMyData] = useState([]);
  const [exchangeRate, setExchangeRate] = useState([]);

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setMyData(data?.data));
  }, [userInfo?._id]);

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/exchangeRate/getExchangeRate`)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data) {
          setExchangeRate(data?.data[0]);
        }
      });
  }, []);

  const handelUpdate = async () => {
    if (myData?.pin === pin) {
      if (total <= myData?.riccaricaBalance) {
        await fetch(`${server_url}/riccaricaOrder/addRiccaricaOrder`, {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ordererUserName: userInfo?.userName,
            ordererId: userInfo?._id,
            referralId: userInfo?.referralId,
            referralUserName: userInfo?.referralUserName,
            ordererPhone: userInfo?.phone,
            receiverMobileNumber: formData?.receiverMobileNumber,
            packageType: formData?.packageType,
            note: formData?.note,
            amountEuro: Number(
              formData?.amountBdt * exchangeRate?.euroExchangeRate
            ),
            serviceChargeEuro: Number(formData?.serviceCharge),
            totalAmountEuro: total,
            date,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "success") {
              fetch(`${server_url}/user/${myData?._id}`, {
                method: "PATCH",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  riccaricaBalance: Number(myData?.riccaricaBalance) - total,
                }),
              })
                .then((res) => res.json())
                .then((data) => {
                  if (data?.status === "success") {
                    toast?.success("User Balance Updated !");
                    setOpen(false);
                    onSuccess();
                  }
                });
            }
          });
      } else {
        toast?.error("you don't have sufficient amount");
      }
    } else {
      toast?.error("Your pin was wrong!");
    }
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative  z-50"
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-sm z-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto z-50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="lg:w-5/12 md:8/12 w-11/12 mx-auto transform overflow-hidden rounded-md bg-white text-left align-middle  alig shadow-xl transition-all">
                <div className="bg-primary/10 py-2 flex justify-between items-center px-5">
                  <h2 className="text-lg font-semibold">Recharge</h2>
                  <button
                    onClick={() => setOpen(false)}
                    className="bg-white shadow-lg shadow-secondary/50 hover:scale-105 duration-300 px-2 text-secondary rounded-md"
                  >
                    X
                  </button>
                </div>

                <div className="border m-5 p-2 rounded-lg grid grid-cols-2 gap-2">
                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Package Type</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      {formData?.packageType}
                    </p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Recipient Number</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      {formData?.receiverMobileNumber}
                    </p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Amount</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      {formData?.amountBdt} BDT
                    </p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Service Charge</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      €{" "}
                      {formData?.serviceCharge
                        ? formData?.serviceCharge
                        : "N/A"}
                    </p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Total</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">€ {total.toFixed(2)}</p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm">Your Note</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <p className="text-sm text-primary">
                      {formData?.note ? formData?.note : "N/A"}
                    </p>
                  </div>

                  <div className="border-r border-b p-1">
                    <p className="font-semibold text-sm mt-1">Password</p>
                  </div>
                  <div className="border-r border-b p-1">
                    <input
                      name="mobileBalance"
                      type="password"
                      onChange={(e) => setPin(e.target?.value)}
                      required
                      placeholder="Enter Your Password"
                      className="w-full rounded-md  border border-gray-300 px-2 py-1  focus:outline-none"
                    />
                  </div>

                  <button
                    onClick={handelUpdate}
                    className="bg-primary w-fit my-2 py-2 px-4 rounded-md text-white  font-semibold hover:scale-105 duration-300"
                  >
                    Send Request !
                  </button>
                </div>
                <div className="border-t h-6 bg-primary/10"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AgentBdRechargeModal;
