import React from "react";
import Login from "../Login/Login";

const LandingPage = () => {
  return (
    <div>
      <Login></Login>{" "}
    </div>
  );
};

export default LandingPage;
