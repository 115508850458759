import flatpickr from "flatpickr";
import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../Shared/Loading";
import { useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import { startOfMonth } from "date-fns";

const AdminBkashReports = () => {
  const { id } = useParams();


  // console.log(id)
  // useEffect(() => {
  //   flatpickr("#datepicker", {
  //     // Configuration options for Flatpickr
  //     // You can customize the appearance and behavior here
  //   });
  // }, []);

  const [orders, setOrders] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterOrders, setFilterOrders] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date()); 


  const [totals, setTotals] = useState(0); 
  const [totalAmount,setTotalAmount] = useState(0);
  const [totalCommission,settotalcommission] = useState (0);
  const [quantity, setQuantity] = useState(0);


  const [selectedMonthTotalAmount, setSelectedMonthTotalAmount] = useState(0);
  const [selectedMonthTotalOrders, setSelectedMonthTotalOrders] = useState(0);
  
  const [
    selectedMonthTotalTransectionFee,
    setselectedMonthTotalTransectionFee,
  ] = useState(0);


  useEffect(() => {
    setLoading(true);
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/mobileBankingOrder/getMobileBankingOrder`
    ).then((res) =>
      res.json().then((data) => {
        setQuantity(data?.total);
  
        const confirmOrders = data?.data.filter((order) => {
          return order.status === 'confirmed' && order.ordererId === id;
        });
        const totalQuantity = confirmOrders.length;
        setQuantity(totalQuantity);
           const total = confirmOrders.reduce((acc, order) => acc + order.totalAmount, 0);
           setTotals(total);


           const totalAmounts = confirmOrders.reduce((acc, order) => acc + order.amount, 0);
           setTotalAmount(totalAmounts);

          const totalCommissions = confirmOrders.reduce((acc, order) => acc + order.commission, 0);
          settotalcommission(totalCommissions);
  
        setOrders(confirmOrders);
        setFilterOrders(confirmOrders);
        setLoading(false);
      })
    );
  }, [ refetch, id]);

  const filterOrdersByDate = () => {
    if (selectedMonth) {
      const results = orders?.filter((data) => {
        const orderDate = new Date(data.date);
        return (
          orderDate.getMonth() === selectedMonth.getMonth() && 
          orderDate.getFullYear() === selectedMonth.getFullYear()
        );
      });
      // Calculate total amount and total orders for the selected month
    const totalAmount = results.reduce((acc, order) => acc + order.amount, 0);
    const totalOrders = results.length;
    const totaltransectionFee = results.reduce(
      (acc, order) => acc + order.commission,
      0
    );

    // Update state with the calculated values
    setSelectedMonthTotalAmount(totalAmount);
    setSelectedMonthTotalOrders(totalOrders);
    setselectedMonthTotalTransectionFee(totaltransectionFee);

      setFilterOrders(results);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div>
  <div class="h-auto p-3 rounded-lg bg-gray-200 mb-5">  
  <div className="max-w-md mx-auto lg:flex items-center justify-center border-2  gap-5 border-bkashprimary p-5 shadow-md">

<div className=" mx-auto  text-center">
  <label htmlFor="month">
    <p className="text-bkashprimary font-medium">
      Select Month <span className="text-red-500">*</span>
    </p>
    <DatePicker
      selected={selectedMonth}
      onChange={(date) => setSelectedMonth(date)}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      className="w-full  px-10 py-2 mt-1 bg-white border-2 border-bkashprimary outline-none cursor-pointer p-2 rounded"
      id="month"
    />
  </label>
</div>

<div className="  text-center">

<p className="text-bkashprimary font-medium">
  Filter Now <span className="text-red-500">*</span>
    </p>
  <button
    className="bg-bkashprimary   px-10  w-full text-white  text-xl py-2 rounded-md"
    onClick={filterOrdersByDate}
  >
    Search
  </button>
</div>
</div>


<div className=" lg:flex justify-center items-center gap-5 mt-3">
          <p>Total Sales: ৳ {totalAmount}</p>
          <p className=" ">||</p>
          <p>Total Transaction Fee: ৳ {totalCommission}</p>
          <p className=" ">||</p>
          <p>Commission with Sales & Transaction Fee : ৳ {totals.toFixed(2)}</p>
          <p className=" ">||</p>

          <p>Total Order : ৳ {quantity}</p>
        </div>

</div>
 

<div className="text-center mb-5 lg:flex gap-5 justify-center items-center">
        <p className="text-bkashprimary font-medium">
          Total Amount for Selected Month: ৳ {selectedMonthTotalAmount}
        </p>
        <p className=" ">||</p>

        <p className="text-bkashprimary font-medium">
          Total Transaction Fee for Selected Month: ৳{" "}
          {selectedMonthTotalTransectionFee}
        </p>
        <p className=" ">||</p>
        <p className="text-bkashprimary font-medium">
          Total Orders for Selected Month: {selectedMonthTotalOrders}
        </p>
      </div>


      <div className="overflow-x-auto">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-xs">
            <thead className="text-left bg-bkashprimary text-white">
              <tr>
                <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Code
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Date
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Entry By
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Payment Method
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                 Service Type
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Customer
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Recipient
                </th>
             
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Amount
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Commission
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Total
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Trx
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Status
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {[...filterOrders]?.reverse()?.map((order, i) => (
                <tr key={order?._id} order={order} className="odd:bg-gray-50">
                  <td
                    key={order?._id}
                    order={order}
                    className="whitespace-nowrap px-4 py-2 font-medium text-gray-900"
                  >
                    {i + 1}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?._id}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.date}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.ordererUserName}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.paymentMethod}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.serviceType}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.customerName}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.recipientNumber}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.amount}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.commission}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.totalAmount}
                  </td>

                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {order?.trx}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    <button className="bg-bkashprimary px-2 text-white pb-1 rounded-full">
                      {" "}
                      {order?.status}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      {/* ... Your table and pagination components ... */}
    </div>
    </div>
  );
};

export default AdminBkashReports;
