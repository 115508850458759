import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Contact.css";

const Contact = () => {
  const [option, setOption] = useState();

  //OnSubmit Form handler
  const handleForm = (event) => {
    event.preventDefault();
    const form = event.target;
    const name = form.name.value;
    const email = form.email.value;
    const subject = form.subject.value;
    const message = form.message.value;
  };
  return (
    <div className="lg:w-[90%] mx-auto">
      <div className="w-full md:p-14 p-5 grid grid-cols-1 lg:grid-cols-2 gap-5 ">
        <div className="py-10 px-7 rounded-md">
          <div className="px-5 lg:px-1 ">
            <div className="font-bold">
              <h3 className="contact-header uppercase pb-4 underline underline-offset-8 decoration-2 ">
                Hade Office
              </h3>
              <p className="uppercase">Genzam-it </p>
              <p>Rome, Italy</p>
              <p>Phone: +39 351178 9888</p>
              <p>E-mail: genzamofficial@gmail.com</p>
            </div>
            &nbsp;
            <p className="text-justify">
              {" "}
              Join our digital product transfer business and unlock new
              opportunities to trade digital goods. By partnering with us, you
              can become a multi-service provider, offering a wide range of
              digital products to your customers. Whether it's mobile top-ups,
              online gift cards, or a variety of digital services, you can
              expand your offerings and provide valuable solutions to your
              clientele. Join us today and be a part of the thriving digital
              product transfer industry.
            </p>
          </div>
        </div>

        <div className="md:py-10 px-7 rounded-md">
         

          <div className="p-8 form-border">
            <form onSubmit={handleForm}>
              {/* {/ name  /} */}
              <fieldset className="my-2">
                <label className=" text-primary">Name</label>
                <input
                  type="text"
                  name="name"
                  className="w-full py-2 my-2 px-2 input_style"
                  placeholder="Your Name ..."
                  required
                />
              </fieldset>

              {/* {/ email  /} */}
              <fieldset className="my-2">
                <label className="text-primary">Email</label>
                <input
                  type="email"
                  name="email"
                  className="w-full py-2 my-2 px-2 input_style"
                  placeholder="Your Email ..."
                  required
                />
              </fieldset>

              {/* {/ subject  /} */}
              <fieldset className="my-2">
                <label className="text-primary">Subject</label>
                <input
                  type="text"
                  name="subject"
                  className="w-full py-2 my-2 px-2 input_style"
                  placeholder="Subject ..."
                  required
                />
              </fieldset>

              {/* {/ message  /} */}
              <fieldset className="my-2">
                <label className="text-primary">Message</label>
                <textarea
                  name="message"
                  id=""
                  cols="10"
                  rows="5"
                  className="input_style w-full py-2 my-2 px-2 h-[136px]"
                  placeholder="Your Message ..."
                  required
                ></textarea>
              </fieldset>

              {/* {/ button  /} */}

              <button type="submit" className="button w-full bg-primary">
                <div className="flex justify-center items-center py-2">
                  {/* <img src={icon} alt="" className='w-5 mr-2' /> */}
                  <Link
                    to={""}
                    className=" mr-2 text-white text-xl font-semibold"
                  >
                    {/* <FiSend></FiSend> */}
                  </Link>
                  <button type="submit" className="text-white text-xl font-semibold">Send</button>
                </div>
              </button>
            </form>
          </div>

    
        </div>
      </div>
    </div>
    
  );
};

export default Contact;
