import React from "react";

const MobileBankingPaymnetList = () => {
  return (
    <div>
      {/*
  Heads up! 👋

  This component comes with some `rtl` classes. Please remove them if they are not needed in your project.
*/}

<nav aria-label="Breadcrumb" className="flex">
  <ol
    className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600"
  >
    <li className="flex items-center">
      <a
        href="#"
        className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>

        <span className="ms-1.5 text-xs font-medium"> Home </span>
      </a>
    </li>

    <li className="relative flex items-center">
      <span
        className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"
      >
      </span>

      <a
        href="#"
        className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900"
      >
        Payment List
      </a>
    </li>
  </ol>
</nav>

      <div className="mt-5 bg-white shadow-2xl p-5">
      <div className=" flex justify-between mb-3">
        <h1 className=" text-2xl">Payment List</h1>
        <button className=" bg-bkashprimary p-2 text-white rounded-md">
          New Request
        </button>
      </div>
      
      <div className=" w-full">
      <div class="flex flex-1 items-center justify-center p-6">
    <div class="w-full ">
        <form class="mt-3 sm:flex sm:items-center">
            <input id="q" name="q" class="inline w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" placeholder="Keyword" type="search" autofocus="" value=""></input>
            <button type="submit" class="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent    bg-bkashprimary px-4 py-2 font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-bkashprimary focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Search</button>
        </form>
    </div>
</div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="text-left bg-bkashprimary text-white">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Code</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Date</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Service
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">Type</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
              Received/Return	
              </th>
            
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Entry By
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
              Paid To	
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Amount
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium">
                Status
              </th>
          
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            <tr className="odd:bg-gray-50">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                1
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                OT1691740564
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                11.08.2023
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                bKash
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              2023-06-01	
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              operator
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                01963553376
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                osthirstore1250
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
              13100.00	
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700">
            Complete
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};

export default MobileBankingPaymnetList;
