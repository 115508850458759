
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../../../Shared/Loading";
import saveAs from "file-saver";

const CargoConfirmOrder = () => {
  const [loading, setLoading] = useState(false);
  const [cargoOrders, setCargoOrders] = useState([]);
  const [refetch, setRefetch] = useState(false);
  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(50);

  // Filter criteria
  const [searchCriteria, setSearchCriteria] = useState({
    customerPhone: "",
    receiverPhone: "",
    entryBy: "",
  });

  useEffect(() => {
    setLoading(true);
    // Construct the API endpoint URL with the filter criteria
    const apiUrl = `https://walletserver.genzamwallet.com/api/v1/cargoOrder/getCargoOrder?size=${size}&page=${page}`;

    // Fetch data from the API
    fetch(apiUrl)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Network response was not ok (${res.status})`);
        }
        return res.json();
      })
      .then((data) => {
        // Filter and set cargoOrders with "pending" status
        const pendingCargoOrders = data?.data.filter(
          (cargoOrder) => cargoOrder.status === "confirmed"
        );
        setCargoOrders(pendingCargoOrders);
        setQuantity(pendingCargoOrders.length); // Update quantity based on filtered data
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [refetch, size, page]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prevCriteria) => ({
      ...prevCriteria,
      [name]: value,
    }));
  };

  const filteredCargoOrders = cargoOrders.filter((cargoOrder) => {
    return (
      cargoOrder.senderInfo.phone.includes(searchCriteria.customerPhone) &&
      cargoOrder.receiverInfo.phone.includes(searchCriteria.receiverPhone) &&
      cargoOrder.ordererUserName.includes(searchCriteria.entryBy)
    );
  });
  return (
    <div className=" p-5">
      <div className=" lg:flex  justify-center items-center gap-5 mb-5 bg-white shadow-md p-5">
        <div className="w-full">
          <input
            type="search"
            className="border border-black outline-none w-full p-1 rounded-md"
            placeholder="Customer Phone"
            name="customerPhone"
            value={searchCriteria.customerPhone}
            onChange={handleInputChange}
          />
        </div>

        <div className="w-full">
          <input
            type="search"
            className="border border-black outline-none w-full p-1 rounded-md"
            placeholder="Receiver Phone"
            name="receiverPhone"
            value={searchCriteria.receiverPhone}
            onChange={handleInputChange}
          />
        </div>
      
      </div>
      {loading && <p><Loading></Loading></p>}

      <div class="overflow-x-auto  rounded-lg border border-gray-200">
        <table class="min-w-full divide-y-2 divide-gray-200 bg-white text-xs">
          <thead class="text-left bg-[#34495E] text-white">
            <tr>
              <th class="whitespace-nowrap border px-4 py-2 font-medium ">#</th>
              <th class="whitespace-nowrap border px-4 py-2 font-medium ">
                Ric No.
              </th>
              <th class="whitespace-nowrap  border px-4 py-2 font-medium ">
                Type
              </th>
              <th class="whitespace-nowrap  border px-4 py-2 font-medium ">
                Date
              </th>
              <th class="whitespace-nowrap border px-4 py-2 font-medium ">
                Label Print
              </th>

              <th class="whitespace-nowrap border px-4 py-2 font-medium ">
                Customer
              </th>

              <th class="whitespace-nowrap border px-4 py-2 font-medium ">
                Total{" "}
              </th>

              <th class="whitespace-nowrap border px-4 py-2 font-medium ">
                Commission
              </th>

              <th class="whitespace-nowrap border px-4 py-2 font-medium ">
                Entry By{" "}
              </th>
              <th class="whitespace-nowrap border px-4 py-2 font-medium ">
                Action{" "}
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {filteredCargoOrders.map((cargoOrder, index) => (
              <tr key={index}>
                <td className="whitespace-nowrap px-4 border py-2 font-medium text-gray-900">
                  {index + 1}
                </td>
                <td className="whitespace-nowrap px-4 border py-2 font-medium text-gray-900">
                  {cargoOrder._id}
                </td>
                <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                  {cargoOrder.name}
                </td>
                <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                  {cargoOrder.date}
                </td>
                <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                <Icon className="cursor-pointer text-2xl" onClick={() => saveAs(cargoOrder?.label)} icon="flat-color-icons:print"></Icon>
                </td>
                <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                  {cargoOrder.senderInfo.firstName}{" "}
                  {cargoOrder.senderInfo.lastName}
                </td>
                <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                  € {cargoOrder.orderDetails.total}
                </td>
                <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                  € {cargoOrder.orderDetails.totalAgentCommission}
                </td>
                <td className="whitespace-nowrap px-4 border py-2 text-gray-700">
                  {cargoOrder.ordererUserName}
                </td>
                <td className="whitespace-nowrap px-4 border py-2 text-gray-700 flex gap-5 text-2xl">
                 

                  <Link to={`/cargo/cargo-order-view/${cargoOrder._id}`}>
                    <Icon icon="carbon:view"></Icon>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CargoConfirmOrder;
