import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { server_url } from "../../../Config/API";
import Pagination from "../../../Shared/Pagination";
import AuthUser from "../../../Hooks/authUser";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";

const AgentMRechargeList = () => {
  const { userInfo } = AuthUser();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [orders, setOrders] = useState([]);
  const [filterOrders, setFilterOrders] = useState([]);

  useEffect(() => {
    fetch(
      `${server_url}/riccaricaOrder/specific/?size=${size}&&page=${page}&&fieldName=${"ordererId"}&&fieldValue=${
        userInfo?._id
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        setQuantity(data?.total);
        setOrders(data?.data);
        setFilterOrders(data?.data);
      });
  }, [page, size, userInfo?._id]);

  useEffect(() => {
    handleSearch();
  }, [search, status, orders]);

  const handleSearch = () => {
    let results = orders;

    if (search) {
      results = results.filter((data) => {
        return (
          (data._id && data._id.toLowerCase().includes(search.toLowerCase())) ||
          (data.ordererUserName &&
            data.ordererUserName
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (data.receiverMobileNumber &&
            data.receiverMobileNumber
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          (data.totalAmount && data.totalAmount.toString().includes(search)) ||
          (data.trackingId &&
            data.trackingId.toLowerCase().includes(search.toLowerCase()))
        );
      });
    }

    if (status) {
      results = results.filter((data) => data.status === status);
    }

    setFilterOrders(results);
  };

  return (
    <div>
      <nav aria-label="Breadcrumb" className="flex mb-5">
        <ol className="flex overflow-hidden rounded-lg border border-gray-200 text-gray-600">
          <li className="flex items-center">
            <a
              href="#"
              className="flex h-10 items-center gap-1.5 bg-gray-100 px-4 transition hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>

              <span className="ms-1.5 text-xs font-medium"> Home </span>
            </a>
          </li>

          <li className="relative flex items-center">
            <span className="absolute inset-y-0 -start-px h-10 w-4 bg-gray-100 [clip-path:_polygon(0_0,_0%_100%,_100%_50%)] rtl:rotate-180"></span>

            <a
              href="#"
              className="flex h-10 items-center bg-white pe-4 ps-8 text-xs font-medium transition hover:text-gray-900"
            >
              Mobile Recharge List
            </a>
          </li>
        </ol>
      </nav>
      <div className="lg:flex justify-center items-center border p-5 rounded-md bg-ricaricabg gap-5 shadow-lg">
        <div className="w-full mt-2">
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="w-full border p-3 rounded-md focus:outline-none focus:ring-2  focus:border-transparent"
            placeholder="Tracking-ID, Phone Number"
            type="search"
          />
        </div>

        <div className="flex mt-2 justify-between w-full items-center gap-5">
          <div className="w-full">
            <select
              onChange={(e) => setStatus(e.target.value)}
              className="w-full p-3 bg-white border rounded-md focus:outline-none focus:ring-2  focus:border-transparent"
            >
              <option disabled value="">
                Select Status
              </option>
              <option value="confirmed">Completed</option>
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>
          <button
            onClick={handleSearch}
            className="bg-white  text-gray-700 p-3 rounded-md px-7 hover:bg-primary-dark transition duration-300"
          >
            Search
          </button>
        </div>
      </div>

      <div className="mt-5 bg-white ">
        <div className="flex flex-col lg:flex-row justify-between items-center mb-6 ">
          <h1 className="text-xl font-bold text-center text-white bg-gradient-to-r from-[#008C44] to-[#00B74A] p-2 rounded-md mb-4 lg:mb-0">
            Mobile Recharge Order List
          </h1>
          <div className="flex gap-4">
            <Link
              className="group relative inline-flex items-center overflow-hidden rounded bg-gradient-to-r from-[#008C44] to-[#00B74A] px-8 py-3 text-white focus:outline-none   active:bg-[#006F35] transition-all duration-300"
              to="/riccarica/agent-bd-recharge"
            >
              <span className="absolute -left-full transition-all group-hover:left-4">
                <svg
                  className="h-5 w-5 rtl:rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </span>
              <span className="text-xs transition-all group-hover:ml-4">
                New Recharge
              </span>
            </Link>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-xs">
            <thead className="text-left bg-[#008C44] text-white">
              <tr>
                <th className="whitespace-nowrap px-4 py-2 font-medium">#</th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Tracking-ID
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Date
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Entry By
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Receiver Number
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Package
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Amount
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Service Charge
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Commission
                </th>

                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Total Amount
                </th>
                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Status
                </th>

                <th className="whitespace-nowrap px-4 py-2 font-medium">
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {orders.length === 0
                ? [...Array(10).keys()].map((index) => (
                    <tr key={index} className="odd:bg-gray-50">
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>{" "}
                      <td className="whitespace-nowrap px-4 py-2">
                        <Skeleton />
                      </td>
                    </tr>
                  ))
                : filterOrders
                    ?.slice()
                    .reverse()
                    ?.map((order, i) => (
                      <tr
                        key={order?._id}
                        order={order}
                        className="odd:bg-gray-50 "
                      >
                        <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                          {i + 1}
                        </td>
                        <td
                          className="whitespace-nowrap px-4 py-2 text-gray-700 cursor-pointer"
                          title="Click to Copy"
                        >
                          <span
                            className="text-blue-500 hover:underline"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(order?.trackingId)
                                .then(() => {
                                  toast.success("Copied to clipboard");
                                });
                            }}
                          >
                            {order?.trackingId}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {order?.date}
                        </td>
                        <td
                          className="whitespace-nowrap px-4 py-2 text-gray-700 cursor-pointer"
                          title={order?.ordererUserName}
                        >
                          <span className="hover:text-blue-500">
                            {order?.ordererUserName?.slice(0, 10)}
                          </span>
                        </td>
                        <td
                          className="whitespace-nowrap px-4 py-2 text-gray-700 cursor-pointer"
                          title="Click to Copy"
                        >
                          <span
                            className="text-blue-500 hover:underline"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(order?.receiverMobileNumber)
                                .then(() => {
                                  toast.success("Copied to clipboard");
                                });
                            }}
                          >
                            {order?.receiverMobileNumber}
                          </span>
                        </td>

                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          {order?.packageType}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          € {order?.amountEuro.toFixed(2)}
                        </td>

                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          €{" "}
                          {order?.serviceChargeEuro
                            ? order?.serviceChargeEuro.toFixed(2)
                            : "N/A"}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          €{" "}
                          {order?.commissionEuro
                            ? order?.commissionEuro
                            : "N/A"}
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          €{order?.totalAmountEuro.toFixed(2)}
                        </td>

                        <td className="whitespace-nowrap px-2 text-xs py-2 text-gray-700">
                          <p
                            className={`${
                              order?.status === "pending"
                                ? "bg-yellow-500"
                                : order?.status === "confirmed"
                                ? "bg-green-600"
                                : order?.status === "rejected"
                                ? "bg-red-500"
                                : ""
                            } rounded-md px-2 text-center text-white py-1 uppercase text-xs`}
                          >
                            {order?.status}
                          </p>
                        </td>
                        <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                          <Link to={`recharge-invoice/${order._id}`}>
                            <Icon
                              className="text-center text-2xl"
                              icon="flat-color-icons:print"
                            />
                          </Link>
                        </td>
                      </tr>
                    ))}
            </tbody>
          </table>
        </div>
      </div>

      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

export default AgentMRechargeList;
