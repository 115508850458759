import React from "react";
import { toast } from "react-toastify";

const AgentRechargeReportTable = ({ order, i }) => {
  return (
    <tr className="odd:bg-gray-50">
      <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
        {i + 1}
      </td>
      <td
        className="whitespace-nowrap px-4 py-2 text-blue-500 hover:underline cursor-pointer"
        onClick={() => {
          navigator.clipboard.writeText(order?.trackingId);
          toast.success("Copied to clipboard");
        }}
      >
        {order?.trackingId}
      </td>
      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
        {order?.date}
      </td>
      <td className="whitespace-nowrap px-4 py-2 text-gray-700 hover:text-blue-500  cursor-pointer hover:whitespace-normal">
        <span title={order?.ordererUserName}>
          {order?.ordererUserName?.slice(0, 10)}...
        </span>
      </td>
      <td
        className="whitespace-nowrap px-4 py-2 text-blue-500 hover:underline cursor-pointer"
        onClick={() => {
          navigator.clipboard.writeText(order?.receiverMobileNumber);
          toast.success("Copied to clipboard");
        }}
      >
        {order?.receiverMobileNumber}
      </td>
      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
        {order?.packageType}
      </td>
      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
        € {order?.amountEuro.toFixed(2)}
      </td>

      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
        €
        {order?.serviceChargeEuro ? order?.serviceChargeEuro.toFixed(2) : "N/A"}
      </td>

      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
        € {order?.commissionEuro ? order?.commissionEuro : "N/A"}
      </td>
      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
        €{order?.totalAmountEuro.toFixed(2)}
      </td>

      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
        <p
          className={`${
            order?.status === "pending"
              ? "bg-yellow-500"
              : order?.status === "confirmed"
              ? "bg-green-600"
              : order?.status === "rejected"
              ? "bg-red-500"
              : ""
          } rounded-md px-2 text-center text-white py-1 uppercase text-xs`}
        >
          {order?.status}
        </p>
      </td>
    </tr>
  );
};

export default AgentRechargeReportTable;
