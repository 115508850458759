import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AuthUser from "../../Hooks/authUser";
import DeleteHook from "../../Hooks/DeleteHook";
import { Link } from "react-router-dom";
import Pagination from "../../Shared/Pagination";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  AreaChart,
  CalendarDays,
  Captions,
  FileSearch,
  UsersRound,
} from "lucide-react";

const PaymentReport = ({ setShow }) => {
  const { userInfo } = AuthUser();
  const [refetch, setRefetch] = useState(false);
  const [payment, setPayment] = useState([]);
  const [filterPayment, setFilterPayment] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(50);
  const [loading, setLoading] = useState(true);

  const data = ["Bkash", "Riccarica", "Cargo"];
  const data2 = ["complete", "pending", "return"];

  const [formData, setFormData] = useState({
    userName: "",
    serviceName: "",
    status: "",
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/myPayment/specific?fieldName=agentId&fieldValue=${userInfo?._id}&size=${size}&page=${page}`
    )
      .then((res) => res.json())
      .then((data) => {
        setPayment(data?.data);
        setFilterPayment(data?.data);
        setQuantity(data?.total);
        setLoading(false);
      });
  }, [page, size, userInfo?._id, refetch]);

  const handleSearch = (e) => {
    e.preventDefault();
    const results = payment?.filter((data) => {
      return (
        (formData?.userName ? data?.userName === formData?.userName : true) &&
        (formData?.serviceName
          ? data?.serviceName === formData?.serviceName
          : true) &&
        (formData?.status ? data?.status === formData?.status : true) &&
        (formData?.fromDate && formData?.toDate
          ? moment(data?.date).isBetween(formData?.fromDate, formData?.toDate)
          : true)
      );
    });
    setFilterPayment(results);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="my-10">
      <div>
        <form onSubmit={handleSearch}>
          <div className="flex  justify-center items-center gap-3">
            <div className="flex gap-1 bg-primary">
              <label className="py-2 flex gap-1 items-center pl-4 w-56   text-white">
                <UsersRound />
                User
              </label>
              <input
                name="userName"
                onChange={handleChange}
                type="text"
                placeholder="Enter Your User Name"
                className="border-primary border w-full px-3 outline-none  focus:border-primary"
              />
            </div>

            <div className="flex  bg-primary">
              <label className="py-2 flex gap-1 items-center pl-4 w-56 rounded  text-white">
                <Captions />
                Service
              </label>
              <select
                name="serviceName"
                onChange={handleChange}
                className="border border-primary w-full px-3 outline-none"
              >
                <option value="" selected>
                  Select
                </option>
                {data?.map((cat, index) => (
                  <option key={index} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-4 my-5 md:my-10 items-center justify-center">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
              <div className="flex gap-5  justify-between items-center bg-primary">
                <label className=" px-3 py-2 flex gap-1 items-center text-white font-semibold">
                  <CalendarDays />
                  From Date
                </label>
                <input
                  name="fromDate"
                  onChange={handleChange}
                  type="date"
                  className="border px-3 py-2  outline-none focus:border-blue-500 transition duration-300"
                />
              </div>

              <div className="flex   justify-between gap-5 items-center bg-primary ">
                <label className=" flex gap-1 items-center px-3 py-2 text-white font-semibold">
                  <CalendarDays /> To Date
                </label>
                <input
                  name="toDate"
                  onChange={handleChange}
                  type="date"
                  className="border px-3 py-2 outline-none focus:border-blue-500 transition duration-300"
                />
              </div>

              <div className="flex justify-between bg-primary gap-5 items-center">
                <label className="  flex items-center gap-1 px-6 py-2 text-white font-semibold">
                  <AreaChart />
                  Status
                </label>
                <select
                  name="status"
                  onChange={handleChange}
                  className="border px-3 py-[0.6rem] outline-none focus:border-blue-500 transition duration-300"
                >
                  <option value="" selected>
                    Select Any
                  </option>
                  {data2?.map((cat, index) => (
                    <option key={index} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button
              type="submit"
              className="bg-primary text-white px-3 py-[0.6rem] flex  items-center gap-3  shadow-md hover:bg-primary-hover transition duration-300  md:mt-0"
            >
              <FileSearch />
              Search
            </button>
          </div>
        </form>

        <div className="overflow-x-auto rounded-lg border border-gray-200">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
            <thead className="ltr:text-left bg-primary rtl:text-right text-white">
              <tr>
                <th className="whitespace-nowrap text-start px-4 py-3 font-semibold ">
                  #
                </th>
                <th className="whitespace-nowrap text-start px-4 py-3 font-semibold ">
                  Payment Date
                </th>
                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                  Agent
                </th>
                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                  Service Name
                </th>
                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                  Status
                </th>
                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                  Amount
                </th>
                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                  Notes
                </th>
                <th className="whitespace-nowrap px-4 text-start py-3 font-semibold ">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {loading
                ? [...Array(10)].map((_, i) => (
                    <tr key={i}>
                      <td className="whitespace-nowrap px-4 py-4">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-4 py-4">
                        <Skeleton />
                      </td>
                    </tr>
                  ))
                : [...filterPayment]?.reverse()?.map((data, index) => (
                    <tr key={data?._id}>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        <p>{1 + index}</p>
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        <p>{data?.date}</p>
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        {userInfo?.name}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        {data?.serviceName}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        <button
                          className={`px-3 py-[5px] text-center rounded ${
                            data?.status === "Complete"
                              ? "bg-green-600"
                              : data?.status === "Pending"
                              ? "bg-yellow-600"
                              : "bg-red-600"
                          } text-white font-semibold`}
                        >
                          {data?.status}
                        </button>
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        {data?.amount}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        {data?.note}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-gray-700">
                        <Link
                          to={`/agentDashboard/editPayment/${data?._id}`}
                          className="px-3 mr-3 py-[5px] text-center rounded bg-blue-600 text-white font-semibold"
                        >
                          Edit
                        </Link>
                        <button
                          onClick={() => {
                            DeleteHook({
                              refetch,
                              setRefetch,
                              url: `https://walletserver.genzamwallet.com/api/v1/myPayment/deleteMyPayment/${data?._id}`,
                            });
                          }}
                          className="px-3 mr-3 py-[5px] text-center rounded bg-red-600 text-white font-semibold"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

PaymentReport.propTypes = {
  setShow: PropTypes.func.isRequired,
};

export default PaymentReport;
