import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import AuthUser from "../../Hooks/authUser";
import { server_url } from "../../Config/API";
import Loading from "../../Shared/Loading";
import MyPaymentTable from "./MyPaymentTable";
import Pagination from "../../Shared/Pagination";

const MyPayments = () => {
  const { userInfo } = AuthUser();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const form = moment(fromDate);
  const to = moment(toDate);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [orders, setOrders] = useState([]);
  const [filterOrders, setFilterOrders] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetch(
      `${server_url}/myPayment/getMyPayment/?size=${size}&&page=${page}&&fieldName=${"recipientId"}&&fieldValue=${userInfo?._id}`
    ).then((res) =>
      res.json().then((data) => {
        setQuantity(data?.total);
        setOrders(data?.data);
        setFilterOrders(data?.data);
        setLoading(false);
      })
    );
  }, [page, size, userInfo?._id]);

  const handleSearch = () => {
    const results = orders?.filter((data) => {
      return (
        (to.isAfter(data?.date) && form.isBefore(data?.date))
      );
    });
    setFilterOrders(results);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="w-11/12 mx-auto min-h-screen">
      <div className=" lg:flex justify-center items-center gap-5 rounded-md border p-5 mt-5">
        <div className="">
          <label className="text-lg font-semibold">From Date</label> <br />
          <input
            onChange={(e) => setFromDate(e?.target?.value)}
            className="border-2 border-gray-300 rounded px-3 py-2 w-56"
            type="date"
            placeholder="Select a date"
          />
        </div>
        <div className="">
          <label className="text-lg font-semibold">To Date</label> <br />
          <input
            type="date"
            onChange={(e) => setToDate(e?.target?.value)}
            className="border-2 border-gray-300 rounded px-3 py-2 w-56"
            placeholder="Select a date"
          />
        </div>
        <div className="">
          <label className="text-lg font-semibold"></label> <br />
          <button
            onClick={handleSearch}
            className="bg-primary text-white rounded px-3 py-2 w-44 hover:scale-105 duration-300"
            placeholder="Select a date"
          >
            Search History
          </button>
        </div>
      </div>
      <div className=" mt-5">
        <div>
          <div className=" flex justify-between  mb-3">
            <h1 className=" text-2xl">
              My Payment List (Total Transaction: {quantity})
            </h1>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
              <thead className="text-left bg-primary text-white">
                <tr>
                  <th className="whitespace-nowrap border px-4 py-2 font-medium">#</th>
                  <th className="whitespace-nowrap border px-4 py-2 font-medium">Date</th>
                  <th className="whitespace-nowrap border px-4 py-2 font-medium">Receiver UserName</th>
                  <th className="whitespace-nowrap border px-4 py-2 font-medium">Receiver Number</th>
                  <th className="whitespace-nowrap border px-4 py-2 font-medium">Service Type</th>
                  <th className="whitespace-nowrap border  px-4 py-2 font-medium">Amount</th>
                  <th className="whitespace-nowrap border  px-4 py-2 font-medium">Status</th>
                  <th className="whitespace-nowrap border px-4 py-2 font-medium">Note</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filterOrders?.reverse()?.map((order, i) => (
                  <MyPaymentTable i={i} key={order?._id} order={order} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

export default MyPayments;
