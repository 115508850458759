import CargoHome from "../Component/Cargo/CargoHome";
import CargoNewRequest from "../Component/Cargo/CargoNewRequest/CargoNewRequest";
import CargoOrderView from "../Component/Cargo/CargoOrderView";

const CargoRoutes =[
    { path: "overview", Component: CargoHome },
    { path: "cargo-new-request", Component: CargoNewRequest },
    {
        path:"cargo-order-view/:id",Component:CargoOrderView
    }

]

export default CargoRoutes;