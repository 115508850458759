import { Icon } from "@iconify/react";
import React, { useState, useEffect, useCallback } from "react";
import AuthUser from "../../../Hooks/authUser";
import Skeleton from "react-loading-skeleton";
import UserMobileUserSalesTable from "./UserMobileUserSalesTable";
import Pagination from "../../../Shared/Pagination";

const UserSalesList = () => {
  const { userInfo } = AuthUser();
  const [filterUser, setFilterUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [user, setUser] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // for pagination
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(50);

  useEffect(() => {
    fetch(
      `https://walletserver.genzamwallet.com/api/v1/user/specific?fieldName=${"referralId"}&&fieldValue=${userInfo?._id}&&size=${size}&&page=${page}`
    ).then((res) =>
      res.json().then((data) => {
        setUser(data?.data);
        setQuantity(data?.total);
      })
    );
  }, [page, size, userInfo?._id]);

  const handleSearch = useCallback(() => {
    const results = user?.filter((data) => {
      return (
        data.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.phone === searchTerm
      );
    });
    setFilterUser(results);
  }, [user, searchTerm]);

  useEffect(() => {
    handleSearch();
  }, [searchTerm, user, handleSearch]);

  return (
    <div>
      <div>
        <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
          All User Sales List
        </h1>
        <p className="mt-1.5 text-xs text-gray-500">
          Efficiently check user sales reports, including personal information 🚀
        </p>
      </div>

      <div className="flex relative rounded-md w-full my-5">
        <input
          type="text"
          placeholder="Enter Reseller Name, Email, or Phone"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 rounded-md border border-r-white rounded-r-none border-gray-300 focus:outline-none"
        />
        <button
          onClick={handleSearch}
          className="inline-flex items-center gap-2 bg-bkashprimary text-white text-lg font-semibold py-3 px-6 rounded-r-md hover:bg-secondary/90"
        >
          <span>Search</span>
          <span className="hidden md:block">
            <Icon icon="material-symbols:search"></Icon>
          </span>
        </button>
      </div>

      <div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-xs">
            <thead className="ltr:text-left rtl:text-righ bg-bkashprimary shadow-sm text-white">
              <tr>
                <th scope="col" className="py-2 text-xs font-medium">User Name</th>
                <th scope="col" className="text-xs font-medium">Email</th>
                <th scope="col" className="text-xs font-medium">Mobile Balance</th>
                <th scope="col" className="text-xs font-medium">Riccarica Balance</th>
                <th scope="col" className="text-xs font-medium">Cargo Balance</th>
                <th scope="col" className="text-xs font-medium">Status</th>
                <th scope="col" className="text-xs font-medium">Action</th>
              </tr>
            </thead>

            <tbody className="divide-y text-left divide-gray-200">
              {user.length === 0
                ? Array.from({ length: 10 }).map((_, index) => (
                    <tr key={index} className="shadow">
                      <td className="px-4 py-2"><Skeleton  /></td>
                      <td className="px-4 py-2"><Skeleton  /></td>
                      <td className="px-4 py-2"><Skeleton  /></td>
                      <td className="px-4 py-2"><Skeleton /></td>
                      <td className="px-4 py-2"><Skeleton  /></td>
                      <td className="px-4 py-2"><Skeleton /></td>
                      <td className="px-4 py-2"><Skeleton  /></td>
                    </tr>
                  ))
                : filterUser.reverse().map((u) => (
                    <UserMobileUserSalesTable
                      key={u?._id}
                      u={u}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      refetch={refetch}
                      setRefetch={setRefetch}
                    />
                  ))}
            </tbody>
          </table>
        </div>
      </div>

      <Pagination
        quantity={quantity}
        page={page}
        setPage={setPage}
        size={size}
        setSize={setSize}
      />
    </div>
  );
};

export default UserSalesList;
