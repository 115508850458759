import { Icon } from "@iconify/react";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthUser from "../../../Hooks/authUser";
import { FaHistory, FaListUl } from "react-icons/fa";
import { TbRecharging } from "react-icons/tb";
import { MdLogout, MdAccountBalance } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AgentRiccaricaSideBar = () => {
  const { userInfo, logout } = AuthUser();
  const [user, setUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetch(`https://walletserver.genzamwallet.com/api/v1/user/${userInfo?._id}`)
      .then((res) => res.json())
      .then((data) => setUser(data?.data))
      .catch((error) => console.error("Error fetching user data:", error));
  }, [userInfo?._id]);

  const isActive = (path) => location.pathname.includes(path);

  return (
    <div className="">
      <div className="flex h-screen w-48 flex-col justify-between border-e bg-[#008C44]">
        <div>
          <div className="grid grid-cols-1 h-16 w-full items-center justify-center p-3 mb-2">
            <div>
              <h1 className="text-white">
                {user ? (
                  `${user?.userName?.slice(0, 15)}...`
                ) : (
                  <Skeleton width={80} />
                )}
              </h1>
            </div>
            <div className="flex items-center text-white">
              <MdAccountBalance />
              <p className="text-white font-semibold mx-1">€</p>
              <p className="text-white">
                {user ? (
                  user?.riccaricaBalance?.toFixed(2)
                ) : (
                  <Skeleton width={50} />
                )}
              </p>
            </div>
          </div>
          <div className="border-t border-gray-100">
            <div className="px-2">
              <div className="py-4">
                <Link
                  to="overview"
                  className="group relative flex justify-start items-center gap-2 rounded px-2 py-1.5 text-white"
                >
                  <Icon icon="ic:baseline-dashboard" />
                  <p>Dashboard</p>
                </Link>
              </div>
              <ul className="space-y-1 border-t border-gray-100 pt-4">
                <li>
                  <Link
                    to="agent-bd-recharge"
                    className={`group relative flex justify-start items-center gap-2 rounded px-2 py-1.5 ${
                      isActive("agent-bd-recharge")
                        ? "bg-white text-gray-700"
                        : "text-white hover:bg-white hover:text-gray-700"
                    }`}
                  >
                    <Icon icon="ri:chat-new-fill" />
                    <p className="text-sm">Bangladesh Recharge</p>
                  </Link>
                </li>
                <li>
                  <Link
                    to="agent-MRecharge"
                    className={`group relative flex justify-start items-center gap-1 rounded px-2 py-1.5 ${
                      isActive("agent-MRecharge")
                        ? "bg-white text-gray-700"
                        : "text-white hover:bg-white hover:text-gray-700"
                    }`}
                  >
                    <TbRecharging />
                    <p className="text-sm">Mobile Recharge List</p>
                  </Link>
                </li>
                {userInfo?.role === "agent" && (
                  <li>
                    <Link
                      to="agent-user-MRecharge"
                      className={`group relative flex justify-start items-center gap-1 rounded px-2 py-1.5 ${
                        isActive("agent-user-MRecharge")
                          ? "bg-white text-gray-700"
                          : "text-white hover:bg-white hover:text-gray-700"
                      }`}
                    >
                      <FaListUl />
                      <p className="text-sm">User M-Recharge List</p>
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    to="agent-recharge-reports"
                    className={`group relative flex justify-start items-center gap-2 rounded px-2 py-1.5 ${
                      isActive("agent-recharge-reports")
                        ? "bg-white text-gray-700"
                        : "text-white hover:bg-white hover:text-gray-700"
                    }`}
                  >
                    <FaHistory />
                    <p>Report</p>
                  </Link>
                </li>
                {userInfo?.role === "agent" && (
                  <li>
                    <Link
                      to="riccarica-user-sales-list"
                      className={`group relative flex justify-start items-center gap-2 rounded px-2 py-1.5 ${
                        isActive("riccarica-user-sales-list")
                          ? "bg-white text-gray-700"
                          : "text-white hover:bg-white hover:text-gray-700"
                      }`}
                    >
                      <FaHistory />
                      <p className="text-sm">User Report</p>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="sticky inset-x-0 bottom-0 border-t border-gray-100 bg-[#008C44] p-2">
          <button
            onClick={logout}
            className="group relative flex w-full justify-start rounded-lg px-2 py-1.5 text-sm text-white hover:bg-gray-50 hover:text-gray-700"
          >
            <MdLogout className="h-5 w-5" />
            <p className="ml-2">Logout</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentRiccaricaSideBar;
