import React from 'react';
import CargoOrderList from './CargoOrderList';

const CargoHome = () => {
    return (
        <div>
     <CargoOrderList></CargoOrderList>    
        </div>
    );
};

export default CargoHome;