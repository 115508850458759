import React, { useEffect } from 'react';

const InternationalOrderDetails = ({data,internationalDetails,setInternationalDetails}) => {
    let number = [];
    for(let i = 1;i<=100;i++){
number.push(i)
}
const handleChange = (e) => {
    setInternationalDetails({
      ...internationalDetails,
      [e.target.name]: e.target.value,
    });
  };
const total = Number(internationalDetails?.weight?internationalDetails?.weight:0) * Number(data?.chargePerKg?data?.chargePerKg:0) + Number(data?.charge?data?.charge:0);

const totalAgentCommission = Number(internationalDetails?.weight?internationalDetails?.weight:0) * Number(data?.agentCommission
?data?.agentCommission:0) 

const totalWithDeliveryCharge = Number(internationalDetails?.weight?internationalDetails?.weight:0) * Number(data?.deliveryCharge
?data?.deliveryCharge:0) + total;

const totalAmount = Number(totalWithDeliveryCharge)-Number(totalAgentCommission)+Number(internationalDetails?.serviceCharge?internationalDetails?.serviceCharge:0)

useEffect(()=>{
    setInternationalDetails({
        ...internationalDetails,
        total: totalAmount,
        totalAgentCommission: totalAgentCommission,
        totalWithDeliveryCharge: totalWithDeliveryCharge,
        charge:data?.charge,
        perKg:data?.chargePerKg,
      });
},[total,totalAgentCommission,totalWithDeliveryCharge,totalAmount])

    return (
        <div>
            <h2 className='text-xl font-bold mt-5 mb-4'>Order Details</h2>
            <div className="w-full overflow-x-auto ">
                <table
                    className="w-full text-left rounded w-overflow-x-auto "
                    cellspacing="0"
                >
                    <tbody>
                        <tr>
                            <th
                                scope="col"
                                colSpan={1}
                                className="h-16 border border-slate-300 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-200"
                            >
                               Box
                            </th>

                            <th
                                scope="col"
                                colSpan={5}
                                className="h-16 border border-slate-300 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-200"
                            >
                                Product Discription
                            </th>
                            <th
                                scope="col"
                                colSpan={2}
                                className="h-16 border border-slate-300 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-200"
                            >
                                weight
                            </th>
                            <th
                                scope="col"
                                colSpan={2}
                                className="h-16 border border-slate-300 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-200"
                            >
                                Per (kg)
                            </th>
                            <th
                                scope="col"
                                colSpan={2}
                                className="h-16 border border-slate-300 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-200"
                            >
                                Charge
                            </th>
                            <th
                                scope="col"
                                colSpan={2}
                                className="h-16 border border-slate-300 px-6 text-sm font-medium stroke-slate-700 text-slate-700 bg-slate-200"
                            >
                                Total
                            </th>
                        </tr>


                        <tr className="">
                            <td colSpan={1} className="h-20 border border-slate-300 px-6 text-sm transition duration-300  border-slate-300-slate-200 stroke-slate-500 text-slate-500">
                                <select onChange={handleChange} name="box" className='border border-slate-300 rounded-md focus:outline-none py-3 px-3'>
                                    {number?.map(n=><option value={n}>{n}</option>)}
                                </select>
                            </td>
                            <td colSpan={5} className="h-20 border border-slate-300 px-6 text-sm transition duration-300  border-slate-300-slate-200 stroke-slate-500 text-slate-500">
                                <textarea onChange={handleChange} name="productDescription" rows={2} className='focus:outline-none w-full border border-slate-300 rounded-md p-1' />
                            </td>
                            <td colSpan={2} className="h-20 border border-slate-300 px-6 text-sm transition duration-300  border-slate-300-slate-200 stroke-slate-500 text-slate-500">
                                <input onChange={handleChange} name='weight' className='w-full border border-slate-300 focus:outline-none h-12 rounded-md p-1' type='number'></input>
                            </td>
                            <td colSpan={2} className="h-20 border border-slate-300 px-6 text-sm transition duration-300  border-slate-300-slate-200 stroke-slate-500 text-slate-500">
                                <input onChange={handleChange} value={data?.chargePerKg} disabled name='perKg' className='w-full border border-slate-300 focus:outline-none h-12 rounded-md p-1' type='number'></input>
                            </td>

                            <td colSpan={2} className="h-20 border border-slate-300 px-6 text-sm transition duration-300  border-slate-300-slate-200 stroke-slate-500 text-slate-500">
                                <input onChange={handleChange} value={data?.charge} disabled name='charge' className='w-full border border-slate-300 focus:outline-none h-12 rounded-md p-1' type='number'></input>
                            </td>

                            <td colSpan={2} className="h-20 border border-slate-300 px-6 text-sm transition duration-300  border-slate-300-slate-200 stroke-slate-500 text-slate-500">
                                <input value={total} onChange={handleChange} disabled name='total' className='w-full border border-slate-300 focus:outline-none h-12 rounded-md p-1' type='number'></input>
                            </td>

                        </tr>
                        <tr className="">
                            <td colSpan={6} className="h-20 border border-slate-300 px-6 text-sm transition duration-300  border-slate-300-slate-200 stroke-slate-500 text-slate-500">
                                <input onChange={handleChange} placeholder='service charge' name='serviceCharge' className='w-full border border-slate-300 focus:outline-none h-12 rounded-md p-1' type='number'></input>
                            </td>
                            <td colSpan={6} className="h-20 border border-slate-300 px-6 text-sm transition duration-300 font-semibold text-right  border-slate-300-slate-200 stroke-slate-500 text-slate-500">
                                <p>Total Agent Commission :</p>
                                <p>Total with home delevary charge :</p>
                                <p className='text-green-500'>Total Amount :</p>
                            </td>


                            <td colSpan={2} className="h-20 border border-slate-300 px-6 text-sm transition duration-300 font-semibold  border-slate-300-slate-200 stroke-slate-500 text-slate-500">
                                <p>{totalAgentCommission}</p>
                                <p>{totalWithDeliveryCharge}</p>
                                <p>{totalWithDeliveryCharge}</p>
                            </td>

                        </tr>

                    </tbody>
                </table>
            </div>

            <div>
                <h2 className='text-lg font-semibold my-2'>Order Note</h2>
                <textarea name="note" onChange={handleChange} placeholder='type your order note' className='focus:outline-none border border-slate-300 rounded-md w-full p-2' rows={4} />
            </div>
        </div>
    );
};

export default InternationalOrderDetails;