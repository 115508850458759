import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import Loading from "../../Shared/Loading";

const CargoOrderView = () => {
  const { id } = useParams();
  const [cargoOrder, setCargoOrder] = useState([]);

  useEffect(() => {
    // Fetch cargo order data based on the `id` parameter
    fetch(`https://walletserver.genzamwallet.com/api/v1/cargoOrder/getCargoOrderById/${id}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`API request failed with status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setCargoOrder(data.data); // Set cargo order data in state
      })
      .catch((error) => {
        console.error("Error fetching cargo order:", error);
        // Handle the error as needed, e.g., show an error message.
      });
  }, [id]);

  if (cargoOrder === null) {
    return <div><Loading></Loading></div>;
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Cargo Order Details</h2>
      <div className="bg-white shadow-lg rounded-lg p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="text-xl font-semibold">Order Information</div>
          {/* <Link to={`/cargo-orders/${cargoOrder._id}/edit`} className="text-blue-500 hover:underline">
            Edit Order
          </Link> */}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">Order ID: {cargoOrder._id}</p>

            <p className="text-gray-600">Type: {cargoOrder?.name}</p>

            <p className="text-gray-600">
              Entry By: {cargoOrder?.ordererUserName}
            </p>
          </div>
          <div>
            <p className="text-gray-600">Order Date: {cargoOrder.date}</p>
            
          </div>
        </div>
      </div>

      <div className="mt-6 bg-white shadow-lg rounded-lg p-6">
        <div className="text-xl font-semibold mb-4">Sender Information</div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">
              Customer Name: {cargoOrder?.senderInfo?.firstName}{" "}
              {cargoOrder?.senderInfo?.lastName}
            </p>

            <p className="text-gray-600">
              City: {cargoOrder?.senderInfo?.city}
            </p>

            <p className="text-gray-600">
              E-mail : {cargoOrder?.senderInfo?.email}
            </p>

            <p className="text-gray-600">
              State : {cargoOrder?.senderInfo?.state}
            </p>

            <p className="text-gray-600">
              Address : {cargoOrder?.senderInfo?.address}
            </p>
          </div>
          <div>
            <p className="text-gray-600">
              Phone: {cargoOrder?.senderInfo?.phone}
            </p>

            <p className="text-gray-600">
              documentNo : {cargoOrder?.senderInfo?.documentNo}
            </p>

            <p className="text-gray-600">
              Codice Fiscale: {cargoOrder?.senderInfo?.codiceFiscale}
            </p>

            <p className="text-gray-600">
              Post Code : {cargoOrder?.senderInfo?.postCode}
            </p>
          </div>
          {/* Add more customer information fields as needed */}
        </div>
      </div>

      <div className="mt-6 bg-white shadow-lg rounded-lg p-6">
        <div className="text-xl font-semibold mb-4">Receiver Information</div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">
              Customer Name: {cargoOrder?.receiverInfo?.firstName}{" "}
              {cargoOrder?.receiverInfo?.lastName}
            </p>
            <p className="text-gray-600">
              City: {cargoOrder?.receiverInfo?.city}
            </p>

            <p className="text-gray-600">
              E-mail : {cargoOrder?.receiverInfo?.email}
            </p>

            <p className="text-gray-600">
              Address : {cargoOrder?.receiverInfo?.address}
            </p>
          </div>
          <div>
            <p className="text-gray-600">
              Phone: {cargoOrder?.receiverInfo?.phone}
            </p>

            <p className="text-gray-600">
              documentNo : {cargoOrder?.receiverInfo?.documentNo}
            </p>

            <p className="text-gray-600">
              Country : {cargoOrder?.receiverInfo?.country}
            </p>

            <p className="text-gray-600">
              Post Code : {cargoOrder?.receiverInfo?.postCode}
            </p>
          </div>
          {/* Add more customer information fields as needed */}
        </div>
      </div>

      <div className="mt-6 bg-white shadow-lg rounded-lg p-6">
        <div className="text-xl font-semibold mb-4">Order Details Information</div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">
            Product Description: {cargoOrder?.orderDetails?.productDescription}
           
            </p>
            <p className="text-gray-600">
            Quantity: {cargoOrder?.orderDetails?.quantity}
            </p>

            <p className="text-gray-600">
            Charge : {cargoOrder?.orderDetails?.charge}
            </p>

            <p className="text-gray-600">
            Total : {cargoOrder?.orderDetails?.total}
            </p>
          </div>
          <div>
            <p className="text-gray-600">
            Service Charge: {cargoOrder?.orderDetails?.serviceCharge}
            </p>

            <p className="text-gray-600">
            Total Commission : {cargoOrder?.orderDetails?.totalAgentCommission}
            </p>

            <p className="text-gray-600">
            Total With Delivery Charge : {cargoOrder?.orderDetails?.totalWithDeliveryCharge}
            </p>

            <p className="text-gray-600">
            Note : {cargoOrder?.orderDetails?.note}
            </p>
          </div>
          {/* Add more customer information fields as needed */}
        </div>
      </div>
    </div>
  );
};

export default CargoOrderView;
