import React from 'react';
import { Link } from 'react-router-dom';

const CargoNavbar = () => {
    return (
        <div>
            <nav class="bg-gray-800 text-white py-3 px-4 flex items-center justify-between">
    <a class="font-bold text-xl tracking-tight" href="#">Cargo</a>
    <div class="flex items-center">
        <Link class="text-sm px-4 py-2 leading-none rounded-full hover:bg-gray-700" to="cargo-new-request">New Request</Link>
        <Link class="text-sm px-4 py-2 leading-none rounded-full hover:bg-gray-700" to="/cargo">Cargo OrderList</Link>
    </div>
</nav>
            
        </div>
    );
};

export default CargoNavbar;