import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import DeleteHook from "../../../Hooks/DeleteHook";

const AdminBkashOrderTable = ({ i, order, refetch, setRefetch }) => {
  return (
    <tr className="shadow">
      <td className="h-12 px-4 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {i + 1}
      </td>

      <td className="h-12 px-4 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {order?.date}
      </td>
      <td className="h-12 px-4 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {order?.paymentMethod}
      </td>
      <td className="h-12 px-4 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {order?.serviceType}
      </td>

      <td className="h-12 px-4 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {order?.recipientNumber}
      </td>
      <td className="h-12 px-4 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {order?.ordererUserName}
      </td>
      <td className="h-12 px-4 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {order?.referralUserName}
      </td>
      <td className="h-12 px-4 text-xs transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
        {order?.amount} BDT
      </td>

      <td className="h-12 px-4 transition duration-300 border-slate-200 text-secondary text-sm flex gap-1 items-center cursor-pointer">
        <Link
          to={`${order?._id}`}
          className="border border-secondary py-1 px-2 rounded-md hover:bg-secondary/10 duration-300"
        >
          <Icon icon="carbon:view" />
        </Link>

        {/* <button
          onClick={() => {
            DeleteHook({
              refetch,
              setRefetch,
              url: `https://walletserver.genzamwallet.com/api/v1/mobileBankingOrder/deleteMobileBankingOrder/${order?._id}`,
            });
          }}
          aria-label="Open delete confirmation"
          className="border border-secondary py-1 px-2 rounded-md hover:bg-secondary/10 duration-300"
        >
          <Icon icon="material-symbols:delete-outline" />
        </button> */}
      </td>
    </tr>
  );
};

export default AdminBkashOrderTable;
