import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { server_url } from "../../Config/API";
import UpdateHooks from "../../Hooks/UpdateHooks";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ChevronLeft, SendHorizontal } from "lucide-react";

const PaymentReportEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [note, setNote] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    fetch(`${server_url}/myPayment/getMyPaymentById/${id}`).then((res) =>
      res.json().then((data) => {
        setData(data?.data);
      })
    );
  }, [id]);

  const data2 = ["complete", "pending", "return"];

  const handelUpdate = () => {
    UpdateHooks(
      `${server_url}/myPayment/updateMyPayment/${id}`,
      {
        note: note ? note : data?.note,
        status: status ? status : data?.status,
      },
      "Report Updated!"
    );
  };

  return (
    <div className="bg-white shadow-md rounded-lg md:w-1/2 mx-auto mb-10 p-2 mt-5">
      <div className="text-xl text-center py-10">
        <h1 className="font-semibold">Edit Payment Report</h1>
        <p className="text-sm">
          Edit the payment report details including payment date, user name,
          service name, status, amount, and notes.
        </p>
      </div>
      <div>
        <h2 className="text-lg font-semibold mb-2 text-gray-700">Payment Details</h2>
        <div className="flex flex-col gap-1 text-md">
          <div className="flex items-center">
            <label className="py-1 pr-2 w-36 text-gray-700 font-semibold">Payment Date</label>
            <div className="border w-full px-2 py-1 rounded bg-gray-50">
              {data?.date ? data.date : <Skeleton width="100%" />}
            </div>
          </div>
          <div className="flex items-center">
            <label className="py-1 pr-2 w-36 text-gray-700 font-semibold">User Name</label>
            <div className="border w-full px-2 py-1 rounded bg-gray-50">
              {data?.recipientUserName ? data.recipientUserName : <Skeleton width="100%" />}
            </div>
          </div>
          <div className="flex items-center">
            <label className="py-1 pr-2 w-36 text-gray-700 font-semibold">Service Name</label>
            <div className="border w-full px-2 py-1 rounded bg-gray-50">
              {data?.serviceName ? data.serviceName : <Skeleton width="100%" />}
            </div>
          </div>
          <div className="flex items-center">
            <label className="py-1 pr-2 w-36 text-gray-700 font-semibold">Status</label>
            <select
              onChange={(e) => setStatus(e.target.value)}
              id="condition"
              className="border w-full px-2 py-1 rounded bg-gray-50"
              defaultValue={data?.status || ""}
              value={status || data?.status}
            >
              <option value="" disabled>
                Select Any
              </option>
              {data2.map((cat, index) => (
                <option key={index} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center">
            <label className="py-1 pr-2 w-36 text-gray-700 font-semibold">Amount</label>
            <div className="border w-full px-2 py-1 rounded bg-gray-50">
              {data?.amount ? data.amount : <Skeleton width="100%" />}
            </div>
          </div>
          <div className="flex items-start">
            <label className="py-1 pr-2 w-36 text-gray-700 font-semibold">Note</label>
            <textarea
              defaultValue={data?.note}
              onChange={(e) => setNote(e.target.value)}
              rows="3"
              className="border w-full px-2 py-1 rounded bg-gray-50 text-sm"
              placeholder="Please Enter Your Note!"
            ></textarea>
          </div>
        </div>
        <div className="flex justify-between mt-3">
          <button
            onClick={() => navigate(-1)}
            className="bg-primary flex items-center hover:bg-primary-hover duration-300 px-3 py-1 text-center rounded text-white text-sm font-semibold"
          >
            <ChevronLeft size={24} />
            Back
          </button>
          <button
            onClick={handelUpdate}
            className="bg-primary flex items-center gap-1 hover:bg-primary-hover duration-300 px-4 py-2 text-center rounded text-white text-sm font-semibold"
          >
            Update
            <SendHorizontal size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentReportEdit;
